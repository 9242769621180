<template>
  <div>
    <ConfirmDialog group="iframe-security-warning" id="warning-dialog">
      <template #message="slotProps">
        <div
          class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border"
        >
          <i
            :class="slotProps.message.icon"
            class="text-6xl text-primary-500"
          ></i>
          <i18n-t
            scope="global"
            :keypath="
              !state.iframe_enhanced_security_enabled
                ? 'confirmations.transactionEnhancedSecurity.disableMessage'
                : 'confirmations.transactionEnhancedSecurity.enableMessage'
            "
            tag="p"
            class="whitespace-pre-line"
          >
            <template v-slot:link>
              <a
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                target="_blank"
                id="security-iframe-docs-link"
                :href="iframeSecureTokenizationUrl"
                >{{ $t("learnMore") }}</a
              >
            </template>
          </i18n-t>
        </div>
      </template>
    </ConfirmDialog>
    <SpreedlyPageTitle
      :title="environment.name"
      :breadcrumbs="router.currentRoute.value.name === 'EnvironmentSettings'"
      :editable-title="true"
    >
      <template v-slot:edit-title>
        <div class="flex flex-row md:flex-wrap">
          <SpreedlyEditableTitle
            :has-permission="store.hasPermission('environment.update')"
            :required="true"
            v-model:title="environment.name"
            :alt-title="environment.name"
            v-model:title-update-successful="titleUpdateSuccessful"
            @update-title="updateTitle"
          ></SpreedlyEditableTitle>
          <div class="lg:mt-3">
            <div
              v-if="environment.key === currentEnvironment.key"
              class="border-spreedly-blue-600 border text-spreedly-gray-600 bg-spreedly-blue-200 rounded-xl px-4 ml-4 md:ml-2 lg:whitespace-nowrap text-center"
            >
              {{ $t("currentEnvironment") }}
            </div>
            <Button
              v-else
              type="button"
              :id="`switch-environment-${environment.key}`"
              class="text-spreedly-blue-600 hover:text-spreedly-blue-700 ml-6"
              :label="$t('env.settingsSwitch')"
              @click="store.changeCurrentEnvironment(environment)"
            ></Button>
          </div>
        </div>
      </template>
      <template v-slot:breadcrumbs>
        <router-link
          :to="{ name: 'EnvironmentList' }"
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
          id="back-to-search-results-button"
        >
          <i class="pi pi-arrow-left relative mr-1 pb-4 text-[0.75rem]"></i>
          {{ $t("env.environment", 2) }}
        </router-link>
      </template>
    </SpreedlyPageTitle>

    <Message
      v-if="router.options.history.state.successMessage"
      class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
      severity="success"
    >
      <i18n-t
        scope="global"
        :keypath="'successMessage.environmentCreated'"
        tag="p"
      >
        <template v-slot:environment>
          <span class="font-bold">{{ environment.name }}</span>
        </template>
      </i18n-t>
    </Message>

    <SpreedlyContainer>
      <div class="text-sm text-left mb-4">
        <div class="border-b border-gray-300">
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <div class="ml-0 lg:ml-8 font-bold relative top-4">
                {{ $t("key") }}
              </div>
            </div>
            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 py-1">
                <SpreedlyCopyToClipboard
                  id="copy-environment-key"
                  v-if="environment && environment.key"
                  :token="environment.key"
                ></SpreedlyCopyToClipboard>
              </div>
            </div>
          </div>
        </div>
        <div class="border-b border-gray-300 lg:py-4 py-2">
          <div class="grid grid-cols-3 gap-4">
            <div class="col-span-1">
              <span class="ml-0 lg:ml-8 font-bold relative top-1">
                {{ $t("advancedVault.advancedVault") }}
              </span>
            </div>

            <div class="col-span-2 text-spreedly-gray-600 px-2 lg:-px-8">
              <div class="grid grid-cols-12">
                <div
                  class="col-span-4 lg:col-span-3 xl:col-span-2 2xl:col-span-1"
                >
                  <Chip
                    class="px-4 text-sm"
                    :class="
                      environment.payment_method_management_enabled
                        ? 'bg-success-green text-white'
                        : 'bg-spreedly-gray-300 text-spreedly-gray-600'
                    "
                    :label="
                      environment.payment_method_management_enabled
                        ? $t('on')
                        : $t('off')
                    "
                  ></Chip>
                </div>
                <div
                  class="col-span-8 lg:col-span-9 xl:col-span-10 2xl:col-span-11 relative -top-1"
                >
                  <!-- admin allow AV is disabled -->
                  <i18n-t
                    scope="global"
                    v-if="!currentOrganization.allow_payment_method_management"
                    :keypath="'advancedVault.infoMessage.adminAvDisabled'"
                    tag="p"
                    class="ml-4 text-caption relative top-1"
                  >
                    <template v-slot:mailTo>
                      <a
                        class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                        href="mailto:support@spreedly.com"
                        >{{ $t("spreedlySupport") }}</a
                      >
                    </template>
                  </i18n-t>
                  <!-- admin allow AV is enabled and org AV is disabled -->
                  <i18n-t
                    scope="global"
                    v-if="
                      currentOrganization.allow_payment_method_management &&
                      !currentOrganization.payment_method_management_enabled
                    "
                    :keypath="'advancedVault.infoMessage.orgAvDisabled'"
                    tag="p"
                    class="ml-4 text-caption relative top-2"
                  >
                    <template v-slot:organizationSettings>
                      <router-link
                        class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                        :to="{ name: 'OrganizationSettings' }"
                        >{{ $t("org.settings") }}
                      </router-link>
                    </template>
                  </i18n-t>
                  <!-- admin allow AV is enabled and org AV is enabled-->
                  <i18n-t
                    scope="global"
                    v-if="
                      currentOrganization.allow_payment_method_management &&
                      currentOrganization.payment_method_management_enabled
                    "
                    :keypath="'advancedVault.infoMessage.manage'"
                    tag="p"
                    class="ml-4 text-caption relative top-2"
                  >
                    <template v-slot:here>
                      <router-link
                        class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                        id="advanced-vault-router"
                        :to="{ name: 'AdvancedVault' }"
                        >{{ $t("advancedVault.infoMessage.here") }}
                      </router-link>
                    </template>
                  </i18n-t>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:py-4 py-2">
          <div class="grid grid-cols-3 gap-4">
            <div class="col-span-1">
              <span class="ml-0 lg:ml-8 font-bold relative top-1">
                {{ $t("accountUpdater.accountUpdater") }}
              </span>
            </div>

            <div class="col-span-2 text-spreedly-gray-600 px-2 lg:-px-8">
              <div class="grid grid-cols-12">
                <div
                  class="col-span-4 lg:col-span-3 xl:col-span-2 2xl:col-span-1"
                >
                  <Chip
                    class="px-4 text-sm"
                    :class="
                      environment.au_enabled
                        ? 'bg-success-green text-white'
                        : 'bg-spreedly-gray-300 text-spreedly-gray-600'
                    "
                    :label="environment.au_enabled ? $t('on') : $t('off')"
                  ></Chip>
                </div>
                <div
                  class="col-span-8 lg:col-span-9 xl:col-span-10 2xl:col-span-11 relative -top-1"
                >
                  <!-- admin allow AU is disabled -->
                  <i18n-t
                    scope="global"
                    v-if="!currentOrganization.allow_account_updater"
                    :keypath="'accountUpdater.infoMessage.adminAuDisabled'"
                    tag="p"
                    class="ml-4 text-caption relative top-1"
                  >
                    <template v-slot:mailTo>
                      <a
                        class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                        href="mailto:support@spreedly.com"
                        >{{ $t("spreedlySupport") }}</a
                      >
                    </template>
                  </i18n-t>
                  <!-- admin allow AU is enabled and org AU is disabled -->
                  <i18n-t
                    scope="global"
                    v-if="
                      currentOrganization.allow_account_updater &&
                      !currentOrganization.account_updater_enabled
                    "
                    :keypath="'accountUpdater.infoMessage.orgAuDisabled'"
                    tag="p"
                    class="ml-4 text-caption relative top-1"
                  >
                    <template v-slot:organizationSettings>
                      <router-link
                        class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                        :to="{ name: 'OrganizationSettings' }"
                        >{{ $t("org.settings") }}
                      </router-link>
                    </template>
                  </i18n-t>
                  <i18n-t
                    scope="global"
                    v-if="
                      currentOrganization.allow_account_updater &&
                      currentOrganization.account_updater_enabled &&
                      !currentOrganization.allow_au_by_environment
                    "
                    :keypath="'accountUpdater.infoMessage.envAuDisabledEnvSettings'"
                    tag="p"
                    class="ml-4 text-caption relative top-1"
                  >
                    <template v-slot:organizationSettings>
                      <router-link
                        class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                        :to="{ name: 'OrganizationSettings' }"
                        >{{ $t("org.settings") }}
                      </router-link>
                    </template>
                    <template v-slot:here>
                      <router-link
                        class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                        :to="{ name: 'AdvancedVault' }"
                        id="account-updater-router"
                        >{{ $t("advancedVault.infoMessage.here") }}
                      </router-link>
                    </template>
                  </i18n-t>
                  <!-- admin allow AU is enabled and org AU is enabled and allow env AU is enabled-->
                  <i18n-t
                    scope="global"
                    v-if="
                      currentOrganization.allow_account_updater &&
                      currentOrganization.account_updater_enabled &&
                      currentOrganization.allow_au_by_environment
                    "
                    :keypath="'accountUpdater.infoMessage.manage'"
                    tag="p"
                    class="ml-4 text-caption relative top-2"
                  >
                    <template v-slot:here>
                      <router-link
                        class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                        :to="{ name: 'AdvancedVault' }"
                        >{{ $t("advancedVault.infoMessage.here") }}
                      </router-link>
                    </template>
                  </i18n-t>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SpreedlyContainer>
    <!-- SECURITY -->
    <SpreedlySubtitle class="mt-6 mb-4" :title="$t('security')">
    </SpreedlySubtitle>
    <SpreedlyContainer>
      <Message
        v-if="displayErrorMessage"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="error"
        :closable="false"
        >{{ displayErrorMessage }}
      </Message>
      <form
        class="text-sm text-left mb-4"
        @submit.prevent="checkForInvalidForm(v$.$invalid)"
      >
        <div
          class="grid grid-cols-3 gap-4 lg:pt-4 pt-2 relative top-4"
          :class="{
            'border-b border-gray-300': store.hasAccessToIframeEnhancedSecurity,
            'pb-8': !store.hasAccessToIframeEnhancedSecurity,
          }"
        >
          <div class="col-span-1 text-sm text-ellipsis relative flex flex-row">
            <label
              for="payment-method-submission-radio"
              class="ml-0 lg:ml-8 font-bold"
            >
              {{ $t("payment_method_submission") }}
            </label>
            <SpreedlyIcon
              name="information-outline"
              class="text-spreedly-blue-700 px-2 hover:cursor-pointer"
              v-tooltip="$t('env.unrestrictedInfoMessage')"
            ></SpreedlyIcon>
          </div>

          <div class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8">
            <div class="field mt-1">
              <div
                id="payment-method-submission-radio"
                class="mb-4 flex flex-col"
              >
                <div class="inline-block mb-2">
                  <RadioButton
                    :disabled="!store.hasPermission('environment.update')"
                    class="lg:ml-1.5"
                    name="iframe-or-express-radio-option"
                    value="iFrame or Spreedly Express"
                    input-id="iframe-or-express-radio-option"
                    v-model="v$.payment_method_submission.$model"
                  />
                  <label
                    class="ml-2 lg:ml-4 text-spreedly-gray-600"
                    for="iframe-or-express-radio-option"
                  >
                    {{ $t("iFrameOrExpress") }}
                  </label>
                </div>
                <div class="inline-block">
                  <RadioButton
                    :disabled="!store.hasPermission('environment.update')"
                    class="lg:ml-1.5"
                    name="unrestricted-radio-option"
                    value="Unrestricted"
                    input-id="unrestricted-radio-option"
                    v-model="v$.payment_method_submission.$model"
                  />
                  <label
                    class="ml-2 lg:ml-4 text-spreedly-gray-600 mr-2"
                    for="unrestricted-radio-option"
                  >
                    {{ $t("unrestricted") }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="lg:py-4 py-2">
          <div
            v-if="store.hasAccessToIframeEnhancedSecurity"
            class="grid grid-cols-3 gap-4 relative top-6 pb-8"
          >
            <div
              class="col-span-1 self-start text-sm text-ellipsis flex flex-row"
            >
              <label
                for="iframe-tokenization-checkbox"
                class="ml-0 lg:ml-8 font-bold"
              >
                {{ $t("enhancedSecurity.enhancedSecurity") }}
              </label>
              <SpreedlyIcon
                name="information-outline"
                class="text-spreedly-blue-700 px-2 hover:cursor-pointer"
                v-tooltip="$t('enhancedSecurity.infoMessage')"
              ></SpreedlyIcon>
            </div>

            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-4 lg:-px-8"
            >
              <div class="field mt-1">
                <div id="iframe-tokenization-checkbox" class="inline-block">
                  <div class="flex flex-row self-start mb-4">
                    <Checkbox
                      :disabled="!store.hasPermission('environment.update')"
                      v-model="v$.iframe_enhanced_security_enabled.$model"
                      input-id="iframe-tokenization-checkbox"
                      variant="outlined"
                      binary
                      aria-describedby="iframe-tokenization-checkbox"
                    />
                    <div class="-mt-1 flex flex-row self-center">
                      <label class="ml-2" for="iframe-tokenization-checkbox">
                        {{ $t("enhancedSecurity.checkboxLabel") }}
                      </label>
                      <div class="ml-2">
                        <a
                          :href="iframeSecureTokenizationUrl"
                          target="_blank"
                          id="learn-more-link"
                          class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                          >{{ $t("learnMore") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        v-if="store.hasPermission('environment.update')"
        class="flex flex-shrink-0 flex-wrap items-center justify-start"
      >
        <SpreedlyButton
          class="mr-4"
          :text="$t('cancel')"
          :inverse="true"
          :disabled="!v$.$anyDirty || formState === 'saving'"
          @click="resetForm()"
        ></SpreedlyButton>
        <SpreedlyButton
          :disabled="!v$.$anyDirty"
          class="!mr-0"
          :icon="{ position: 'left', state: formState }"
          :text="$t(formState)"
          @click="checkForInvalidForm(v$.$invalid)"
        ></SpreedlyButton>
      </div>
    </SpreedlyContainer>
    <!-- ENV INTEGRATION -->
    <SpreedlySubtitle class="mt-6 mb-4" :title="$t('environmentIntegration')">
    </SpreedlySubtitle>
    <TheStripeConnection
      :environment="currentEnvironment"
    ></TheStripeConnection>
  </div>
</template>

<script setup lang="ts">
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import SpreedlyEditableTitle from "@/components/SpreedlyEditableTitle.vue";

import Message from "primevue/message";
import RadioButton from "primevue/radiobutton";
import Chip from "primevue/chip";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";
import { useSettingsStore } from "@/stores/SettingsStore";
import { computed, reactive, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import router from "@/router";
import { deepCopy } from "@/services/HelperService";
import {
  determineEnvironmentAccountUpdaterStatus,
  determineEnvironmentAdvancedVaultStatus,
  type Environment,
  updateEnvironment,
} from "@/services/EnvironmentService";
import { required } from "@/i18n/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
import { onBeforeRouteLeave } from "vue-router";
import i18n from "@/i18n";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";
import TheStripeConnection from "@/components/TheStripeConnection.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";

const store = useSettingsStore();
const { currentEnvironment, currentOrganization } = storeToRefs(store);

const displayErrorMessage = ref<string | null>(null);
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const submitted = ref(false);
const titleUpdateSuccessful = ref<boolean | null>(null);
const confirm = useConfirm();
const props = defineProps<{
  id: string;
}>();

watch(
  () => currentEnvironment.value.key,
  () => {
    router.push({
      name: "EnvironmentSettings",
      params: { id: currentEnvironment.value.key },
    });
  }
);

const environment = computed(() => {
  const env = deepCopy(
    store.environments.find((env: Environment) => env.key === props.id)
  );
  if (env) {
    env.au_enabled = determineEnvironmentAccountUpdaterStatus(
      currentOrganization.value,
      env.au_enabled as boolean
    );
    env.payment_method_management_enabled =
      determineEnvironmentAdvancedVaultStatus(
        currentOrganization.value,
        env.payment_method_management_enabled as boolean
      );
    populateState(env);
    return env;
  }
  return {};
});

const state = reactive({
  payment_method_submission: "",
  iframe_enhanced_security_enabled: false,
});

const rules = {
  payment_method_submission: { required },
  iframe_enhanced_security_enabled: {},
};

const v$ = useVuelidate(rules, state);

const iframeSecureTokenizationUrl = `${
  import.meta.env.VITE_DOCS_URL
}/docs/securing-iframe`;

function populateState(env: Environment) {
  state.payment_method_submission = env.payment_method_submission as string;
  state.iframe_enhanced_security_enabled =
    env.iframe_enhanced_security_enabled as boolean;
}

onBeforeRouteLeave(() => {
  if (v$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

watch(
  () => v$.value.$anyDirty,
  () => {
    if (v$.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

function resetForm() {
  displayErrorMessage.value = null;
  populateState(environment.value);
  submitted.value = false;
  v$.value.$reset();
}

const updateTitle = async (name: string) => {
  const response = await updateEnvironment(currentOrganization.value.key, {
    name: name,
    key: environment.value.key,
    payment_method_submission: environment.value.payment_method_submission,
    iframe_enhanced_security_enabled:
      environment.value.iframe_enhanced_security_enabled,
  });
  if (response) {
    await store.fillEnvironments(true);
    titleUpdateSuccessful.value = true;
  }
};

async function checkForInvalidForm(isFormInvalid: boolean) {
  if (isFormInvalid) {
    return;
  }
  if (!v$.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = null;

  if (!store.hasPermission("environment.update")) {
    displayErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }

  if (
    state.iframe_enhanced_security_enabled !==
    environment.value.iframe_enhanced_security_enabled
  ) {
    return await confirmIframeSecurityToggle();
  }
  return await save();
}

async function save() {
  submitted.value = true;
  formState.value = "saving";
  try {
    const env = await updateEnvironment(currentOrganization.value.key, {
      name: environment.value.name,
      key: environment.value.key,
      payment_method_submission: state.payment_method_submission,
      iframe_enhanced_security_enabled: state.iframe_enhanced_security_enabled,
    });
    if (env) {
      await store.fillEnvironments(true);
      formState.value = "saved";
      v$.value.$reset();
    }
  } catch (error) {
    displayErrorMessage.value = i18n.global.t("errorMessage.generic");
    formState.value = "saveChanges";
  }
}

async function confirmIframeSecurityToggle() {
  confirm.require({
    group: "iframe-security-warning",
    message: !state.iframe_enhanced_security_enabled
      ? i18n.global.t(
          "confirmations.transactionEnhancedSecurity.disableMessage"
        )
      : i18n.global.t(
          "confirmations.transactionEnhancedSecurity.enableMessage"
        ),
    header: i18n.global.t("enhancedSecurity.enhancedSecurity"),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("save"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: () => save(),
    reject: () =>
      (state.iframe_enhanced_security_enabled =
        !state.iframe_enhanced_security_enabled),
    onHide: () =>
      (state.iframe_enhanced_security_enabled =
        !state.iframe_enhanced_security_enabled),
  });
}
</script>
