import { createApp } from "vue";
import router from "./router";
import i18n from "./i18n/index";
import PrimeVue from "primevue/config";

import { auth0 } from "@/plugins/createAuth0";
import { LDPlugin } from "launchdarkly-vue-client-sdk";
import { loadFonts } from "./plugins/webfontloader";
import { createPinia } from "pinia";

import mdiVue from "mdi-vue/v3";
import icons from "@/services/IconService";

import * as Sentry from "@sentry/vue";

import App from "./App.vue";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import "./index.css";

import DefaultLayout from "./components/layouts/DefaultLayout.vue";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import { datadogRum } from "@datadog/browser-rum";
import Tooltip from "primevue/tooltip";

loadFonts();

const LaunchDarklyClientID = import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID;

const app = createApp(App);
if (import.meta.env.VITE_SENTRY_URL) {
  Sentry.init({
    app,
    environment:
      import.meta.env.VITE_ENV || import.meta.env.MODE || "unknown",
    dsn: import.meta.env.VITE_SENTRY_URL,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        mask: [".sentry-mask"],
      }),
    ],
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [import.meta.env.VITE_PRODUCTION_URL, /^\//],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
  });
}

if (
  import.meta.env.VITE_DATADOG_APPLICATION_ID &&
  import.meta.env.VITE_DATADOG_CLIENT_TOKEN
) {
  /* global datadog */
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "next",
    env: import.meta.env.VITE_ENV || import.meta.env.MODE || "unknown",
    // Specify a version number to identify the deployed version of your application in Datadog
    version: import.meta.env.VITE_VERSION || "1.0.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    enableExperimentalFeatures: ["clickmap"],
    allowedTracingUrls: [import.meta.env.VITE_ID_URL],
  });

  datadogRum.startSessionReplayRecording();
}
app.component("default-layout", DefaultLayout);

app.use(PrimeVue);
app.use(createPinia());
app.use(router);
app.use(i18n);
app.use(mdiVue, { icons });
app.use(auth0);
app.use(LDPlugin, {
  clientSideID: LaunchDarklyClientID,
  deferInitialization: true,
});
app.use(ConfirmationService);
app.use(ToastService);

app.directive("tooltip", Tooltip);
app.mount("#app");

app.directive("click-outside", (el, binding) => {
  el.clickOutsideEvent = function (event: PointerEvent) {
    if (!(el === event.target || el.contains(event.target))) {
      if (typeof binding.value === "function") {
        binding.value();
      }
    }
  };
  document.body.addEventListener("click", el.clickOutsideEvent);
});

if (import.meta.env.VITE_PENDO_API_KEY) {
  (function (apiKey: string) {
    (function () {
      let w, x;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const o = (window["pendo"] = window["pendo"] || {});
      o._q = o._q || [];

      const v = [
        "initialize",
        "identify",
        "updateOptions",
        "pageLoad",
        "track",
      ];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"](
                // eslint-disable-next-line prefer-rest-params
                [m].concat([].slice.call(arguments, 0))
              );
            };
        })(v[w]);

      const y = document.createElement("script");
      y.async = !0;
      y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";

      const z = document.getElementsByTagName("script")[0];
      if (z && z.parentNode) {
        z.parentNode.insertBefore(y, z);
      }
    })();
  })(import.meta.env.VITE_PENDO_API_KEY);
}
