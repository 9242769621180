<template>
  <div>
    <SpreedlySubtitle :title="$t('recover.recoverConfiguration', 2)">
      <template v-slot:info-text>
        <div
          class="flex flex-col md:flex-col lg:flex-row lg:justify-between pb-8 text-spreedly-gray-600"
        >
          <i18n-t
            scope="global"
            :keypath="'recover.recoverConfigurationDescription'"
            tag="p"
            class="text-caption"
          >
          </i18n-t>
        </div>
      </template>
    </SpreedlySubtitle>
    <div
      class="flex flex-row"
      :class="
        store.hasPermission('environment.create_workflow')
          ? 'justify-between'
          : 'justify-end'
      "
    >
      <SpreedlyButton
        @click="router.push({ name: 'RecoverConfigurationCreate' })"
        :text="$t('recover.createConfiguration')"
        v-if="store.hasPermission('environment.create_workflow')"
        class="mb-4"
        :icon="{ name: 'plus', position: 'left' }"
        id="create-recover-configuration-button"
      ></SpreedlyButton>
      <div class="self-center">
        <Dropdown
          v-model="sort"
          id="sort-dropdown"
          @change="sortConfigs()"
          :options="[
            { label: i18n.global.t('createdDateDesc'), code: 'desc' },
            { label: i18n.global.t('createdDateAsc'), code: 'asc' },
          ]"
          option-label="label"
        />
      </div>
    </div>

    <div class="h-full w-full flex flex-col">
      <div v-if="!recoverConfigurations.length" class="self-center mt-4">
        <span class="text-lg font-light italic text-spreedly-gray-500">{{
          $t("recover.noConfigurations")
        }}</span>
      </div>
      <div v-else>
        <div v-for="config in paginatedConfigs" class="flex flex-col">
          <div
            class="grid grid-cols-1 lg:grid-cols-4 gap-x-4 w-full justify-between overflow-hidden drop-shadow-sm my-2 rounded-lg bg-white border text-spreedly-gray-600 p-4"
          >
            <div class="col-span-1 self-center">
              <button
                :title="config.description"
                :id="`edit-recover-config-${config.key}`"
                class="w-full underline font-bold text-lg text-start text-ellipsis whitespace-nowrap overflow-hidden"
                @click="
                  router.push({
                    name: 'RecoverConfigurationSettings',
                    params: { id: config.key },
                  })
                "
              >
                {{ config.description }}
              </button>
            </div>
            <div class="col-span-1 self-center">
              <div>
                <span class="font-bold mr-2">{{ $t("last_modified") }}</span
                ><span>{{ formatDate(config.updated_at!) }}</span>
              </div>
            </div>
            <div class="col-span-1 self-center">
              <div>
                <span class="font-bold mr-2">{{ $t("created_at") }}</span>

                <span>{{ formatDate(config.inserted_at!) }}</span>
              </div>
            </div>
            <div class="col-span-1 self-center text-end">
              <Button
                @click="
                  router.push({
                    name: 'RecoverConfigurationSettings',
                    params: { id: config.key },
                  })
                "
                :title="$t('edit')"
                icon="pi pi-cog pi-text-override"
                type="button"
                :id="`recover-configuration-cog-button-${config.key}`"
                class="recover-configuration-cog-button p-button-text p-button-rounded !text-spreedly-gray-600 !shadow-none !hover:text-spreedly-gray-700 hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
              >
              </Button>
            </div>
          </div>
        </div>
        <Paginator
          v-if="recoverConfigurations.length > 10"
          :totalRecords="recoverConfigurations.length"
          :rows="numRows"
          :rowsPerPageOptions="[5, 10, 15, 20]"
          template="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          v-model:first="currentPage"
          @update:first="(newPage) => (currentPage = newPage)"
          @update:rows="(newRows) => (numRows = newRows)"
          :pt="{
            root: {
              class: 'mt-4 bg-transparent py-0 text-spreedly-gray-600',
            },
            rowPerPageDropdown: {
              root: {
                class:
                  'flex !ml-4 !mb-0 border border-spreedly-gray-400 ml-4 items-center !w-[5.2rem]',
              },
            },
          }"
        ></Paginator>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, onUnmounted, ref } from "vue";
import { listRecoverConfigurations } from "@/services/RecoverConfigurationsService";
import { useSettingsStore } from "@/stores/SettingsStore";
import { useWorkflow } from "@/composables/useWorkflow";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";
import router from "@/router";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import { formatDate } from "@/services/HelperService";
import Button from "primevue/button";
import Paginator from "primevue/paginator";
import Dropdown from "primevue/dropdown";
import i18n from "@/i18n";

const store = useSettingsStore();
const { recoverConfigurations } = useWorkflow();
const numRows = ref(10);
const currentPage = ref(0);
const isLoading = ref(false);
const sort = ref({ label: "Created Date: Newest to oldest", code: "desc" });

onBeforeMount(async () => {
  isLoading.value = true;
  try {
    await setConfigs();
    sortConfigs();
  } catch (err) {
  } finally {
    isLoading.value = false;
  }
});

onUnmounted(() => {
  recoverConfigurations.value = [];
});
const paginatedConfigs = computed(() => {
  if (recoverConfigurations.value.length > 10) {
    const start = currentPage.value > 0 ? currentPage.value : 0;
    const end = start + numRows.value;
    return recoverConfigurations.value.slice(start, end);
  }
  return recoverConfigurations.value;
});
async function setConfigs() {
  recoverConfigurations.value = await listRecoverConfigurations(
    store.currentOrganization.key,
    store.currentEnvironment.key!
  );
}

function sortConfigs() {
  if (sort.value.code === "desc") {
    recoverConfigurations.value.sort(
      (a, b) => Date.parse(b.inserted_at!) - Date.parse(a.inserted_at!)
    );
  } else {
    recoverConfigurations.value.reverse();
  }
}
</script>
