<template>
  <div>
    <SpreedlyContainer v-if="gateway.key">
      <Message
        v-if="router.options.history.state.successMessage && !redacted"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="success"
      >
        <i18n-t
          scope="global"
          :keypath="'successMessage.gatewayCreated'"
          tag="p"
        >
          <template v-slot:gateway>
            <span class="font-bold">{{ gateway.name }}</span>
          </template>
        </i18n-t>
      </Message>
      <Message
        v-else-if="redacted"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="info"
        :closable="false"
      >
        <i18n-t
          scope="global"
          :keypath="'successMessage.gatewayRedacted'"
          tag="p"
        >
          <template v-slot:gateway>
            <span class="font-bold" v-if="gateway.description">{{
              `${gateway.name} - ${gateway.description}`
            }}</span>
          </template>
        </i18n-t>
      </Message>
      <Message
        v-if="displayErrorMessage"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="error"
        :closable="false"
        >{{ $t("errorMessage.generic") }}
      </Message>
      <div class="flex justify-end">
        <SpreedlyButton
          id="view-payment-capabilities"
          :text="$t('viewCapabilities')"
          inverse
          @click="togglePaymentCapabilities = true"
        ></SpreedlyButton>
      </div>

      <form
        class="text-sm text-left mb-4"
        @submit.prevent="checkForValidForm(v$.$invalid)"
      >
        <div class="border-b border-gray-300">
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <span class="ml-0 lg:ml-8 font-bold relative top-4">
                {{ $t("gateway_token") }}
              </span>
            </div>
            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 py-1">
                <SpreedlyCopyToClipboard
                  id="copy-gateway-token"
                  v-if="gateway.key"
                  :token="gateway.key"
                >
                </SpreedlyCopyToClipboard>
              </div>
            </div>
          </div>
        </div>
        <div class="border-b border-gray-300">
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <span class="ml-0 lg:ml-8 font-bold">
                {{ $t("gateway_.name") }}
              </span>
            </div>
            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 pb-4">
                <span class="mr-4 pr-4 pl-2">{{ gateway.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="border-b border-gray-300"
          v-if="gateway.mode.toLowerCase() !== 'default'"
        >
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <span class="ml-0 lg:ml-8 font-bold">
                {{ $t("gateway_.authMode") }}
              </span>
            </div>
            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 pb-4">
                <span class="mr-4 pr-4 pl-2">{{ gateway.mode }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="border-b border-gray-300">
          <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
            <div class="col-span-1">
              <span class="ml-0 lg:ml-8 font-bold">
                {{ $t("created_at") }}
              </span>
            </div>
            <div
              class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
            >
              <div class="w-2/3 pb-4">
                <span class="mr-4 pr-4 pl-2">{{
                  formatDate(gateway.created_at!)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            gateway && gateway.gateway_settings && settingsAdded && !redacted
          "
        >
          <div
            class="field"
            v-for="key in Object.keys(gateway.gateway_settings)"
            :key="key"
          >
            <div class="border-b border-gray-300">
              <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2 pb-1">
                <div class="col-span-1 flex flex-row">
                  <label
                    :for="`${key}-input`"
                    class="font-bold relative top-2 ml-0 lg:ml-8"
                    :class="{
                      '!top-0':
                        typeof gateway.gateway_settings![key as keyof GatewaySettings] === 'boolean',
                      'p-error': v$.settings[`${key}`].$invalid && submitted,
                    }"
                  >
                    {{ sentenceCase(key) }}
                  </label>
                  <Button
                    v-if="key === 'enabled_network_tokens'"
                    severity="secondary"
                    text
                    id="network-token-info"
                    rounded
                    :aria-label="`${key}-info`"
                    type="button"
                    class="-mt-2 ml-2 p-button-text p-button-rounded !text-spreedly-blue-700 !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
                    icon="pi pi-info-circle"
                    @click="togglePopup"
                    @keyup.enter="togglePopup"
                  >
                  </Button>
                </div>
                <div
                  class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
                >
                  <div class="w-2/3 pb-4">
                    <Checkbox
                      v-if="typeof gateway.gateway_settings![key as keyof GatewaySettings] === 'boolean'"
                      v-model.trim="v$.settings[`${key}`].$model"
                      :disabled="!store.hasPermission('gateway.update') || ! store.hasOrganizationMembership(gateway.organization_key!) || redacted || redacting"
                      variant="outlined"
                      binary
                      :input-id="`${key}-input`"
                      :aria-describedby="`${key}-input`"
                    />
                    <InputText
                      v-else
                      :disabled="!store.hasPermission('gateway.update') || ! store.hasOrganizationMembership(gateway.organization_key!) || redacted || redacting"
                      :id="`${key}-input`"
                      v-model.trim="v$.settings[`${key}`].$model"
                      :aria-describedby="`${key}-input`"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            gateway.gateway_type !== 'test' &&
            credentialsAdded &&
            gateway.credentials
          "
        >
          <!-- Not Spreedly Test Gateway-->
          <div
            class="field"
            v-for="(cred, index) in gateway.credentials"
            :key="index"
          >
            <!-- If credential is the last in the list don't add a bottom border -->
            <div
              :class="{
                'border-b border-gray-300':
                  gateway.credentials.length - 1 !== index,
              }"
            >
              <div class="grid grid-cols-3 gap-4 lg:pt-4 pt-2">
                <div class="col-span-1">
                  <label
                    :for="`${cred.name}-input`"
                    class="font-bold relative top-2 ml-0 lg:ml-8"
                    :class="{
                      'p-error': v$[`${cred.name}`].$invalid && submitted,
                    }"
                  >
                    {{ sentenceCase(cred.name) }}
                  </label>
                </div>
                <div
                  class="col-span-2 text-sm text-spreedly-gray-600 px-2 lg:-px-8"
                >
                  <div class="w-2/3 pb-4">
                    <InputText
                      v-if="!redacted && !redacting"
                      :disabled="!store.hasPermission('gateway.update')  || ! store.hasOrganizationMembership(gateway.organization_key!)"
                      :id="`${cred.name}-input`"
                      v-model.trim="v$[`${cred.name}`].$model"
                      aria-describedby="description-error"
                    />
                    <span v-else class="mr-4 pr-4 pl-2">{{
                      v$[`${cred.name}`].$model
                    }}</span>
                  </div>
                  <div
                    v-if="
                      (v$[`${cred.name}`].$invalid && submitted) ||
                      v$[`${cred.name}`].$pending.$response
                    "
                    class="-mt-4 mb-4"
                  >
                    <small class="p-error">{{
                      v$[`${cred.name}`].required.$message
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        class="flex flex-shrink-0 flex-col justify-start mb-4"
        v-if="!redacted"
      >
        <i18n-t
          scope="global"
          :keypath="'gateway_.infoMessage.moreInfo'"
          tag="p"
          class="text-sm font-normal text-spreedly-gray-600"
        >
          <template v-slot:link>
            <a
              class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
              target="_blank"
              id="gateway-docs-link"
              :href="`${docsUrl}/docs/create-gateways-and-receivers`"
              >{{ $t("learnMore") }}</a
            >
          </template>
        </i18n-t>
        <i18n-t
          scope="global"
          :keypath="'gateway_.infoMessage.collectingPaymentInfoText'"
          tag="p"
          class="text-sm font-normal text-spreedly-gray-600"
        >
          <template v-slot:link>
            <a
              class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
              target="_blank"
              id="collecting-payment-info-docs-link"
              :href="`${docsUrl}/docs/collect-payment-information`"
              >{{ $t("gateway_.infoMessage.collectingPaymentInfo") }}</a
            >
          </template>
        </i18n-t>
      </div>
      <div v-else class="flex flex-shrink-0 flex-col justify-start mb-4">
        <i18n-t
          scope="global"
          :keypath="'gateway_.infoMessage.redacted'"
          tag="p"
          class="text-sm font-normal text-spreedly-gray-600"
        >
          <template v-slot:link>
            <a
              class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
              target="_blank"
              id="updating-gateway-info-docs-link"
              href="https://docs.spreedly.com/reference/api/v1/#update"
              >{{ $t("learnMore") }}</a
            >
          </template>
          <!--TODO: update docs reference to developer.spreedly.com-->
        </i18n-t>
      </div>
      <div
        v-if="store.hasPermission('gateway.update') && !redacted  && store.hasOrganizationMembership(gateway.organization_key!)"
        class="flex flex-row justify-between"
      >
        <div class="flex flex-shrink-0 flex-wrap items-center justify-start">
          <SpreedlyButton
            class="mr-4"
            :text="$t('cancel')"
            id="cancel-edit-gateway-button"
            :inverse="true"
            :disabled="!v$.$anyDirty || formState === 'saving' || redacting"
            @click="resetForm()"
          ></SpreedlyButton>
          <SpreedlyButton
            :disabled="!v$.$anyDirty || formState === 'saving' || redacting"
            class="!mr-0"
            id="save-gateway-button"
            :icon="{ position: 'left', state: formState }"
            :text="$t(formState)"
            @click="checkForValidForm(v$.$invalid)"
          ></SpreedlyButton>
        </div>
        <div class="flex flex-shrink-0 justify-end mt-4 items-center">
          <button
            :disabled="
              redacting || redacted || gatewayIsInWorkflow || isLoading
            "
            v-tooltip.bottom="
              gatewayIsInWorkflow ? $t('workflows.disableRedact') : ''
            "
            type="button"
            @click="confirmRedact()"
            id="redact-gateway-button"
            class="px-4 py-2 max-h-[42px] inline-flex disabled:cursor-not-allowed justify-center items-center rounded whitespace-nowrap text-spreedly-blue-600 disabled:text-spreedly-gray-400 hover:text-spreedly-blue-700 cursor-pointer"
          >
            {{ redacting ? $t("gateway_.redacting") : $t("gateway_.redact") }}
          </button>
        </div>
      </div>
    </SpreedlyContainer>
    <SpreedlyKeyValueList
      v-if="gateway && !gateway.key"
      :obj="gateway"
      :show-keys="['status', 'message']"
    >
    </SpreedlyKeyValueList>
    <div
      class="mb-6"
      v-if="gateway?.key && gatewayIsInWorkflow && workflows?.length > 0"
    >
      <SpreedlySubtitle
        :title="$t('workflows.inUse')"
        class="mb-4"
      ></SpreedlySubtitle>
      <div
        v-for="workflow in workflows"
        :key="workflow.key"
        class="w-full inline-flex overflow-hidden drop-shadow-sm my-2 rounded-lg bg-white"
      >
        <div
          v-if="workflow.key"
          class="grid grid-cols-1 lg:grid-cols-2 w-[calc(100%-8rem)] gap-x-4 gap-y-1 pl-4 lg:pl-12 pr-4 py-4 rounded-l-lg text-spreedly-gray-600 border-l border-t border-b"
        >
          <div class="col-span-1 min-w-0 flex flex-row">
            <div class="text-ellipsis whitespace-nowrap overflow-hidden">
              <router-link
                :id="`workflow-name-${workflow.key}`"
                class="font-bold cursor-pointer"
                :to="{
                  name: 'WorkflowSettings',
                  params: { id: workflow.key },
                }"
                >{{ workflow.name }}
              </router-link>
            </div>
            <div
              v-if="
                workflow.key === store.currentEnvironment.default_workflow_key
              "
            >
              <div
                class="text-sm border-spreedly-teal border text-spreedly-gray-600 bg-spreedly-teal-light rounded-xl px-2 ml-2 lg:whitespace-nowrap text-center font-bold"
              >
                {{ $t("workflows.default") }}
              </div>
            </div>
          </div>
          <div
            class="col-span-1 whitespace-nowrap overflow-hidden text-ellipsis relative flex flex-row pt-2 -mt-1"
          >
            <span class="font-bold">{{ $t("key") }}</span>
            <div>
              <SpreedlyCopyToClipboard
                inline
                id="workflow-token"
                :token="workflow.key"
              ></SpreedlyCopyToClipboard>
            </div>
          </div>
          <div
            class="col-span-1 text-ellipsis whitespace-nowrap overflow-hidden -mt-4 lg:mt-0"
          >
            <span class="font-bold">{{ $t("created_at") }}</span>
            <span class="ml-2"> {{ formatDate(workflow.inserted_at!) }}</span>
          </div>
          <div
            class="col-span-1 text-ellipsis whitespace-nowrap overflow-hidden lg:mt-0"
          >
            <span class="font-bold">{{ $t("last_modified") }}</span>
            <span class="ml-2"> {{ formatDate(workflow.updated_at!) }}</span>
          </div>
        </div>
        <div
          class="grid grid-cols-1 gap-x-4 border-r border-t border-b rounded-r-lg px-4"
        >
          <div class="self-center">
            <router-link
              :to="{
                name: 'WorkflowSettings',
                params: { id: workflow.key },
              }"
              :title="$t('viewDetails')"
              id="workflow-settings-button"
              class="text-spreedly-blue-600 hover:text-spreedly-blue-700 w-full h-full whitespace-nowrap"
            >
              {{ $t("viewDetails") }}
              <i
                class="pi pi-arrow-right relative ml-1 pb-4 text-[0.75rem]"
              ></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ConfirmDialog
      group="disable-network-tokenization"
      id="warning-dialog"
    ></ConfirmDialog>
    <ConfirmDialog group="redact-gateway" id="delete-dialog">
      <template #message="slotProps">
        <div
          class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border"
        >
          <i
            :class="slotProps.message.icon"
            class="text-6xl text-primary-500"
          ></i>
          <i18n-t
            scope="global"
            :keypath="'confirmations.redact.message'"
            tag="p"
          >
            <template v-slot:link>
              <a
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                target="_blank"
                id="gateway-docs-link"
                href="https://docs.spreedly.com/reference/api/v1/#redact"
                >{{ $t("learnMore") }}</a
              >
            </template>
            <!--TODO: update docs reference to developer.spreedly.com-->
          </i18n-t>
        </div>
      </template>
    </ConfirmDialog>
    <OverlayPanel
      id="enable-network-token-tooltip-panel"
      ref="op"
      :pt="{
        root: 'bg-spreedly-gray-700 bg-opacity-95 text-white font-sans text-sm',
      }"
    >
      <div class="flex flex-col w-[350px]">
        <div class="pb-4">
          {{ $t("gateway_.networkTokenDoc") }}
        </div>
        <a
          :href="`${docsUrl}/docs/network-tokenization#enabling-network-tokenization-at-gateways`"
          target="_blank"
          class="text-spreedly-blue-lighter underline"
          >{{ $t("gateway_.seeNetworkTokenDocs") }}</a
        >
      </div>
    </OverlayPanel>
    <PaymentCapabilitiesDrawer
      :composer="false"
      :gateway-type="gatewayType_"
      v-model:toggle-payment-capabilities="togglePaymentCapabilities"
    ></PaymentCapabilitiesDrawer>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from "vue";
import SpreedlyButton from "./SpreedlyButton.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";
import SpreedlyKeyValueList from "@/components/SpreedlyKeyValueList.vue";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import PaymentCapabilitiesDrawer from "@/components/PaymentCapabilitiesDrawer.vue";
import Message from "primevue/message";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import ConfirmDialog from "primevue/confirmdialog";
import Button from "primevue/button";

import type { Gateway, GatewaySettings } from "@/services/GatewayService";
import {
  redactGateway,
  updateGateway,
  anyActiveWorkflows,
} from "@/services/GatewayService";
import { deepCopy, formatDate, sentenceCase } from "@/services/HelperService";
import { useSettingsStore } from "@/stores/SettingsStore";
import { useVuelidate } from "@vuelidate/core";
import { storeToRefs } from "pinia";
import { useConfirm } from "primevue/useconfirm";
import { required } from "@/i18n/i18n-validators";
import i18n from "@/i18n";
import router from "@/router";
import { onBeforeRouteLeave } from "vue-router";
import {
  listWorkflowsByGateway,
  type Workflow,
} from "@/services/WorkflowService";
import OverlayPanel from "primevue/overlaypanel";

const props = defineProps<{
  id: string;
  gatewayType?: string;
}>();

const confirm = useConfirm();

const redacted = computed(() => {
  return gateway.value.state === "redacted";
});
const docsUrl = import.meta.env.VITE_DOCS_URL;
const displayErrorMessage = ref(false);
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const store = useSettingsStore();
const { currentEnvironment, gatewayOptions } = storeToRefs(store);
const credentialsAdded = ref(false);
const redacting = ref(false);
const gatewayIsInWorkflow = ref(false);
const togglePaymentCapabilities = ref(false);

const gateway = defineModel<Gateway>("gateway", { required: true });

const isLoading = ref(false);
const workflows = ref<Workflow[]>([]);
const settingsAdded = ref(false);

const state = reactive<Record<string, unknown>>({
  description: "",
});

const rules = reactive<Record<string, unknown>>({
  description: {},
});
const emit = defineEmits(["reloadGateway"]);

const submitted = ref(false);
const v$ = useVuelidate(rules, state);
const op = ref();

const gatewayType_ = computed(() => {
  if (props.gatewayType) {
    return props.gatewayType;
  } else {
    return gatewayOptions.value.find((g) => g.name === gateway.value.name)
      ?.gateway_type;
  }
});

const confirmDisableNetworkTokenization = () => {
  confirm.require({
    group: "disable-network-tokenization",
    message: i18n.global.t("confirmations.disableNT.message"),
    header: i18n.global.t("confirmations.disableNT.header"),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("save"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: () => save(),
    reject: () => (state.settings.enabled_network_tokens = true),
  });
};
const confirmRedact = () => {
  confirm.require({
    group: "redact-gateway",
    message: i18n.global.t("confirmations.redact.message"),
    header: i18n.global.t("confirmations.redact.header", {
      gateway: gateway.value.name,
    }),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("gateway_.redact"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: () => redact(),
  });
};

async function redact() {
  redacting.value = true;
  try {
    await redactGateway(
      gateway.value.organization_key!,
      gateway.value.environment_key!,
      gateway.value
    );
    emit("reloadGateway");
    await store.fillGatewayOptions(true);
  } catch (err) {
    displayErrorMessage.value = true;
  } finally {
    redacting.value = false;
  }
}

onMounted(async () => {
  populateState();
  await fetchWorkflows();
});

const togglePopup = (event) => {
  op.value.toggle(event);
};
async function fetchWorkflows() {
  isLoading.value = true;
  try {
    gatewayIsInWorkflow.value = await anyActiveWorkflows(
      gateway.value.organization_key as string,
      gateway.value.environment_key as string,
      gateway.value.key
    );

    if (
      store.hasPermission("workflow.read") &&
      store.hasAccessToComposer &&
      !redacted.value &&
      !redacting.value &&
      props.gatewayType &&
      gatewayIsInWorkflow.value
    ) {
      workflows.value = await listWorkflowsByGateway(
        gateway.value.organization_key as string,
        gateway.value.environment_key as string,
        gateway.value.key
      );
    }
  } catch (err) {
    workflows.value = [];
  } finally {
    isLoading.value = false;
  }
}

onBeforeRouteLeave(() => {
  if (v$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

watch(
  () => v$.value.$anyDirty,
  () => {
    if (v$.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

watch(currentEnvironment, async () => {
  populateState();
  await fetchWorkflows();
});

watch(gateway, () => populateState());

function populateState() {
  const gatewayCopy = deepCopy(gateway.value);
  state.description = gatewayCopy.description as string;

  if (gatewayCopy.gateway_type !== "test" && gatewayCopy.credentials) {
    for (const credential in gatewayCopy.credentials) {
      // Only adds non-sensitive credentials to state
      const fieldName = gatewayCopy.credentials[credential].name;
      state[fieldName] = gatewayCopy.credentials[credential].value;
      rules[fieldName] = { required };
    }
    credentialsAdded.value = true;
  }

  if (
    gatewayCopy.gateway_settings &&
    Object.keys(gatewayCopy.gateway_settings).length
  ) {
    state.settings = {};
    rules.settings = {};
    Object.keys(gatewayCopy.gateway_settings).forEach((key: string) => {
      state.settings[key] = gatewayCopy.gateway_settings[key];
      rules.settings[key] = {};
    });
    settingsAdded.value = true;
  }
}

function resetForm() {
  displayErrorMessage.value = false;
  populateState();
  submitted.value = false;
  v$.value.$reset();
}

function buildPayload(): Gateway {
  const stateCopy = deepCopy(state);
  const payload = {
    key: gateway.value.key,
    name: gateway.value.name,
    mode: gateway.value.mode,
    state: gateway.value.state,
    description: stateCopy.description,
    credentials: undefined,
    gateway_settings: stateCopy.settings,
  };
  delete stateCopy.settings;
  delete stateCopy.description;

  // remaining data is credentials
  payload.credentials = stateCopy;

  return payload;
}

async function checkForValidForm(isFormInvalid: boolean) {
  if (!v$.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = false;
  submitted.value = true;
  if (isFormInvalid) {
    return;
  }

  if (
    gateway.value.gateway_settings?.hasOwnProperty("enabled_network_tokens") &&
    state.settings.hasOwnProperty("enabled_network_tokens") &&
    !state.settings.enabled_network_tokens
  ) {
    confirmDisableNetworkTokenization();
  } else {
    await save();
  }
}

async function save() {
  formState.value = "saving";
  const response = await updateGateway(
    gateway.value.organization_key!,
    gateway.value.environment_key!,
    buildPayload()
  );

  if (response) {
    await store.fillGateways(true);
    formState.value = "saved";
    v$.value.$reset();
    submitted.value = false;
  } else {
    displayErrorMessage.value = true;
    formState.value = "saveChanges";
  }
}
</script>
