import { get, put, getList, post, httpDelete } from "@/http";

export type RecoverConfigurationPayload = {
  recover_configuration: {
    key?: string;
    version?: number;
    environment_key?: string;
    description?: string;
    inserted_at?: string;
    updated_at?: string;
    deleted?: boolean;
    retryable_failure_reasons?: string[];
  };
};

export type RecoverConfiguration = {
  key?: string;
  version?: number;
  retryable_failure_reasons: string[];
  inserted_at?: string;
  updated_at?: string;
  description: string;
  deleted?: boolean;
};

export async function createRecoverConfiguration(
  orgKey: string,
  envKey: string,
  config: RecoverConfigurationPayload
): Promise<RecoverConfiguration> {
  const url = `/organizations/${orgKey}/environments/${envKey}/recover_configurations`;
  return post(url, config, "recover_configuration");
}

export async function listRecoverConfigurations(
  orgKey: string,
  envKey: string
) {
  return getList<RecoverConfiguration>(
    `/organizations/${orgKey}/environments/${envKey}/recover_configurations`,
    "recover_configurations"
  );
}

export async function listFailureReasons(orgKey: string, envKey: string) {
  return getList<string>(
    `/organizations/${orgKey}/environments/${envKey}/recover_configurations/index_failure_reasons`
  );
}
export async function updateRecoverConfiguration(
  orgKey: string,
  envKey: string,
  configurationKey: string,
  configuration: RecoverConfigurationPayload
): Promise<RecoverConfiguration> {
  const url = `/organizations/${orgKey}/environments/${envKey}/recover_configurations/${configurationKey}`;
  return put(url, configuration, "recover_configuration");
}

export async function deleteRecoverConfiguration(
  orgKey: string,
  envKey: string,
  configurationKey: string
): Promise<void> {
  const url = `/organizations/${orgKey}/environments/${envKey}/recover_configurations/${configurationKey}`;
  return httpDelete(url);
}

export async function fetchRecoverConfiguration(
  orgKey: string,
  envKey: string,
  configurationKey: string
): Promise<RecoverConfiguration> {
  return get(
    `/organizations/${orgKey}/environments/${envKey}/recover_configurations/${configurationKey}`,
    "recover_configuration"
  );
}

export async function fetchRecoverConfigurationByVersion(
  orgKey: string,
  envKey: string,
  configurationKey: string,
  version: number
): Promise<RecoverConfiguration> {
  return get(
    `/organizations/${orgKey}/environments/${envKey}/recover_configurations/${configurationKey}/version/${version}`,
    "recover_configuration"
  );
}
