<template>
  <WorkflowVerticalStepper
    :position="step.priority"
    display-header
    :key="step.uuid"
  >
    <template v-slot:header>
      <div class="flex flex-row pt-2 w-full">
        <FloatLabel
          class="-ml-3"
          :pt="{ root: { class: 'w-full' } }"
          :title="v.description.$model"
        >
          <InputText
            :id="`step-description-input-${step.priority}`"
            v-model="v.description.$model"
            :disabled="!hasPermission"
            :maxlength="100"
            @update:model-value="
              steps[step.priority].description = v.description.$model
            "
          />
          <label :for="`step-description-input-${step.priority}`">{{
            $t("stepDescription", { stepIndex: step.priority })
          }}</label>
        </FloatLabel>
      </div>
    </template>
    <div v-if="step.condition_set" class="mb-2">
      <WorkflowConditionSet
        :key="step.condition_set.uuid"
        :step-index="step.priority"
        :condition-set="step.condition_set"
        @remove-condition="removeConditionFromSet"
      ></WorkflowConditionSet>
    </div>
    <div
      v-if="step.conclusions && step.conclusions.length > 0"
      class="bg-white rounded-lg border border-spreedly-gray-300 p-2 flex flex-col"
    >
      <div
        v-for="(conclusion, _index) in step.conclusions"
        class="mx-2 text-spreedly-gray-600"
      >
        <WorkflowConclusion
          :key="conclusion.uuid"
          :conclusion="conclusion"
          :conclusion-index="_index"
          :step-index="step.priority"
          @add-recover="addRecover"
          @remove-split="removeConclusionSplit"
        ></WorkflowConclusion>
      </div>
      <div class="flex flex-row mx-2">
        <Button
          v-if="gateways.length && step.conclusions.length < 2"
          :disabled="!hasPermission"
          :id="`add-split-${step.priority}`"
          type="button"
          @click="addSplit()"
          :label="$t('workflows.addSplit')"
          class="add-split-button p-button-text !text-spreedly-blue-600 hover:text-spreedly-blue-700 bg-transparent !shadow-none disabled:text-spreedly-gray-400"
        >
          <template #icon>
            <SpreedlyIcon
              name="callSplit"
              :size="18"
              class="mr-1"
            ></SpreedlyIcon>
          </template>
        </Button>
      </div>
    </div>
  </WorkflowVerticalStepper>
</template>
<script lang="ts" setup>
import WorkflowVerticalStepper from "@/components/WorkflowVerticalStepper.vue";
import WorkflowConclusion from "@/components/WorkflowConclusion.vue";
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";

import { computed, reactive } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { useVuelidate } from "@vuelidate/core";
import {
  useWorkflow,
  addGatewaySplit,
  removeSplit,
  addRecovery,
  removeCondition,
} from "@/composables/useWorkflow";
import type { WorkflowStep } from "@/services/WorkflowService";
import useEventBus from "@/composables/useEventBus";
import { deepCopy } from "@/services/HelperService";
import WorkflowConditionSet from "@/components/WorkflowConditionSet.vue";
import Button from "primevue/button";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";
const store = useSettingsStore();
const { action, steps, gateways } = useWorkflow();
const { emit } = useEventBus();
const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("environment.create_workflow");
});

const props = defineProps<{
  step: WorkflowStep;
}>();

const state = reactive({
  description: props.step.description || "",
});
const rules = {
  description: {},
};

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

const removeConditionFromSet = (conditionIndex: number) => {
  emit(
    "removeCondition",
    deepCopy(steps.value),
    props.step.condition_set?.conditions[conditionIndex].condition_type
  );
  removeCondition(conditionIndex, props.step.priority);
  v.value.$touch();
};

function addSplit() {
  addGatewaySplit(props.step.priority);
  v.value.$touch();
}
const removeConclusionSplit = (removeTarget: number) => {
  // Preserve copy of split configuration in case user wants to undo
  emit("removeSplit", deepCopy(steps.value));
  removeSplit(removeTarget, props.step.priority);
  v.value.$touch();
};
const addRecover = (conclusionIndex: number, stepIndex: number) => {
  addRecovery(conclusionIndex, stepIndex);
  v.value.$touch();
};
</script>
