<template>
  <div v-if="steps[stepIndex].conclusions" class="w-full mb-2">
    <div
      v-if="store.hasComposerEnabledGatewayConnections()"
      class="flex flex-row w-full"
    >
      <div class="flex flex-col w-full">
        <div
          class="flex flex-row mb-2"
          v-if="steps[stepIndex].conclusions!.length > 1"
        >
          <div class="self-center font-bold">
            <i18n-t
              scope="global"
              :keypath="'sendPercentTransactionsTo'"
              tag="p"
            >
              <template v-slot:percent>
                <InputNumber
                  v-model="v.weight.$model"
                  :disabled="!hasPermission"
                  buttonLayout="horizontal"
                  :aria-label="`workflow-split-${stepIndex}-${conclusionIndex}`"
                  :key="steps[stepIndex].conclusions![conclusionIndex].uuid"
                  :inputId="`workflow-split-${stepIndex}-${conclusionIndex}`"
                  @update:model-value="updateWorkflow()"
                  mode="decimal"
                  suffix="%"
                  :pt="{
                    input: {
                      root: {
                        class: 'text-center !text-spreedly-gray-600',
                      },
                    },
                    root: {
                      class:
                        '!h-[30px] !w-[45px] split-input bg-white !border-b-spreedly-blue-600 border !border-x-0 !border-t-0 font-sans font-bold mx-1',
                    },
                  }"
                  :min="0"
                  :max="100"
                />
              </template>
            </i18n-t>
          </div>
          <Button
            :disabled="!hasPermission"
            :label="$t('removeSplit')"
            :id="`remove-split-${steps[stepIndex]}-${conclusionIndex}`"
            type="button"
            @click="removeSplit(conclusionIndex)"
            class="mt-1 ml-2 text-xs remove-split-button text-spreedly-blue-600 hover:text-spreedly-blue-700 disabled:text-spreedly-gray-400 px-2"
          >
          </Button>
        </div>

        <div
          class="flex flex-col w-full"
          :class="{
          'border mb-2 border-spreedly-gray-300 border-solid surface-border rounded-md surface-ground flex-auto flex justify-content-center align-items-center font-medium p-2':
            steps[stepIndex].conclusions!.length > 1,
        }"
        >
          <div
            class="flex flex-row justify-start"
            :class="{'mx-2': steps[stepIndex].conclusions!.length > 1}"
          >
            <WorkflowResult
              :key="conclusion.result.uuid"
              :result="conclusion.result"
              :step-index="stepIndex"
              :conclusion-index="conclusionIndex"
            ></WorkflowResult>
          </div>
          <div class="flex flex-row mt-4">
            <Button
              v-if="!conclusion.result.params?.recover"
              :disabled="!hasPermission"
              :id="`add-recovery-strategy-button-${stepIndex}-${conclusionIndex}`"
              type="button"
              @click="addRecoveryStrategy()"
              :label="$t('recover.add')"
              icon="pi pi-plus"
              class="p-button-text !text-spreedly-blue-600 hover:text-spreedly-blue-700 bg-transparent !shadow-none"
            >
            </Button>
          </div>

          <OverlayPanel
            :id="`recover-info-overlay-panel-${stepIndex}-${conclusionIndex}`"
            ref="recoverInfoOverlayPanel"
            :pt="{
              root: {
                class:
                  'rounded-lg -mt-2 font-sans text-spreedly-gray-600 w-[475px]',
              },
            }"
          >
            <div
              class="bg-spreedly-blue-200 rounded-lg p-2 w-full"
              v-if="!store.currentOrganization.allow_recover"
            >
              <div class="flex flex-row my-2">
                <SpreedlyIcon
                  name="information"
                  class="text-spreedly-blue-700 pr-2"
                  data-testid="info-icon"
                ></SpreedlyIcon>
                <div class="font-semibold whitespace-pre-line">
                  {{ $t("recover.premiumService") }}
                </div>
              </div>

              <i18n-t
                scope="global"
                keypath="recover.docs"
                tag="p"
                class="whitespace-pre-line text-sm ml-8"
              >
                <template v-slot:learnMore>
                  <a
                    class="underline text-spreedly-blue-700 hover:text-spreedly-blue-800 cursor-pointer"
                    :href="`${docsUrl}/docs/recover-user-guide`"
                    target="_blank"
                    >{{ $t("learnMore").toLowerCase() }}</a
                  >
                </template>
              </i18n-t>
              <i18n-t
                scope="global"
                :keypath="'recover.reachOut'"
                tag="p"
                class="text-caption whitespace-pre-line ml-8 mt-2"
              >
                <template v-slot:support>
                  <a
                    class="underline cursor-pointer"
                    href="mailto:support@spreedly.com"
                    >{{ $t("spreedlySupport") }}</a
                  >
                </template>
              </i18n-t>
            </div>
          </OverlayPanel>

          <div :class="{'mx-2': steps[stepIndex].conclusions!.length > 1}">
            <WorkflowRecover
              :key="conclusion.result.uuid"
              @remove-recover="removeRecovery"
              @open-recover-info="openRecoverInfoOverlay"
              v-if="conclusion.result.params?.recover"
              :recover="conclusion.result.params?.recover"
              :step-index="stepIndex"
              :conclusion-index="conclusionIndex"
            ></WorkflowRecover>
          </div>
        </div>
      </div>
    </div>
    <Message
      v-else
      severity="info"
      :closable="false"
      :pt="{ root: { class: 'bg-spreedly-blue-200' } }"
    >
      <i18n-t
        :keypath="'workflows.noGateways'"
        tag="p"
        class="text-lg"
        scope="global"
      >
        <template v-slot:supportedGateway>
          <router-link
            :id="`marketplace-link-${stepIndex}-${conclusionIndex}`"
            class="marketplace-link text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
            :to="{ name: 'Marketplace' }"
            >{{ $t("workflows.supportedGateway") }}
          </router-link>
        </template>
      </i18n-t>
    </Message>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Message from "primevue/message";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  removeRecoveryFromConclusion,
  updateWeight,
  useWorkflow,
} from "@/composables/useWorkflow";
import WorkflowRecover from "@/components/WorkflowRecover.vue";
import WorkflowResult from "@/components/WorkflowResult.vue";
import type { WorkflowConclusion } from "@/services/WorkflowService";
import useEventBus from "@/composables/useEventBus";
import { deepCopy } from "@/services/HelperService";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";
import OverlayPanel from "primevue/overlaypanel";

const { action, steps, isSandbox } = useWorkflow();
const store = useSettingsStore();
const docsUrl = import.meta.env.VITE_DOCS_URL;
const recoverInfoOverlayPanel = ref();
const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("environment.create_workflow");
});

const props = defineProps<{
  conclusion: WorkflowConclusion;
  conclusionIndex: number;
  stepIndex: number;
}>();

const $emit = defineEmits(["removeSplit", "addSplit", "addRecover"]);

const state = reactive(props.conclusion);
const rules = {
  weight: { required },
};

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });
const { emit } = useEventBus();

const removeRecovery = () => {
  emit("removeRecover", deepCopy(steps.value));
  removeRecoveryFromConclusion(props.conclusionIndex, props.stepIndex);
  v.value.$touch();
};

watch(isSandbox, () => {
  if (!isSandbox.value && !store.currentOrganization.allow_recover) {
    removeRecoveryFromConclusion(props.conclusionIndex, props.stepIndex);
  }
});

const openRecoverInfoOverlay = () => {
  recoverInfoOverlayPanel.value.toggle(event);
};
function addRecoveryStrategy() {
  if (!store.currentOrganization.allow_recover && !isSandbox.value) {
    openRecoverInfoOverlay();
  } else {
    $emit("addRecover", props.conclusionIndex, props.stepIndex);
  }
}
function updateWorkflow() {
  if (!v.value.$anyDirty) {
    return;
  }

  v.value.$touch();
  updateWeight(state, props.conclusionIndex, props.stepIndex);
}

const removeSplit = (removeTarget: number) => {
  $emit("removeSplit", removeTarget);
};
</script>
<style lang="css" scoped>
:deep(.p-inputtext.p-inputnumber-input) {
  background-color: white;
}
</style>
