<template>
  <table>
    <thead>
      <tr class="sr-only">
        <th>Gateway</th>
        <th>Volume</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(ruleTarget, i) in ruleTargets" :key="'split-' + i">
        <td class="text-right">
          <span class="mr-1" aria-hidden="true">
            <template v-if="i === 0">
              {{ $t("routing.gateway.use") }}
            </template>
            <template v-else>
              {{ $t("and") }}
            </template>
          </span>
          <GatewayDescription
            :gateway="findGateway(gatewayList, ruleTarget.gateway_key)"
          />
        </td>
        <td>
          <figure
            class="font-bold mx-1 inline-block"
            :class="{ 'sr-only': ruleTargets.length === 1 }"
          >
            {{ ruleTarget.volume }}%
          </figure>
          <span v-if="ruleTargets.length > 1" aria-hidden="true">
            {{ $t("routing.gateway.splitTime") }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import GatewayDescription from "@/components/RoutingGatewayDescription.vue";
import type { Gateway } from "@/models/routing-rules/gateway.model";
import { findGateway } from "@/services/routing-rules/gateways";
import { useOptionsStore } from "@/stores/routing-rules/OptionsStore";
import type { RuleTarget } from "@/models/routing-rules/rule-target.model";
import { storeToRefs } from "pinia";

defineProps<{ ruleTargets: RuleTarget[] }>(); // TODO: define ruleTarget type

const { options } = storeToRefs(useOptionsStore());

const gatewayList: Gateway[] = options.value?.gateways ?? [];
</script>

<style scoped>
td {
  padding: 0.375rem 0;
}
</style>
