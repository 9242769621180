<template>
  <div class="flex flex-row bin-range pb-6">
    <div class="comparator self-start">
      <slot name="comparator"></slot>
      <div class="line"></div>
    </div>
    <div class="comparator-line">
      <div class="line"></div>
    </div>
    <div class="flex flex-row justify-start bin-range-content">
      <div class="mx-2 flex flex-col">
        <span
          class="p-float-label text-xs text-[#6c757d] -mt-5 mb-1"
          :class="{ 'p-error': v.upper.$invalid && submitted }"
          >{{ $t("workflows.binLowerRange") }}</span
        >
        <InputOtp
          :disabled="!hasPermission"
          v-model="v.lower.$model"
          :length="8"
          @blur="updateWorkflow"
          :pt="{
            root: {
              class:
                v.lower.$invalid && submitted
                  ? 'border !border-spreedly-red !w-[275px] !h-[42px] !mb-0 !gap-0'
                  : '!h-[42px] !w-[275px] !mb-0 !gap-0',
            },
          }"
          :id="`lower-bin-range-${stepIndex}-${conditionIndex}-${binRangeIndex}`"
        >
          <template #default="{ attrs, events, index }">
            <input
              onkeydown="return ['Backspace','Delete','ArrowLeft','ArrowRight','Tab'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
              :aria-label="`lower-bin-range-${stepIndex}-${conditionIndex}-${binRangeIndex}-${index}`"
              :id="`lower-bin-range-${stepIndex}-${conditionIndex}-${binRangeIndex}-${index}`"
              placeholder="*"
              type="number"
              v-bind="attrs"
              v-on="events"
              class="custom-otp-input"
            />
          </template>
        </InputOtp>
        <div v-if="v.lower.$invalid && submitted">
          <small class="p-error" v-if="v.lower.isSameLengthValidator.$invalid"
            >{{ v.lower.isSameLengthValidator.$message }}
          </small>
          <small v-else class="p-error"
            >{{ $t("validations.required") }}
          </small>
        </div>
      </div>
      <div class="-mx-[2px] mt-2">
        <span class="h-[42px]">{{ "-" }}</span>
      </div>
      <div class="self-center mx-2 flex flex-col">
        <span
          class="p-float-label text-xs text-[#6c757d] -mt-5 mb-1"
          :class="{ 'p-error': v.upper.$invalid && submitted }"
          >{{ $t("workflows.binUpperRange") }}</span
        >
        <InputOtp
          :disabled="!hasPermission"
          v-model="v.upper.$model"
          :length="8"
          @blur="updateWorkflow"
          :pt="{
            root: {
              class:
                v.upper.$invalid && submitted
                  ? 'border !border-spreedly-red !w-[275px] !h-[42px] !mb-0 !gap-0'
                  : '!h-[42px] !w-[275px] !mb-0 !gap-0',
            },
          }"
          :id="`upper-bin-range-${stepIndex}-${conditionIndex}-${binRangeIndex}`"
        >
          <template #default="{ attrs, events, index }">
            <input
              tabindex="0"
              onkeydown="return ['Backspace','Delete','ArrowLeft','ArrowRight','Tab'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
              :aria-label="`upper-bin-range-${stepIndex}-${conditionIndex}-${binRangeIndex}-${index}`"
              :id="`upper-bin-range-${stepIndex}-${conditionIndex}-${binRangeIndex}-${index}`"
              placeholder="*"
              type="number"
              v-bind="attrs"
              v-on="events"
              class="custom-otp-input"
            />
          </template>
        </InputOtp>
        <div v-if="v.upper.$invalid && submitted">
          <small class="p-error" v-if="v.upper.isSameLengthValidator.$invalid"
            >{{ v.upper.isSameLengthValidator.$message }}
          </small>
          <small v-else-if="!v.upper.$model" class="p-error"
            >{{ $t("validations.required") }}
          </small>
          <small
            class="p-error"
            v-else-if="v.upper.isValidRangeValidator.$invalid"
            >{{ v.upper.isValidRangeValidator.$message }}
          </small>
        </div>
      </div>
      <slot name="remove"></slot>
    </div>
  </div>
  <div
    class="add"
    v-if="
      binRangeIndex + 1 ===
      steps[stepIndex].condition_set.conditions[conditionIndex].values.length
    "
  >
    <slot name="add"></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { useVuelidate } from "@vuelidate/core";
import { useWorkflow } from "@/composables/useWorkflow";
import InputOtp from "primevue/inputotp";

import { helpers, required } from "@vuelidate/validators";
import type { BinRange } from "@/services/WorkflowService";
import i18n from "@/i18n";

const { action, submitted, steps } = useWorkflow();
const store = useSettingsStore();
const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("environment.create_workflow");
});

const isValidRange = helpers.withParams(
  { type: "isValidRange" },
  (upperValue: string, binRange: BinRange) => {
    let lower = parseInt(binRange.lower);
    let upper = parseInt(upperValue);
    if (lower.toString().length !== upper.toString().length) {
      return upper.toString().length > lower.toString().length;
    } else {
      return upper >= lower;
    }
  }
);

const isSameLength = helpers.withParams(
  { type: "isSameLength" },
  (value: string, binRange: BinRange) => {
    return binRange.lower.length === binRange.upper.length;
  }
);

const isValidRangeValidator = helpers.withMessage(
  i18n.global.t("validations.binRanges.validRange"),
  isValidRange
);

const isSameLengthValidator = helpers.withMessage(
  i18n.global.t("validations.binRanges.sameLength"),
  isSameLength
);
const props = defineProps<{
  stepIndex: number;
  conditionIndex: number;
  binRangeIndex: number;
  binRange: BinRange;
  invalidKey: boolean;
}>();

const state = reactive({
  lower: props.binRange.lower,
  upper: props.binRange.upper,
});

const rules = {
  lower: { required, isSameLengthValidator },
  upper: { required, isSameLengthValidator, isValidRangeValidator },
};

const emit = defineEmits(["addBinRange"]);

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

const updateWorkflow = () => {
  if (!v.value.$anyDirty) {
    return;
  }

  emit("addBinRange", props.binRangeIndex, {
    uuid: props.binRange.uuid,
    lower: state.lower,
    upper: state.upper,
  });
};
</script>
<style lang="css" scoped>
.bin-range:nth-child(2) .comparator {
  width: 40px;
  margin: 0.25rem;
}

.bin-range:nth-child(2) {
  position: relative;
}

.bin-range {
  margin-left: -0.5rem;
  position: relative;
}

.bin-range:nth-child(2) {
  margin-left: -3.5rem;
}
.bin-range .comparator {
  z-index: 10;
}

.add {
  margin-left: -3.5rem;
  margin-top: -1.5rem;
}

.add:nth-child(2) {
  margin-top: -1rem;
}

.bin-range:nth-child(2):not(:nth-last-child(2)) .comparator .line {
  position: absolute;
  border-left: 3px solid #8f9495;
  height: calc(100% - 3rem);
  left: 1.1rem;
}
.bin-range .comparator-line {
  width: 100px;
  margin-left: -6.1rem;
  position: relative;
  z-index: 0;
}
.bin-range:nth-child(3) .comparator-line .line {
  height: calc(100% + 0.3rem);
  margin-top: -1.5rem;
}
.bin-range:not(:nth-last-child(1)) .comparator-line .line {
  height: calc(100% + 1.8rem);
  margin-top: -1.5rem;
}

.bin-range:not(:nth-child(3)):nth-last-child(1) .comparator-line .line {
  height: 100%;
}
.bin-range .comparator-line .line {
  position: absolute;
  border-left: 3px solid #8f9495;
  left: 4.2rem;
}

.bin-range:first-child .comparator-line,
.bin-range:first-child .comparator-line .line,
.bin-range:nth-child(2) .comparator-line .line,
.bin-range:not(:nth-child(2)) .comparator,
.bin-range:not(:nth-child(2)) .comparator .line {
  display: none;
}

.custom-otp-input {
  width: 16px;
  font-size: 16px;
  appearance: none;
  text-align: center;
  transition: all 0.2s;
  border: 0 none;
  background: transparent;
}

.custom-otp-input:focus {
  outline: 0 none;
  border-bottom-color: #0077c8;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
