<template>
  <div>
    <SpreedlyPageTitle
      :title="$t('advancedVault.advancedVault')"
    ></SpreedlyPageTitle>
    <SpreedlyContainer>
      <Message
        v-if="displayAvErrorMessage"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="error"
        :closable="false"
        >{{ displayAvErrorMessage }}
      </Message>
      <form @submit.prevent="saveAdvancedVault(avV$.$invalid)">
        <div class="flex justify-left">
          <InputSwitch
            v-if="
              currentOrganization.allow_payment_method_management &&
              currentOrganization.payment_method_management_enabled
            "
            class="mr-4"
            input-id="payment-method-management-enabled-toggle"
            :disabled="!store.hasPermission('environment.update')"
            v-model="avV$.payment_method_management_enabled.$model"
          />
          <label
            class="inline-block text-spreedly-gray-600 text-lg"
            for="payment-method-management-enabled-toggle"
          >
            {{ $t("env.environmentLevelAdvancedVault") }}
          </label>
        </div>
        <div class="flex mt-2 text-sm text-spreedly-gray-600">
          <!-- admin allow AV is disabled -->
          <i18n-t
            scope="global"
            v-if="!currentOrganization.allow_payment_method_management"
            :keypath="'advancedVault.infoMessage.adminAvDisabled'"
            tag="p"
            class="text-caption"
          >
            <template v-slot:mailTo>
              <a
                class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                href="mailto:support@spreedly.com"
                >{{ $t("spreedlySupport") }}</a
              >
            </template>
          </i18n-t>
          <!-- admin allow AV is enabled and org AV is disabled -->
          <i18n-t
            scope="global"
            v-if="
              currentOrganization.allow_payment_method_management &&
              !currentOrganization.payment_method_management_enabled
            "
            :keypath="'advancedVault.infoMessage.orgAvDisabled'"
            tag="p"
            class="text-caption"
          >
            <template v-slot:organizationSettings>
              <router-link
                class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                :to="{ name: 'OrganizationSettings' }"
                >{{ $t("org.settings") }}
              </router-link>
            </template>
          </i18n-t>
          <!-- admin allow AV is enabled and org AV is enabled-->
          <i18n-t
            scope="global"
            v-if="
              currentOrganization.allow_payment_method_management &&
              currentOrganization.payment_method_management_enabled
            "
            :keypath="'advancedVault.infoMessage.avAllowed'"
            tag="p"
            class="text-caption"
          >
          </i18n-t>
        </div>
      </form>
      <div
        v-if="
          currentOrganization.payment_method_management_enabled &&
          store.hasPermission('environment.update')
        "
        class="m flex flex-shrink-0 flex-wrap items-center justify-start rounded-b-md mt-6"
      >
        <SpreedlyButton
          :text="$t('cancel')"
          :inverse="true"
          :disabled="!avV$.$anyDirty || avFormState === 'saving'"
          @click="resetAdvancedVault()"
          class="mr-6"
        ></SpreedlyButton>
        <SpreedlyButton
          :text="$t(avFormState)"
          :icon="{ position: 'left', state: avFormState }"
          :disabled="!avV$.$anyDirty"
          class="!mr-0"
          @click="saveAdvancedVault(avV$.$invalid)"
        ></SpreedlyButton>
      </div>
    </SpreedlyContainer>
    <SpreedlyContainer>
      <Message
        v-if="displayAuErrorMessage"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="error"
        :closable="false"
        >{{ displayAuErrorMessage }}
      </Message>
      <form @submit.prevent="saveAccountUpdater(auV$.$invalid)">
        <div>
          <div class="flex justify-left">
            <InputSwitch
              v-if="
                currentOrganization.allow_account_updater &&
                currentOrganization.account_updater_enabled &&
                currentOrganization.allow_au_by_environment
              "
              input-id="au-enabled-toggle"
              id="au-enabled-toggle"
              class="mr-4"
              :disabled="!store.hasPermission('environment.update')"
              v-model="auV$.au_enabled.$model"
            />
            <label
              class="inline-block text-spreedly-gray-600 text-lg"
              for="au-enabled-toggle"
            >
              {{ $t("env.accountUpdater") }}
            </label>
          </div>
          <div class="flex mt-2 text-sm text-spreedly-gray-600">
            <!-- admin allow AU is disabled -->
            <i18n-t
              scope="global"
              v-if="!currentOrganization.allow_account_updater"
              :keypath="'accountUpdater.infoMessage.adminAuDisabled'"
              tag="p"
              class="text-caption"
            >
              <template v-slot:mailTo>
                <a
                  class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                  href="mailto:support@spreedly.com"
                  >{{ $t("spreedlySupport") }}</a
                >
              </template>
            </i18n-t>
            <!-- admin allow AU is enabled and org AU is disabled -->
            <i18n-t
              scope="global"
              v-if="
                currentOrganization.allow_account_updater &&
                !currentOrganization.account_updater_enabled
              "
              :keypath="'accountUpdater.infoMessage.orgAuDisabled'"
              tag="p"
              class="text-caption"
            >
              <template v-slot:organizationSettings>
                <router-link
                  class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                  :to="{ name: 'OrganizationSettings' }"
                  >{{ $t("org.settings") }}
                </router-link>
              </template>
            </i18n-t>
            <!-- org allow au by environment is disabled -->
            <i18n-t
              scope="global"
              v-if="
                currentOrganization.allow_account_updater &&
                currentOrganization.account_updater_enabled &&
                !currentOrganization.allow_au_by_environment
              "
              :keypath="'accountUpdater.infoMessage.envAuDisabled'"
              tag="p"
              class="text-caption"
            >
              <template v-slot:organizationSettings>
                <router-link
                  class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                  :to="{ name: 'OrganizationSettings' }"
                  >{{ $t("org.settings") }}
                </router-link>
              </template>
            </i18n-t>
          </div>
        </div>
        <div
          class="mt-4 text-spreedly-gray-600"
          v-if="
            currentOrganization.allow_account_updater &&
            currentOrganization.account_updater_enabled &&
            currentOrganization.allow_account_updater_callbacks
          "
        >
          <label for="callback-url-input">
            {{ $t("callback_url") }}
          </label>
          <div class="text-sm">
            <i18n-t
              scope="global"
              keypath="env.infoMessage.callbackUrl"
              tag="p"
              class="text-caption"
            >
              <template v-slot:link>
                <a
                  class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                  :href="docsCallbackUrl"
                  target="_blank"
                  >{{ $t("env.infoMessage.callbackUrlLink") }}</a
                >
              </template>
            </i18n-t>
          </div>
          <div class="p-float-label mt-2 w-full lg:w-2/3 2xl:w-1/3 pb-4">
            <InputText
              :disabled="!store.hasPermission('environment.update')"
              id="callback-url-input"
              aria-describedby="callback-url-error"
              v-model.trim="auV$.callback_url.$model"
              type="text"
              class="w-1/2"
            />
          </div>

          <div
            v-if="auV$.callback_url.$invalid && auSubmitted"
            class="mt-0 mb-4"
          >
            <small class="p-error">{{ auV$.callback_url.url.$message }}</small>
          </div>
        </div>
      </form>

      <div
        v-if="
          currentOrganization.allow_account_updater &&
          currentOrganization.account_updater_enabled &&
          store.hasPermission('environment.update')
        "
        class="flex flex-shrink-0 flex-wrap items-center justify-start rounded-b-md mt-4"
      >
        <SpreedlyButton
          :text="$t('cancel')"
          :inverse="true"
          :disabled="!auV$.$anyDirty || auFormState === 'saving'"
          @click="resetAccountUpdater()"
          class="mr-6"
        ></SpreedlyButton>
        <SpreedlyButton
          :text="$t(auFormState)"
          :icon="{ position: 'left', state: auFormState }"
          class="!mr-0"
          :disabled="!auV$.$anyDirty"
          @click="saveAccountUpdater(auV$.$invalid)"
        ></SpreedlyButton>
      </div>
    </SpreedlyContainer>
  </div>
</template>

<script setup lang="ts">
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";

import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Message from "primevue/message";

import { reactive, ref, watch } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";
import {
  type Environment,
  updateEnvironment,
} from "@/services/EnvironmentService";
import { deepCopy } from "@/services/HelperService";
import { useVuelidate } from "@vuelidate/core";
import { url } from "@vuelidate/validators";
import { onBeforeRouteLeave } from "vue-router";
import i18n from "@/i18n";

const store = useSettingsStore();
const { currentOrganization, currentEnvironment } = storeToRefs(store);

/********************************
 * ADVANCED VAULT RELATED CODE  *
 ********************************/

const displayAvErrorMessage = ref<string | null>(null);
const avSubmitted = ref(false);
const avFormState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const avState = reactive({
  payment_method_management_enabled: deepCopy(
    currentEnvironment.value.payment_method_management_enabled
  ),
});
const avRules = {
  payment_method_management_enabled: {},
};

const avV$ = useVuelidate(avRules, avState);

watch(
  () => avV$.value.$anyDirty,
  () => {
    if (avV$.value.$anyDirty) {
      avFormState.value = "saveChanges";
    }
  }
);

async function saveAdvancedVault(isFormInvalid: boolean) {
  if (!avV$.value.$anyDirty || avFormState.value === "saving") {
    return;
  }
  displayAvErrorMessage.value = null;
  if (!store.hasPermission("organization.update_settings")) {
    displayAvErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }
  avSubmitted.value = true;
  if (isFormInvalid) {
    return;
  }

  avFormState.value = "saving";
  const env: Environment = {
    key: currentEnvironment.value.key,
    payment_method_management_enabled:
      avState.payment_method_management_enabled,
  };

  if (await updateEnvironment(currentOrganization.value.key, env)) {
    await store.fillEnvironments(true);
    avFormState.value = "saved";
    avState.payment_method_management_enabled = deepCopy(
      currentEnvironment.value.payment_method_management_enabled
    );
    avV$.value.$reset();
  } else {
    resetAdvancedVault();
    displayAvErrorMessage.value = i18n.global.t("errorMessage.generic");
    avFormState.value = "saveChanges";
  }
}

function resetAdvancedVault() {
  avState.payment_method_management_enabled = deepCopy(
    currentEnvironment.value.payment_method_management_enabled
  );
  displayAvErrorMessage.value = null;
  avSubmitted.value = false;
  avV$.value.$reset();
}

/********************************
 * ACCOUNT UPDATER RELATED CODE *
 ********************************/
const docsCallbackUrl = `${
  import.meta.env.VITE_DOCS_URL
}/docs/account-updater#callbacks`;

const auSubmitted = ref(false);
const displayAuErrorMessage = ref<string | null>(null);
const auFormState = ref<"saveChanges" | "saving" | "saved">("saveChanges");

const auState = reactive({
  au_enabled: deepCopy(currentEnvironment.value.au_enabled),
  callback_url: deepCopy(currentEnvironment.value.callback_url),
});

const auRules = {
  au_enabled: {},
  callback_url: { url },
};

const auV$ = useVuelidate(auRules, auState);

watch(
  () => auV$.value.$anyDirty,
  () => {
    if (auV$.value.$anyDirty) {
      auFormState.value = "saveChanges";
    }
  }
);

async function saveAccountUpdater(isFormInvalid: boolean) {
  if (!auV$.value.$anyDirty || auFormState.value === "saving") {
    return;
  }
  displayAuErrorMessage.value = null;

  if (!store.hasPermission("environment.update")) {
    displayAuErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }
  auSubmitted.value = true;
  if (isFormInvalid) {
    return;
  }

  auFormState.value = "saving";
  const env = {
    key: currentEnvironment.value.key,
    name: currentEnvironment.value.name,
    payment_method_submission:
      currentEnvironment.value.payment_method_submission,
    au_enabled: auState.au_enabled,
    callback_url: auState.callback_url,
  };

  if (await updateEnvironment(currentOrganization.value.key, env)) {
    await store.fillEnvironments(true);
    auFormState.value = "saved";
    auV$.value.$reset();
  } else {
    resetAccountUpdater();
    displayAuErrorMessage.value = i18n.global.t("errorMessage.generic");
    auFormState.value = "saveChanges";
  }
}

function resetAccountUpdater() {
  displayAuErrorMessage.value = null;
  auSubmitted.value = false;
  auState.au_enabled = deepCopy(currentEnvironment.value.au_enabled);
  auState.callback_url = deepCopy(
    currentEnvironment.value.callback_url
  ) as string;
  auV$.value.$reset();
}

onBeforeRouteLeave((to, from) => {
  if (auV$.value.$anyDirty || avV$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});
</script>
