<template>
  <div
    class="h-[70vh] p-2 border drop-shadow-sm safari-overflow-hidden !rounded-lg mb-8 lg:mb-12 overflow-y overflow-x-auto"
  >
    <pre v-html="syntaxHighlight(rawJson)"></pre>
  </div>
</template>
<script lang="ts" setup>
defineProps<{
  rawJson: string;
}>();

function syntaxHighlight(json: string) {
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      let cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}
</script>
<style lang="css">
.string {
  color: #00856f;
}

.number {
  color: #676b6c;
}

.boolean {
  color: #be4b00;
}

.null {
  color: #b1399e;
}

.key {
  color: #0077c8;
}
</style>
