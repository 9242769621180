import {
  createRouter,
  createWebHistory,
  type RouteLocation,
  type RouteLocationNormalized,
  type RouteRecordRaw,
} from "vue-router";
import { authGuard } from "@auth0/auth0-vue";
import ReportView from "@/components/ReportView.vue";
import ReportEnvironmentOverview from "@/components/ReportEnvironmentOverview.vue";
import ReportSuccessRates from "@/components/ReportSuccessRates.vue";
import ReportFailures from "@/components/ReportFailures.vue";
import Report3DS2 from "@/components/Report3DS2.vue";
import ReportPaymentMethods from "@/components/ReportPaymentMethods.vue";
import ReportAccountUpdater from "@/components/ReportAccountUpdater.vue";
import ReportRoutingRules from "@/components/ReportRoutingRules.vue";
import ReportTransactions from "@/components/ReportTransactions.vue";
import ReportMerchantAggregator from "@/components/ReportMerchantAggregator.vue";
import CustomAnalytics from "@/components/CustomAnalytics.vue";
import TransactionList from "@/components/TransactionList.vue";
import TransactionDetail from "@/components/TransactionDetail.vue";
import GatewayList from "@/components/GatewayList.vue";
import MarketplaceWrapper from "@/components/MarketplaceWrapper.vue";
import RoutingRules from "@/components/RoutingRules.vue";
import AdvancedVault from "@/components/AdvancedVault.vue";

import EnvironmentSettings from "@/components/EnvironmentSettings.vue";
import EnvironmentAccessSecrets from "@/components/EnvironmentAccessSecrets.vue";
import EnvironmentSigningSecret from "@/components/EnvironmentSigningSecret.vue";

import OrganizationAccessSecrets from "@/components/OrganizationAccessSecrets.vue";
import ReportOrganizationView from "@/components/ReportOrganizationView.vue";
import ReportOrganizationOverview from "@/components/ReportOrganizationOverview.vue";
import OrganizationSettings from "@/components/OrganizationSettings.vue";
import OrganizationUserList from "@/components/OrganizationUserList.vue";
import OrganizationUserCreate from "@/components/OrganizationUserCreate.vue";
import OrganizationUserSettings from "@/components/OrganizationUserSettings.vue";
import OrganizationActivityLog from "@/components/OrganizationActivityLog.vue";
import EnvironmentList from "@/components/EnvironmentList.vue";
import NotFound from "@/components/NotFound.vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import i18n from "@/i18n/index";
import ProfileSettings from "@/components/ProfileSettings.vue";
import GatewayDetail from "@/components/GatewayDetail.vue";
import PasswordReset from "@/components/PasswordReset.vue";
import { auth0 } from "@/plugins/createAuth0";
import GatewayCreate from "@/components/GatewayCreate.vue";
import EnvironmentCreate from "@/components/EnvironmentCreate.vue";
import TransactionDetailSummary from "@/components/TransactionDetailSummary.vue";
import TransactionDetailJson from "@/components/TransactionDetailJson.vue";
import TransactionDetailTranscript from "@/components/TransactionDetailTranscript.vue";
import GatewayTransactions from "@/components/GatewayTransactions.vue";
import GatewayDetailSummary from "@/components/GatewayDetailSummary.vue";
import EnvironmentOverview from "@/components/EnvironmentOverview.vue";
import GatewayListPartner from "@/components/GatewayListPartner.vue";
import ComposerView from "@/components/ComposerView.vue";
import WorkflowList from "@/components/WorkflowList.vue";
import WorkflowCreate from "@/components/WorkflowCreate.vue";
import WorkflowSettings from "@/components/WorkflowSettings.vue";
import RecoverConfigurationList from "@/components/RecoverConfigurationList.vue";
import RecoverConfigurationCreate from "@/components/RecoverConfigurationCreate.vue";
import RecoverConfigurationSettings from "@/components/RecoverConfigurationSettings.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "EnvironmentOverview",
    component: EnvironmentOverview,
    meta: {
      requiredPermissions: "organization.list_environments",
      title: "Overview",
    },
  },
  {
    path: "/reports",
    component: ReportView,
    meta: { requiredPermissions: "dashboard.read" },
    children: [
      {
        path: "",
        name: "ReportEnvironmentOverview",
        component: ReportEnvironmentOverview,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Environment Overview Report",
        },
      },
      {
        path: "success",
        name: "ReportSuccessRates",
        component: ReportSuccessRates,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Success Rates Report",
        },
      },
      {
        path: "failures",
        name: "ReportFailures",
        component: ReportFailures,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Failure Report",
        },
      },
      {
        path: "3DS2",
        name: "Report3DS2",
        component: Report3DS2,
        meta: { requiredPermissions: "dashboard.read", title: "3DS2 Report" },
      },
      {
        path: "payment-methods",
        name: "ReportPaymentMethods",
        component: ReportPaymentMethods,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Payment Methods Report",
        },
      },
      {
        path: "lifecycle-management",
        name: "ReportAccountUpdater",
        component: ReportAccountUpdater,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Account Updater Report",
        },
      },
      {
        path: "transactions",
        name: "ReportTransactions",
        component: ReportTransactions,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Transactions Report",
        },
      },
      {
        path: "recover",
        name: "ReportRoutingRules",
        component: ReportRoutingRules,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Routing Rules Report",
        },
      },
      {
        path: "merchant-aggregator",
        name: "ReportMerchantAggregator",
        component: ReportMerchantAggregator,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Merchant Aggregator Report",
        },
      },
    ],
  },
  {
    path: "/custom-analytics",
    name: "CustomAnalytics",
    component: CustomAnalytics,
    meta: { requiredPermissions: "dashboard.read", title: "Custom Analytics" },
  },
  {
    path: "/transactions",
    name: "TransactionList",
    component: TransactionList,
    meta: {
      requiredPermissions: "transaction.read",
      title: "Transaction List",
    },
  },
  {
    path: "/transactions/search",
    name: "TransactionSearch",
    redirect: "",
    beforeEnter: (to: RouteLocationNormalized) => {
      window.location.href = `/transaction-details/${to.query.token}`;
    },
  },
  {
    path: "/transaction-details/:id",
    name: "TransactionDetail",
    component: TransactionDetail,
    props: true,
    meta: {
      requiredPermissions: "transaction.read",
    },
    children: [
      {
        path: "",
        name: "TransactionDetailSummary",
        component: TransactionDetailSummary,
        props: true,
        meta: {
          requiredPermissions: "transaction.read",
          title: "Transaction Detail Summary",
        },
      },
      {
        path: "json",
        name: "TransactionDetailJson",
        component: TransactionDetailJson,
        props: true,
        meta: {
          requiredPermissions: "transaction.read",
          title: "Transaction Detail JSON",
        },
      },
      {
        path: "transcript",
        name: "TransactionDetailTranscript",
        component: TransactionDetailTranscript,
        props: true,
        meta: {
          requiredPermissions: "transaction.read",
          title: "Transaction Detail Transcript",
        },
      },
    ],
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: MarketplaceWrapper,
    meta: {
      marketplace: true,
      requiredPermissions: "gateway.read",
    },
  },
  {
    path: "/gateways/all",
    name: "GatewayList",
    component: GatewayList,
    meta: {
      requiredPermissions: "gateway.read",
      title: "All Gateways",
    },
  },
  {
    path: "/composer",
    name: "Composer",
    component: ComposerView,
    meta: {
      composer: true,
      requiredPermissions: "workflow.read",
    },
    children: [
      {
        path: "",
        name: "WorkflowList",
        component: WorkflowList,
        meta: {
          composer: true,
          requiredPermissions: "workflow.read",
          title: "Composer",
        },
      },
      {
        path: "recover-configurations",
        name: "RecoverConfigurationList",
        component: RecoverConfigurationList,
        meta: {
          composer: true,
          requiredPermissions: "workflow.read",
          title: "Recover Configurations",
        },
      },
    ],
  },
  {
    path: "/composer/recover-configurations/create",
    name: "RecoverConfigurationCreate",
    component: RecoverConfigurationCreate,
    meta: {
      composer: true,
      requiredPermissions: "environment.create_workflow",
      title: "Create Recover Configuration",
    },
  },
  {
    path: "/composer/recover-configurations/edit/:id",
    name: "RecoverConfigurationSettings",
    component: RecoverConfigurationSettings,
    meta: {
      composer: true,
      requiredPermissions: "workflow.read",
      title: "Recover Configuration Settings",
    },
    props: true,
  },
  {
    path: "/gateways/:id",
    name: "PartnerGatewayList",
    component: GatewayListPartner,
    meta: {
      marketplace: true,
      requiredPermissions: "gateway.read",
    },
    props: true,
  },
  {
    path: "/gateways/:gatewayType/:id",
    name: "PartnerGatewayDetail",
    component: GatewayDetail,
    props: true,
    meta: {
      marketplace: true,
      requiredPermissions: "gateway.read",
    },
    children: [
      {
        path: "",
        name: "PartnerGatewayDetailSummary",
        component: GatewayDetailSummary,
        props: true,
        meta: {
          marketplace: true,
          requiredPermissions: "gateway.read",
          title: "Gateway Detail Summary",
        },
      },
      {
        path: "transactions",
        name: "PartnerGatewayTransactions",
        component: GatewayTransactions,
        props: true,
        meta: {
          marketplace: true,
          requiredPermissions: "transaction.read",
          title: "Gateway Transactions",
        },
      },
    ],
  },
  {
    path: "/gateways/create",
    name: "GatewayCreate",
    component: GatewayCreate,
    meta: {
      requiredPermissions: "environment.create_gateway",
      title: "Create Gateway",
    },
  },
  {
    path: "/gateways/:gatewayType/create",
    name: "PartnerGatewayCreate",
    component: GatewayCreate,
    props: true,
    meta: {
      requiredPermissions: "environment.create_gateway",
      title: "Create Gateway",
      marketplace: true,
    },
  },
  {
    path: "/gateway-details/:id",
    name: "GatewayDetail",
    component: GatewayDetail,
    props: true,
    meta: { requiredPermissions: "gateway.read" },
    children: [
      {
        path: "",
        name: "GatewayDetailSummary",
        component: GatewayDetailSummary,
        props: true,
        meta: {
          requiredPermissions: "gateway.read",
          title: "Gateway Detail Summary",
        },
      },
      {
        path: "transactions",
        name: "GatewayTransactions",
        component: GatewayTransactions,
        props: true,
        meta: {
          requiredPermissions: "transaction.read",
          title: "Gateway Transactions",
        },
      },
    ],
  },
  {
    path: "/routing-rules",
    name: "RoutingRules",
    component: RoutingRules,
    meta: {
      requiredPermissions: "routing_rules.update",
      title: "Routing Rules",
    },
  },
  {
    path: "/advanced-vault",
    name: "AdvancedVault",
    component: AdvancedVault,
    meta: { requiredPermissions: "environment.read", title: "Advanced Vault" },
  },
  {
    path: "/current-environment/:id",
    name: "CurrentEnvironment",
    component: EnvironmentSettings,
    meta: {
      requiredPermissions: "organization.list_environments",
      title: "Environment Settings",
    },
    props: true,
  },
  {
    path: "/environment/:id",
    name: "EnvironmentSettings",
    component: EnvironmentSettings,
    meta: {
      requiredPermissions: "organization.list_environments",
      title: "Environment Settings",
    },
    props: true,
  },
  {
    path: "/environment/create",
    name: "EnvironmentCreate",
    component: EnvironmentCreate,
    meta: {
      requiredPermissions: "organization.create_environment",
      title: "Create Environment",
    },
    props: true,
  },
  {
    path: "/environment-access-secrets",
    name: "EnvironmentAccessSecrets",
    component: EnvironmentAccessSecrets,
    meta: {
      requiredPermissions: "organization.list_access_secrets",
      title: "Environment Access Secrets",
    },
  },
  {
    path: "/environment-signing-secret",
    name: "EnvironmentSigningSecret",
    component: EnvironmentSigningSecret,
    meta: {
      requiredPermissions: "environment.update",
      title: "Environment Signing Secret",
    },
  },
  {
    path: "/environments",
    name: "EnvironmentList",
    component: EnvironmentList,
    meta: {
      requiredPermissions: "organization.list_environments",
      title: "Environment List",
    },
  },
  {
    path: "/organization-access-secrets",
    name: "OrganizationAccessSecrets",
    component: OrganizationAccessSecrets,
    meta: {
      requiredPermissions: "organization.list_access_secrets",
      title: "Organization Access Secrets",
    },
  },
  {
    path: "/organization-reports",
    name: "ReportOrganizationView",
    component: ReportOrganizationView,
    meta: { requiredPermissions: "dashboard.read" },
    children: [
      {
        path: "",
        name: "ReportOrganizationOverview",
        component: ReportOrganizationOverview,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Organization Overview Report",
        },
        props: { orgView: true },
      },
      {
        path: "success",
        name: "ReportOrganizationSuccessRates",
        component: ReportSuccessRates,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Organization Success Rates Report",
        },
        props: { orgView: true },
      },
      {
        path: "failures",
        name: "ReportOrganizationFailures",
        component: ReportFailures,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Organization Failure Report",
        },
        props: { orgView: true },
      },
      {
        path: "3DS2",
        name: "ReportOrganization3DS2",
        component: Report3DS2,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Organization 3DS2 Report",
        },
        props: { orgView: true },
      },
      {
        path: "payment-methods",
        name: "ReportOrganizationPaymentMethods",
        component: ReportPaymentMethods,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Organization Payment Methods Report",
        },
        props: { orgView: true },
      },
      {
        path: "lifecycle-management",
        name: "ReportOrganizationAccountUpdater",
        component: ReportAccountUpdater,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Organization Account Updater Report",
        },
        props: { orgView: true },
      },
      {
        path: "transactions",
        name: "ReportOrganizationTransactions",
        component: ReportTransactions,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Organization Transactions Report",
        },
        props: { orgView: true },
      },
      {
        path: "recover",
        name: "ReportOrganizationRoutingRules",
        component: ReportRoutingRules,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Organization Routing Rules Report",
        },
        props: { orgView: true },
      },
      {
        path: "merchant-aggregator",
        name: "ReportOrganizationMerchantAggregator",
        component: ReportMerchantAggregator,
        meta: {
          requiredPermissions: "dashboard.read",
          title: "Organization Merchant Aggregator Report",
        },
        props: { orgView: true },
      },
    ],
  },
  {
    path: "/organization-settings",
    name: "OrganizationSettings",
    component: OrganizationSettings,
    meta: { requiredPermissions: "", title: "Organization Settings" },
  },
  {
    path: "/organization-users",
    name: "OrganizationUserList",
    component: OrganizationUserList,
    meta: {
      requiredPermissions: "organization.assign_roles",
      title: "Organization User List",
    },
  },
  {
    path: "/edit-organization-user/:id",
    name: "OrganizationUserSettings",
    component: OrganizationUserSettings,
    props: true,
    meta: {
      requiredPermissions: "organization.assign_roles",
      title: "Edit Organization User",
    },
  },
  {
    path: "/add-organization-user",
    name: "OrganizationUserCreate",
    component: OrganizationUserCreate,
    meta: {
      requiredPermissions: "organization.add_user",
      title: "Add Organization User",
    },
  },

  {
    path: "/activity-log",
    name: "OrganizationActivityLog",
    component: OrganizationActivityLog,
    meta: {
      requiredPermissions: "activity_log.read",
      title: "Organization Activity Log",
    },
  },
  {
    path: "/profile-settings",
    name: "ProfileSettings",
    component: ProfileSettings,
    meta: { requiredPermissions: "", title: "Profile Settings" },
  },
  {
    path: "/stripeConnection",
    name: "StripeConnection",
    redirect: "",
    beforeEnter: (to: RouteLocationNormalized) => {
      window.location.href = `/current-environment/${to.query.state}?stripeCode=${to.query.code}&environmentKey=${to.query.state}`;
    },
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: PasswordReset,
    meta: { title: "Reset Password" },
  },
  {
    path: "/composer/workflows/create",
    name: "WorkflowCreate",
    component: WorkflowCreate,
    meta: {
      composer: true,
      title: "Workflow Designer",
      requiredPermissions: "environment.create_workflow",
    },
  },
  {
    path: "/composer/workflows/edit/:id",
    name: "WorkflowSettings",
    component: WorkflowSettings,
    meta: {
      composer: true,
      title: "Workflow Designer",
      requiredPermissions: "workflow.read",
    },
    props: true,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
});
router.beforeResolve(async (to: RouteLocation, from: RouteLocation, next) => {
  if (to.name === "PasswordReset" && !auth0.isAuthenticated.value) {
    next();
    return;
  } else if (to.name === "PasswordReset" && auth0.isAuthenticated.value) {
    // redirect to from path if user is authenticated trying to reach /password-reset path
    next(`${from.path}`);
    return;
  }

  document.title = (to.meta?.title as string) || "Spreedly";
  await authGuard(to);

  if (to.name === "NotFound" || !to.meta.requiredPermissions) {
    next();
    return;
  }

  const store = useSettingsStore();
  if (store.userPermissions.length === 0) {
    await store.fillOrganizations();
  }

  if (
    (to.name === "RoutingRules" || to.name === "ReportRoutingRules") &&
    !store.hasAccessToRoutingRules
  ) {
    alert(i18n.global.t("permission_denied"));
    next(`${from.path}`);
    return;
  }

  if (to.meta.marketplace && !store.hasAccessToMarketplace) {
    alert(i18n.global.t("permission_denied"));
    next(`${from.path}`);
    return;
  }

  if (to.meta.composer && !store.hasAccessToComposer) {
    alert(i18n.global.t("permission_denied"));
    next(`${from.path}`);
    return;
  }

  if (store.user.key) {
    const userPermissions = store.userPermissions;
    const requiredPermissions = to.meta.requiredPermissions as string;

    if (userPermissions.includes(requiredPermissions)) {
      next();
    } else {
      // user exists but does not have the necessary permissions
      alert(i18n.global.t("permission_denied"));
      next(`${from.path}`);
      return;
    }
  } else {
    next();
  }
});

router.onError((error, to: RouteLocationNormalized) => {
  if (
    error.message.includes("Failed to fetch dynamically imported module") ||
    error.message.includes("Importing a module script failed")
  ) {
    window.location.href = to.fullPath;
  }
});

export { routes };

export default router;
