<template>
  <div>
    <ConfirmDialog
      group="default-workflow-warning"
      id="warning-dialog"
    ></ConfirmDialog>
    <SpreedlySubtitle :title="sentenceCase($t('workflows.workflow', 2))">
      <template v-slot:info-text>
        <div
          class="flex flex-col md:flex-col lg:flex-row lg:justify-between pb-8 text-spreedly-gray-600"
        >
          <i18n-t
            scope="global"
            :keypath="'workflows.description'"
            tag="p"
            class="text-caption"
          >
            <template v-slot:supportedGateway>
              <a
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                :href="`${docsUrl}/docs/composer#gateways-available-in-composer`"
                target="_blank"
                >{{ $t("workflows.supportedGateway", 2) }}</a
              >
            </template>
          </i18n-t>
        </div>
      </template>
    </SpreedlySubtitle>
    <Message
      v-if="!store.hasComposerEnabledGatewayConnections()"
      severity="info"
      :closable="false"
      :pt="{ root: { class: 'bg-spreedly-blue-200' } }"
    >
      <i18n-t
        :keypath="'workflows.noGateways'"
        tag="p"
        class="text-lg"
        scope="global"
      >
        <template v-slot:supportedGateway>
          <router-link
            class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
            id="marketplace-link"
            :to="{ name: 'Marketplace' }"
            >{{ $t("workflows.supportedGateway") }}
          </router-link>
        </template>
      </i18n-t>
    </Message>

    <div
      v-if="workflows && workflows.length === 0 && !isLoading"
      class="bg-teal-400 bg-opacity-5 drop-shadow-sm !rounded-lg p-8"
    >
      <div class="text-center my-20">
        <div class="mb-12 text-sky-800">
          <h2 class="text-4xl font-bold mb-5">
            {{ $t("workflows.createHeading") }}
          </h2>
          <p class="text-lg">{{ $t("workflows.createDescription") }}</p>
        </div>
        <SpreedlyButton
          :disabled="!store.hasComposerEnabledGatewayConnections()"
          @click="router.push({ name: 'WorkflowCreate' })"
          id="create-workflow-button"
          :text="$t('workflows.createAction')"
          :icon="{ position: 'left', name: 'plus' }"
          v-if="store.hasPermission('environment.create_workflow')"
        ></SpreedlyButton>
      </div>
    </div>

    <div v-else-if="workflows && workflows.length > 0 && !isLoading">
      <SpreedlyButton
        @click="router.push({ name: 'WorkflowCreate' })"
        :text="$t('workflows.createAction')"
        v-if="store.hasPermission('environment.create_workflow')"
        :icon="{ position: 'left', name: 'plus' }"
        class="mb-4"
        id="create-workflow-button"
      ></SpreedlyButton>
      <div
        v-for="workflow in paginatedWorkflows"
        :key="workflow.key"
        class="w-full inline-flex overflow-hidden safari-overflow-hidden drop-shadow-sm my-2 rounded-lg bg-white border text-spreedly-gray-600"
        :class="{
          'border-spreedly-teal border-2':
            workflow.key === store.currentEnvironment.default_workflow_key &&
            !isRefreshing,
        }"
      >
        <div class="flex flex-row w-full justify-between p-4">
          <div
            class="grid grid-cols-1 lg:grid-cols-2 gap-x-4 w-[calc(100%-170px)]"
          >
            <div class="col-span-1 mr-4">
              <button
                v-if="!isRefreshing"
                :id="`workflow-name-${workflow.key}`"
                class="font-bold cursor-pointer text-ellipsis whitespace-nowrap overflow-hidden"
                @click="
                  router.push({
                    name: 'WorkflowSettings',
                    params: { id: workflow.key },
                  })
                "
              >
                {{ workflow.name }}
              </button>
              <Skeleton v-else height="2rem" width="15rem"></Skeleton>
            </div>
            <div class="col-span-1">
              <div v-if="!isRefreshing" class="relative flex flex-row -mb-2">
                <span class="font-bold">{{ $t("key") }}</span>
                <SpreedlyCopyToClipboard
                  inline
                  id="workflow-token"
                  :token="workflow.key!"
                ></SpreedlyCopyToClipboard>
              </div>
              <Skeleton v-else width="15rem"></Skeleton>
            </div>
            <div class="col-span-1 mr-4">
              <div
                v-if="!isRefreshing"
                class="flex flex-row mb-2 whitespace-nowrap flex-wrap"
              >
                <span class="font-bold">{{ $t("created_at") }}</span>
                <span class="font-normal ml-2">
                  {{ formatDate(workflow.inserted_at!) }}</span
                >
              </div>
              <Skeleton v-else width="15rem"></Skeleton>
            </div>
            <div class="col-span-1 mr-4">
              <div
                class="flex flex-row whitespace-nowrap flex-wrap"
                v-if="!isRefreshing"
              >
                <span class="font-bold">{{ $t("last_modified") }}</span>
                <span class="font-normal ml-2">
                  {{ formatDate(workflow.updated_at!) }}</span
                >
              </div>
              <Skeleton v-else width="15rem" height="2rem"></Skeleton>
            </div>
            <div class="col-span-1">
              <div class="flex flex-row flex-wrap" v-if="!isRefreshing">
                <div
                  class="rounded-md shadow-md p-1 mr-2 hover:cursor-pointer border"
                  v-for="parent in getGatewayParentCompanies(workflow.steps)"
                  v-tooltip.bottom="gatewayTypeTooltip(parent, workflow.steps)"
                >
                  <img
                    :src="getPartnerImageSrc(`${parent}_small`)"
                    :alt="`${parent} logo`"
                    class="h-6 object-contain flex-shrink-0"
                  />
                </div>
              </div>
              <Skeleton v-else width="15rem" height="2rem"></Skeleton>
            </div>
            <div class="col-span-1">
              <div class="flex flex-row flex-wrap" v-if="!isRefreshing">
                <div
                  v-if="workflow.sandbox"
                  class="mt-1 border border-success-green bg-success-green-light text-spreedly-gray-600 rounded-xl px-2 mr-2 mb-1 md:mb-0"
                >
                  {{ $t("sandbox") }}
                </div>
                <div
                  v-for="feature in getWorkflowFeatures(
                    workflow.steps,
                    store.currentOrganization.payment_method_management_enabled
                  )"
                  v-tooltip.bottom="displayTooltip(feature, workflow)"
                  class="mt-1 bg-spreedly-blue-200 border-spreedly-blue-600 border text-spreedly-gray-600 rounded-xl px-2 mr-2 mb-1 md:mb-0"
                  :class="{
                    '!border-warning-orange !bg-warning-orange-light':
                      displayWarningTag(feature, workflow),
                  }"
                >
                  <i
                    class="pi pi-exclamation-triangle text-warning-orange"
                    v-if="displayWarningTag(feature, workflow)"
                  ></i>
                  {{ feature }}
                </div>
              </div>
              <Skeleton v-else width="15rem" height="2rem"></Skeleton>
            </div>
          </div>

          <div class="flex flex-row w-fit">
            <div class="flex flex-col w-[170px] justify-between">
              <div
                class="whitespace-nowrap text-end flex-1"
                v-if="!isRefreshing"
              >
                <div
                  v-if="
                    workflow.key ===
                    store.currentEnvironment.default_workflow_key
                  "
                  class="text-base border-spreedly-teal border text-spreedly-gray-600 bg-spreedly-teal-light rounded-xl px-2 whitespace-nowrap text-center font-bold"
                >
                  {{ $t("workflows.default") }}
                </div>
                <button
                  type="button"
                  v-else-if="
                    workflow.key !==
                      store.currentEnvironment.default_workflow_key &&
                    store.hasPermission('environment.update_default_workflow')
                  "
                  class="!text-end text-spreedly-blue-600 hover:text-spreedly-blue-700 ml-4 mr-2 workflow-make-default-button"
                  @click="confirmSetDefaultWorkflow(workflow.key!)"
                >
                  {{ $t("workflows.makeDefault") }}
                </button>
                <div
                  v-else-if="
                    workflow.key !==
                      store.currentEnvironment.default_workflow_key &&
                    !store.hasPermission('environment.update_default_workflow')
                  "
                  class="w-[150px]"
                >
                  &nbsp;
                </div>
              </div>
              <div v-else>
                <Skeleton width="10rem" height="2rem"></Skeleton>
              </div>

              <div class="text-end">
                <Button
                  :title="$t('viewDetails')"
                  icon="pi pi-cog pi-text-override"
                  type="button"
                  :id="`workflow-settings-button-${workflow.key}`"
                  :disabled="isRefreshing"
                  class="workflow-settings-button p-button-text p-button-rounded !text-spreedly-gray-600 !shadow-none !hover:text-spreedly-gray-700 hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
                  @click="
                    router.push({
                      name: 'WorkflowSettings',
                      params: { id: workflow.key },
                    })
                  "
                >
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Paginator
        v-if="workflows.length > 5"
        :totalRecords="workflows.length"
        :rows="numRows"
        :rowsPerPageOptions="[5, 10, 15, 20]"
        template="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        v-model:first="currentPage"
        @update:first="(newPage) => (currentPage = newPage)"
        @update:rows="(newRows) => (numRows = newRows)"
        :pt="{
          root: {
            class: 'mt-4 bg-transparent py-0 text-spreedly-gray-600',
          },
          rowPerPageDropdown: {
            root: {
              class:
                'flex !ml-4 !mb-0 border border-spreedly-gray-400 ml-4 items-center !w-[5.2rem]',
            },
          },
        }"
      ></Paginator>
    </div>
    <SpreedlyLoadingSpinner v-if="isLoading"></SpreedlyLoadingSpinner>
  </div>
</template>
<script setup lang="ts">
import Message from "primevue/message";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";
import Skeleton from "primevue/skeleton";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";
import Paginator from "primevue/paginator";

import { useSettingsStore } from "@/stores/SettingsStore";
import router from "@/router";
import {
  getWorkflowFeatures,
  getGatewayParentCompanies,
  listWorkflows,
  fetchConditionTypes,
} from "@/services/WorkflowService";
import type {
  WorkflowConclusion,
  Workflow,
  WorkflowStep,
} from "@/services/WorkflowService";
import { deepCopy, formatDate, sentenceCase } from "@/services/HelperService";
import { computed, onMounted, ref, watch } from "vue";
import i18n from "@/i18n";
import { useConfirm } from "primevue/useconfirm";
import type { GatewayOption } from "@/services/GatewayService";
import { getPartnerImageSrc } from "@/services/GatewayService";
import { updateEnvironmentDefaultWorkflow } from "@/services/EnvironmentService";

const confirm = useConfirm();
const store = useSettingsStore();
const workflows = ref<Workflow[]>([]);
const isLoading = ref(false);
const isRefreshing = ref(false);
const numRows = ref(5);
const currentPage = ref(0);
const binDimensions = ref<string[]>([]);
const docsUrl = import.meta.env.VITE_DOCS_URL;

const paginatedWorkflows = computed(() => {
  if (workflows.value.length > 5) {
    const start = currentPage.value > 0 ? currentPage.value : 0;
    const end = start + numRows.value;
    return workflows.value.slice(start, end);
  }
  return workflows.value;
});

onMounted(() => {
  fetchWorkflows();
  setBinDimensions();
});

async function fetchWorkflows() {
  isLoading.value = true;
  try {
    sortWorkflows(
      await listWorkflows(
        store.currentOrganization.key,
        store.currentEnvironment.key as string
      )
    );
  } catch (err) {
    workflows.value = [];
  } finally {
    isLoading.value = false;
  }
}

async function setBinDimensions() {
  try {
    binDimensions.value = (
      await fetchConditionTypes(
        store.currentOrganization.key,
        store.currentEnvironment.key!
      )
    )
      .filter((c) => c.av_required)
      .map((c) => c.name);
  } catch (err) {
    binDimensions.value = [];
  }
}

function sortWorkflows(wf: Workflow[]) {
  if (wf.length > 0) {
    wf.sort(
      (a: Workflow, b: Workflow) =>
        new Date(b.updated_at!).getTime() - new Date(a.updated_at!).getTime()
    );

    const defaultWorkflow =
      wf.find((w) => w.key === store.currentEnvironment.default_workflow_key) ||
      ({} as Workflow);
    wf.unshift(wf.splice(wf.indexOf(defaultWorkflow), 1)[0]);
  }
  workflows.value = wf;
}

async function setWorkflowDefault(workflowKey: string) {
  isRefreshing.value = true;
  try {
    const env = deepCopy(store.currentEnvironment);
    env.default_workflow_key = workflowKey;
    await updateEnvironmentDefaultWorkflow(store.currentOrganization.key, env);
    await store.fillEnvironments(true);
    sortWorkflows(workflows.value);
  } finally {
    isRefreshing.value = false;
  }
}

function displayTooltip(feature: string, workflow: Workflow) {
  let tooltipText = "";
  if (displayWarningTag(feature, workflow)) {
    if (feature === i18n.global.t("recover.recover")) {
      tooltipText = i18n.global.t("recover.notAllowedTooltip");
    } else if (feature === i18n.global.t("workflows.conditions")) {
      tooltipText = i18n.global.t("dimensions.enableAdvancedVault", {
        organizationSettings: i18n.global.t("org.settings"),
      });
    }
  }

  return tooltipText;
}
function displayWarningTag(feature: string, workflow: Workflow) {
  return (
    (feature === i18n.global.t("recover.recover") &&
      !store.currentOrganization.allow_recover &&
      !workflow.sandbox) ||
    (feature === i18n.global.t("workflows.conditions") &&
      hasInvalidBinDimensions(workflow.steps))
  );
}

const gatewayTypeTooltip = (parent: string, steps: WorkflowStep[]) => {
  let gatewayTypes: string[] = [];
  steps.forEach((step: WorkflowStep) => {
    step.conclusions?.forEach((conclusion: WorkflowConclusion) => {
      //check result first
      if (conclusion.result.parent_company_id === parent) {
        gatewayTypes.push(conclusion.result.gateway_type);
      }

      //check recover
      if (conclusion.result.params?.recover?.results) {
        conclusion.result.params.recover.results.forEach((result) => {
          if (result.parent_company_id === parent) {
            gatewayTypes.push(result.gateway_type);
          }
        });
      }
    });
  });

  gatewayTypes = Array.from(new Set(gatewayTypes));
  gatewayTypes = gatewayTypes.map((type) => getGatewayName(type));

  if (gatewayTypes.length === 1) {
    return gatewayTypes[0];
  } else if (gatewayTypes.length === 2) {
    return `${gatewayTypes[0]} ${i18n.global.t("and")} ${gatewayTypes[1]}`;
  } else {
    let str = "";
    gatewayTypes.forEach((type, index) => {
      if (index + 1 === gatewayTypes.length) {
        str += ` ${i18n.global.t("and")} ${type}`;
      } else {
        str += `${type}, `;
      }
    });
    return str;
  }
};

function getGatewayName(gatewayType: string) {
  return (
    store.gatewayOptions.find(
      (option: GatewayOption) => option.gateway_type === gatewayType
    )?.name || ""
  );
}
const hasInvalidBinDimensions = (steps: WorkflowStep[]) => {
  const dimensions = Array.from(
    new Set(
      steps
        .flatMap((s) => s.condition_set?.conditions)
        .filter((s) => s)
        .map((c) => c?.condition_type)
    )
  );
  return (
    dimensions.some((item) => binDimensions.value.includes(item!)) &&
    !store.currentOrganization.payment_method_management_enabled
  );
};
const confirmSetDefaultWorkflow = (workflowKey: string) => {
  confirm.require({
    group: "default-workflow-warning",
    message: i18n.global.t("confirmations.defaultWorkflow.message"),
    header: i18n.global.t("confirmations.defaultWorkflow.header"),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("workflows.setDefaultWorkflow"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: () => setWorkflowDefault(workflowKey),
  });
};

watch(
  () => store.currentEnvironment.key,
  () => {
    fetchWorkflows();
  }
);
</script>
