<template>
  <div>
    <SpreedlyPageTitle
      :title="
        isMarketplaceGateway
          ? $t('gateway_.createGatewayType', {
              gatewayType: selectedGateway?.name,
            })
          : $t('gateway_.create')
      "
      breadcrumbs
    >
      <template v-slot:breadcrumbs>
        <Button
          v-if="isMarketplaceGateway"
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700"
          icon="pi pi-arrow-left"
          icon-pos="left"
          text
          id="partner-gateway-list"
          type="button"
          :label="
            $t('gatewayTypeConnections', { gatewayType: selectedGateway?.name })
          "
          @click="
            router.push({
              name: 'PartnerGatewayList',
              params: { id: props.gatewayType },
            })
          "
        ></Button>
        <Button
          v-else
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700"
          icon="pi pi-arrow-left"
          icon-pos="left"
          text
          type="button"
          id="gateway-list"
          :label="$t('gateway_.allGatewayConnections')"
          @click="router.push({ name: 'GatewayList' })"
        ></Button>
      </template>
    </SpreedlyPageTitle>
    <OverlayPanel
      id="enable-network-token-tooltip-panel"
      ref="op"
      :pt="{
        root: 'bg-spreedly-gray-700 bg-opacity-95 text-white font-sans text-sm',
      }"
    >
      <div class="flex flex-col w-[350px]">
        <div class="pb-4">
          {{ $t("gateway_.networkTokenDoc") }}
        </div>
        <a
          :href="`${docsUrl}/docs/network-tokenization#enabling-network-tokenization-at-gateways`"
          target="_blank"
          class="text-spreedly-blue-lighter underline"
          >{{ $t("gateway_.seeNetworkTokenDocs") }}</a
        >
      </div>
    </OverlayPanel>
    <SpreedlyContainer>
      <Message
        v-if="displayErrorMessage"
        class="-mx-8 -mt-8 w-calc(100% + 4rem) justify-start"
        severity="error"
        :closable="false"
        >{{ displayErrorMessage }}
      </Message>
      <div class="flex justify-end">
        <SpreedlyButton
          id="view-payment-capabilities"
          :text="$t('viewCapabilities')"
          inverse
          @click="togglePaymentCapabilities = true"
        ></SpreedlyButton>
      </div>
      <div>
        <form
          class="text-sm text-left mb-4"
          @submit.prevent="save(v$.$invalid)"
        >
          <div class="field" v-if="!isMarketplaceGateway">
            <div class="flex">
              <label
                class="font-bold text-sm mr-8"
                for="gateway-name-select"
                :class="{ 'p-error': v$.gateway.$invalid && submitted }"
                >{{ `${$t("gateway_.gatewayName")}` }}</label
              >
              <div v-if="v$.gateway.$model['gateway_type']">
                <a
                  id="view-gateway-guide-docs-link"
                  class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700"
                  :href="`https://docs.spreedly.com/payment-gateways/${v$.gateway.$model[
                    'gateway_type'
                  ].replaceAll('_', '-')}/`"
                  target="_blank"
                >
                  <span class="flex-1">{{ $t("gateway_.viewDocs") }}</span>
                  <i class="pi pi-external-link text-xs ml-2"></i
                ></a>
              </div>
            </div>

            <div class="mt-2 w-full lg:w-2/3 2xl:w-1/3">
              <Dropdown
                v-model="v$.gateway.$model"
                filter
                id="gateway-name-select"
                @change="updateVuelidateModel(v$.gateway.$model)"
                showClear
                :options="gatewayOptions"
                optionLabel="name"
                :placeholder="$t('gateway_.select')"
              />
            </div>
            <div v-if="v$.gateway.$invalid && submitted" class="-mt-4 mb-4">
              <small class="p-error">{{ v$.gateway.required.$message }}</small>
            </div>
          </div>
          <div class="field" v-if="v$.gateway.$model['gateway_type']">
            <label class="font-bold text-sm" for="description-input">{{
              `${$t("gateway_.description")}`
            }}</label>
            <div class="mt-2 w-full lg:w-2/3 2xl:w-1/3 pb-4">
              <InputText
                :placeholder="$t('gateway_.descriptionPlaceholder')"
                id="description-input"
                v-model.trim="v$.description.$model"
                type="text"
                :class="{ 'p-invalid': v$.description.$error && submitted }"
                aria-describedby="description-error"
              />
            </div>
            <div
              v-if="v$.description.$errors.length && submitted"
              class="-mt-4 mb-4"
            >
              <small
                v-for="error in v$.description.$errors"
                :key="error.$uid"
                class="p-error"
                >{{ error.$message }}</small
              >
            </div>
          </div>
          <div
            v-if="
              v$.gateway.$model['gateway_settings'] &&
              v$.settings.$model &&
              Object.keys(v$.settings.$model).length > 0
            "
          >
            <div
              class="field"
              v-for="(setting, index) in Object.keys(v$.settings.$model)"
              :key="index"
            >
              <div v-if="fieldIsBoolean(setting)" class="flex mb-2">
                <div class="flex items-center h-6">
                  <Checkbox
                    v-model="v$.settings[`${setting}`].$model"
                    :input-id="`${setting}-checkbox`"
                    variant="outlined"
                    binary
                    :aria-describedby="`${setting}-checkbox`"
                  />
                </div>
                <div class="ml-4 flex flex-row">
                  <label :for="`${setting}-checkbox`" class="font-bold text-sm">
                    {{ sentenceCase(setting) }}
                  </label>
                  <Button
                    v-if="setting === 'enabled_network_tokens'"
                    severity="secondary"
                    text
                    id="network-token-info"
                    rounded
                    :aria-label="`${setting}-info`"
                    type="button"
                    class="-mt-2 ml-2 p-button-text p-button-rounded !text-spreedly-blue-700 !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
                    icon="pi pi-info-circle"
                    @click="togglePopup"
                    @keyup.enter="togglePopup"
                  >
                  </Button>
                </div>
              </div>
              <div v-else>
                <label
                  class="font-bold text-sm"
                  :class="{
                    'p-error': v$.settings[`${setting}`].$invalid && submitted,
                  }"
                  :for="`${setting}-input`"
                  >{{ sentenceCase(setting) }}</label
                >
                <div class="mt-2 w-full lg:w-2/3 2xl:w-1/3 pb-4">
                  <InputText
                    v-model.trim="v$.settings[`${setting}`].$model"
                    :id="`${setting}-input`"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="field"
            v-if="
              v$.gateway.$model &&
              v$.gateway.$model['gateway_type'] !== 'test' &&
              v$.gateway.$model['auth_modes'] &&
              v$.gateway.$model['auth_modes'].length > 1
            "
          >
            <label
              for="auth-mode-select"
              class="font-bold text-sm"
              :class="{
                'p-error':
                  v$.auth_mode.$invalid &&
                  (submitted || (isInStripeConnectMode && !stripeIntegration)),
              }"
            >
              {{ $t("gateway_.authMode") }}
            </label>
            <div class="mt-2 w-full lg:w-2/3 2xl:w-1/3">
              <Dropdown
                v-model="v$.auth_mode.$model"
                filter
                id="auth-mode-select"
                @change="updateCredentials(v$.auth_mode.$model)"
                :showClear="
                  !!(
                    v$.auth_mode.$model && v$.auth_mode.$model['auth_mode_type']
                  )
                "
                :options="v$.gateway.$model['auth_modes']"
                optionLabel="name"
                :placeholder="$t('gateway_.selectAuthMode')"
              />
            </div>
            <div
              v-if="
                v$.auth_mode.$invalid &&
                (submitted || (isInStripeConnectMode && !stripeIntegration))
              "
              class="-mt-4 mb-4"
            >
              <span
                class="p-error pr-2"
                v-if="v$.auth_mode.required.$invalid"
                >{{ v$.auth_mode.required.$message }}</span
              >
              <div v-if="v$.auth_mode.stripeIntegrationRequired">
                <i18n-t
                  class="p-error"
                  v-if="v$.auth_mode.stripeIntegrationRequired.$invalid"
                  scope="global"
                  :keypath="'stripePaymentIntents.noStripeIntegration'"
                  tag="p"
                >
                  <template v-slot:envSettings>
                    <router-link
                      class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                      :to="{
                        name: 'EnvironmentSettings',
                        params: { id: currentEnvironment.key },
                      }"
                      >{{ $t("env.settings").toLowerCase() }}
                    </router-link>
                  </template>
                </i18n-t>
              </div>
            </div>
          </div>

          <div v-if="selectedAuthMode">
            <div
              class="field"
              v-for="(cred, index) in selectedAuthMode.credentials"
              :key="index"
            >
              <label
                class="font-bold text-sm"
                :class="{
                  'p-error':
                    (v$[`${cred.name}`].$invalid && submitted) ||
                    v$[`${cred.name}`].invalidInput,
                }"
                :for="`${cred.name}-input`"
                >{{ sentenceCase(cred.name) }}</label
              >
              <div
                v-if="
                  (state.gateway as GatewayOption).gateway_type.includes('stripe') &&
                  cred.name === 'login'
                "
              >
                <div class="flex items-center flex-wrap">
                  <div class="w-full lg:w-2/3 2xl:w-1/3 mt-2 pb-2">
                    <InputText
                      v-model.trim="v$[`${cred.name}`].$model"
                      :id="`${cred.name}-input`"
                      :type="cred.safe ? 'text' : 'password'"
                    />
                  </div>
                  <div>
                    <a
                      class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer mb-1.5 ml-2"
                      target="_blank"
                      id="stripe-marketplace-external-link"
                      :href="StripeMarketplaceUrl"
                      >{{ $t("stripePaymentIntents.marketplace") }}
                      <i class="pi pi-external-link ml-1.5 text-[0.75rem]"></i>
                    </a>
                  </div>
                </div>
                <p class="text-sm font-normal text-spreedly-gray-500 pb-4 ml-2">
                  {{ $t("stripePaymentIntents.goToApp") }}
                </p>
              </div>
              <div class="mt-2 w-full lg:w-2/3 2xl:w-1/3 pb-4" v-else>
                <InputText
                  v-model.trim="v$[`${cred.name}`].$model"
                  :id="`${cred.name}-input`"
                  :type="cred.safe ? 'text' : 'password'"
                />

                <div
                  v-if="v$[`${cred.name}`].$invalid && submitted"
                  class="mt-0"
                >
                  <small class="p-error">{{
                    v$[`${cred.name}`].required.$message
                  }}</small>
                </div>
                <div v-if="v$[`${cred.name}`].invalidInput">
                  <small class="p-error">{{
                    v$[`${cred.name}`].invalidInput.$message
                  }}</small>
                </div>
              </div>
            </div>
          </div>

          <div class="field" v-if="shouldShowSandboxControl()">
            <div class="flex mb-2">
              <div class="flex items-center h-6">
                <Checkbox
                  v-model="v$.sandbox.$model"
                  :disabled="isInStripeConnectMode"
                  variant="outlined"
                  binary
                  input-id="sandbox-checkbox"
                  aria-describedby="sandbox-checkbox"
                />
              </div>
              <div class="ml-4">
                <label for="sandbox-checkbox" class="font-bold text-sm">
                  {{ $t("sandbox") }}
                </label>
                <i18n-t
                  scope="global"
                  :keypath="'gateway_.infoMessage.sandbox'"
                  tag="p"
                  class="text-sm font-normal text-spreedly-gray-600"
                >
                  <template v-slot:link>
                    <a
                      class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                      target="_blank"
                      id="sandbox-mode-docs-link"
                      :href="`${docsUrl}/docs/testing#gateway-sandboxes`"
                      >{{ $t("gateway_.infoMessage.sandboxMode") }}</a
                    >
                  </template>
                </i18n-t>
                <i18n-t
                  scope="global"
                  :keypath="'stripePaymentIntents.sandbox'"
                  tag="p"
                  v-if="isInStripeConnectMode"
                  class="text-sm font-normal text-spreedly-gray-600 mt-2"
                >
                  <template v-slot:envSettings>
                    <router-link
                      class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                      :to="{
                        name: 'EnvironmentSettings',
                        params: { id: currentEnvironment.key },
                      }"
                      >{{ $t("env.settings").toLowerCase() }}
                    </router-link>
                  </template>
                </i18n-t>
              </div>
            </div>
          </div>
        </form>
        <div class="flex flex-shrink-0 flex-col justify-start mb-4">
          <i18n-t
            scope="global"
            :keypath="'gateway_.infoMessage.moreInfo'"
            tag="p"
            class="text-sm font-normal text-spreedly-gray-600"
          >
            <template v-slot:link>
              <a
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                target="_blank"
                :href="`${docsUrl}/docs/create-gateways-and-receivers`"
                >{{ $t("learnMore") }}</a
              >
            </template>
          </i18n-t>
          <i18n-t
            scope="global"
            :keypath="'gateway_.infoMessage.collectingPaymentInfoText'"
            tag="p"
            class="text-sm font-normal text-spreedly-gray-600"
          >
            <template v-slot:link>
              <a
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                target="_blank"
                :href="`${docsUrl}/docs/collect-payment-information`"
                >{{ $t("gateway_.infoMessage.collectingPaymentInfo") }}</a
              >
            </template>
          </i18n-t>
        </div>

        <div class="flex flex-shrink-0 flex-wrap items-center justify-start">
          <SpreedlyButton
            class="mr-4"
            :text="$t('cancel')"
            id="cancel-create-gateway-button"
            :inverse="true"
            :disabled="!v$.$anyDirty || formState === 'saving'"
            @click="resetForm()"
          ></SpreedlyButton>
          <SpreedlyButton
            :disabled="
              !v$.$anyDirty || (isInStripeConnectMode && !stripeIntegration)
            "
            class="!mr-0"
            id="create-gateway-button"
            :icon="{ position: 'left', state: formState }"
            :text="$t(formState)"
            @click="save(v$.$invalid)"
          ></SpreedlyButton>
        </div>
      </div>
    </SpreedlyContainer>
    <PaymentCapabilitiesDrawer
      :composer="false"
      :gateway-type="gatewayType_"
      v-model:toggle-payment-capabilities="togglePaymentCapabilities"
    ></PaymentCapabilitiesDrawer>
  </div>
</template>
<script setup lang="ts">
import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import Message from "primevue/message";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import { useVuelidate } from "@vuelidate/core";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import router from "@/router";
import i18n from "@/i18n";
import { utf8Validator } from "@/i18n/i18n-validators";
import type {
  AuthMode,
  GatewayOption,
  GatewayPayload,
  GatewayError,
  Setting,
} from "@/services/GatewayService";
import { createEnvironmentGateway } from "@/services/GatewayService";
import { required } from "@/i18n/i18n-validators";
import { deepCopy, sentenceCase } from "@/services/HelperService";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import { helpers } from "@vuelidate/validators";
import type { AxiosError } from "axios";
import Button from "primevue/button";
import PaymentCapabilitiesDrawer from "@/components/PaymentCapabilitiesDrawer.vue";
import OverlayPanel from "primevue/overlaypanel";

const displayErrorMessage = ref<string | null>(null);
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const docsUrl = import.meta.env.VITE_DOCS_URL;
const store = useSettingsStore();
const { currentOrganization, currentEnvironment, gatewayOptions } =
  storeToRefs(store);
const selectedAuthMode = ref<AuthMode>();
const StripeMarketplaceUrl =
  "https://marketplace.stripe.com/apps/install/link/com.app.spreedly";

const props = defineProps<{
  gatewayType?: string;
}>();
const togglePaymentCapabilities = ref(false);
const op = ref();
const togglePopup = (event) => {
  op.value.toggle(event);
};

const isMarketplaceGateway = !!props.gatewayType;
const selectedGateway = computed(
  () =>
    store.gatewayOptions.find((o) => o.gateway_type === props.gatewayType) ||
    null
);

const state = reactive<Record<string, unknown>>({
  gateway: {} as GatewayOption,
  description: "",
  sandbox: false,
});

const rules = reactive<Record<string, unknown>>({
  gateway: { required },
  description: { utf8Validator },
  sandbox: {},
});

const isInStripeConnectMode = computed(() => {
  return !!(
    (state.gateway as GatewayOption).gateway_type ===
      "stripe_payment_intents" &&
    state.auth_mode &&
    (state.auth_mode as AuthMode).auth_mode_type === "connect"
  );
});
const stripeIntegration = computed(() => {
  return (
    currentEnvironment.value.test_stripe_user_id ||
    currentEnvironment.value.stripe_user_id
  );
});

const submitted = ref(false);
const v$ = useVuelidate(rules, state);

const gatewayType_ = computed(() => {
  if (props.gatewayType) return props.gatewayType;
  if ((state.gateway as GatewayOption).gateway_type)
    return (state.gateway as GatewayOption).gateway_type;
});

onMounted(() => {
  if (isMarketplaceGateway && selectedGateway.value) {
    state.gateway = selectedGateway.value;
    updateVuelidateModel(selectedGateway.value);
  }
});

onBeforeRouteLeave((to, from) => {
  if (v$.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

function resetState(preserveAuthMode?: boolean) {
  const validKeys = ["description", "gateway", "sandbox", "settings"];
  if (preserveAuthMode) validKeys.push("auth_mode");

  for (const [key] of Object.entries(state)) {
    if (!validKeys.includes(key)) {
      delete state[key];
    }
  }
}

function resetRules(preserveAuthMode?: boolean) {
  const validKeys = ["description", "gateway", "sandbox", "settings"];

  if (preserveAuthMode) {
    validKeys.push("auth_mode");
  }

  for (const [key] of Object.entries(rules)) {
    if (!validKeys.includes(key)) {
      delete rules[key];
    }
  }
}

function updateCredentials(authMode: AuthMode) {
  resetState(true);
  resetRules(true);
  selectedAuthMode.value = authMode;
  setStripePIConnectSpecificAttributes();
  setCredentials();
}

function setStripePIConnectSpecificAttributes() {
  state.sandbox = !!(
    (state.gateway as GatewayOption).gateway_type ===
      "stripe_payment_intents" &&
    selectedAuthMode.value?.auth_mode_type === "connect" &&
    currentEnvironment.value.test_stripe_user_id
  );

  if (isInStripeConnectMode.value) {
    rules.auth_mode = {
      required,
      stripeIntegrationRequired: helpers.withMessage(
        i18n.global.t("stripePaymentIntents.noStripeIntegration", {
          envSettings: i18n.global.t("env.settings").toLowerCase(),
        }),
        stripeIntegrationRequired
      ),
    };
  } else {
    rules.auth_mode = { required };
  }
}

const stripeIntegrationRequired = () => {
  return (
    currentEnvironment.value.test_stripe_user_id ||
    currentEnvironment.value.stripe_user_id
  );
};

function setCredentials() {
  if (selectedAuthMode.value) {
    for (const credential in selectedAuthMode.value.credentials) {
      const fieldName = selectedAuthMode.value.credentials[credential].name;
      state[fieldName] = "";
      rules[fieldName] = {};
    }
  }
}

function updateVuelidateModel(gateway: GatewayOption) {
  resetState();
  resetRules();
  selectedAuthMode.value = undefined;

  if (gateway && gateway.gateway_type && gateway.auth_modes) {
    if (gateway.auth_modes.length > 1) {
      state.auth_mode = {};
      rules.auth_mode = { required };
    } else {
      selectedAuthMode.value = gateway.auth_modes[0];
      setCredentials();
    }

    state.settings = {};
    rules.settings = {};

    gateway.gateway_settings.forEach((setting) => {
      state.settings[setting.name] = setting.is_boolean ? false : null;
      rules.settings[setting.name] = {};
    });
  } else if (!gateway) {
    state.gateway = {};
  }

  submitted.value = false;
}

watch(
  () => v$.value.$anyDirty,
  () => {
    if (v$.value.$anyDirty) {
      formState.value = "saveChanges";
    }
  }
);

async function save(isFormInvalid: boolean) {
  if (!v$.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = null;
  if (!store.hasPermission("environment.create_gateway")) {
    displayErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }
  submitted.value = true;
  if (isFormInvalid) {
    return;
  }
  formState.value = "saving";
  const payload = formatPayload();
  try {
    const response = await createEnvironmentGateway(
      currentOrganization.value.key,
      currentEnvironment.value.key as string,
      payload
    );
    if (response) {
      await store.fillGateways(true);
      await store.fillGatewayOptions(true);
      formState.value = "saved";
      v$.value.$reset();
      if (isMarketplaceGateway) {
        await router.push({
          name: "PartnerGatewayDetailSummary",
          params: {
            id: response.key,
            gatewayType: response.gateway_type,
          },
          state: { successMessage: "true" },
        });
      } else {
        await router.push({
          name: "GatewayDetailSummary",
          params: {
            id: response.key,
          },
          state: { successMessage: "true" },
        });
      }
    }
  } catch (error) {
    handleErrors(error as AxiosError);
  }
}

function handleErrors(error: AxiosError) {
  const errorList = (error as AxiosError).response?.data?.errors;
  if (errorList) {
    const errorMessageArray = [] as string[];
    errorList.forEach((err: GatewayError) => {
      switch (err.key) {
        case "errors.account_inactive":
          errorMessageArray.push(err.message);
          break;
        case "errors.invalid":
          if (
            err.attribute &&
            selectedAuthMode.value?.credentials.find(
              (cred) => cred.name === err.attribute
            )
          ) {
            rules[err.attribute] = {
              invalidInput: helpers.withMessage(err.message, () => true),
            };
          }

          errorMessageArray.push(i18n.global.t("errorMessage.invalidFields"));
          break;
        case "errors.blank":
          if (
            err.attribute &&
            selectedAuthMode.value?.credentials.find(
              (cred) => cred.name === err.attribute
            )
          ) {
            rules[err.attribute] = { required };
          }

          errorMessageArray.push(i18n.global.t("errorMessage.missingFields"));
          break;
        default:
          break;
      }
    });

    formatErrorMessage([...new Set(errorMessageArray)]);
  } else {
    displayErrorMessage.value = i18n.global.t("errorMessage.generic");
  }
  formState.value = "saveChanges";
}

function formatErrorMessage(errorMessageArray: string[]) {
  if (errorMessageArray.length === 0) {
    displayErrorMessage.value = i18n.global.t("errorMessage.generic");
  } else {
    if (errorMessageArray.length === 1) {
      displayErrorMessage.value = errorMessageArray[0];
    } else {
      displayErrorMessage.value = "";
      errorMessageArray.forEach((error, index) => {
        if (index === errorMessageArray.length - 1) {
          displayErrorMessage.value!.concat(error);
        } else {
          displayErrorMessage.value!.concat(error).concat(", ");
        }
      });
    }
  }
}
function formatPayload(): GatewayPayload {
  const stateCopy = deepCopy(state);
  const type = stateCopy.gateway.gateway_type;
  const payload = {
    gateway_type: stateCopy.gateway.gateway_type,
    description: stateCopy.description,
    sandbox: stateCopy.sandbox,
    gateway_settings: stateCopy.settings,
    credentials: undefined,
    mode: undefined,
  };

  delete stateCopy.settings;
  delete stateCopy.description;
  delete stateCopy.sandbox;
  delete stateCopy.gateway;

  if (type !== "test") {
    payload.credentials = stateCopy;
    payload.mode = stateCopy.auth_mode?.auth_mode_type || undefined;
  }

  return payload;
}

function resetForm() {
  v$.value.$reset();
  if (isMarketplaceGateway) {
    updateVuelidateModel(selectedGateway.value!);
  } else {
    resetState();
    resetRules();
    selectedAuthMode.value = undefined;
    state.gateway = {};
  }
  state.description = "";
  state.sandbox = false;
  displayErrorMessage.value = null;
  submitted.value = false;
}

function shouldShowSandboxControl() {
  return (
    (state.gateway as GatewayOption).gateway_type &&
    selectedAuthMode.value &&
    ((isInStripeConnectMode.value && stripeIntegration.value) ||
      !isInStripeConnectMode.value)
  );
}

function fieldIsBoolean(fieldName: string): boolean {
  if (
    (state.gateway as GatewayOption)?.gateway_settings &&
    (state.gateway as GatewayOption)?.gateway_settings.length > 0
  ) {
    const setting = (state.gateway as GatewayOption)?.gateway_settings.find(
      (setting: Setting) => setting.name === fieldName
    );
    return setting?.is_boolean || false;
  }

  return false;
}
</script>
