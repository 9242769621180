<template>
  <div>
    <SpreedlyPageTitle :title="$t('activityLog.activityLog')">
    </SpreedlyPageTitle>

    <DataTable
      stateStorage="session"
      stateKey="activity-log-session"
      paginator
      filterDisplay="row"
      v-model:filters="filters"
      :rows="10"
      striped-rows
      :rowsPerPageOptions="[5, 10, 20, 50]"
      size="small"
      removable-sort
      column-resize-mode="fit"
      sortField="time"
      :sort-order="-1"
      :value="activityLogs"
      :pt="{
        root: { class: 'font-sans border !text-[14px]' },
        headerRow: { class: '!text-spreedly-gray-400 border-b' },
        bodyRow: { class: 'last:border-b-0 border-b' },
        header: { class: 'bg-[#F8F9FA]' },
        wrapper: { class: 'pb-2 bg-white' },
        paginator: {
          root: {
            class: 'font-sans ',
          },
          rowPerPageDropdown: {
            root: {
              class:
                'flex !ml-4 !mb-0 border border-spreedly-gray-400 ml-4 items-center !w-[5.2rem]',
            },
          },
        },
      }"
    >
      <template #empty>
        <span class="text-spreedly-gray-500 italic" v-if="!loadingLogs">{{
          $t("activityLog.noLogs")
        }}</span>
      </template>
      <template #header>
        <div class="text-left mt-2 ml-2" v-if="activityLogs?.length">
          <SpreedlyButton
            :icon="{ position: 'left', name: 'download' }"
            :inverse="false"
            :text="$t('downloadCsv')"
            @click="downloadCsvData()"
          ></SpreedlyButton>
        </div>
      </template>
      <Column
        v-for="col in headers"
        :field="col.field"
        :header="col.alias ? $t(col.alias) : $t(col.field)"
        :show-filter-menu="col.showFilterMenu"
        :sortable="col.sortable"
        :class="col.colClass"
        :pt="{
          sort: { class: 'text-spreedly-blue-600' },
          sortIcon: { class: 'text-spreedly-blue-600' },
          headerTitle: { class: '!text-spreedly-gray-600' },
          headerCell: { class: 'first:pl-4' },
          bodyCell: {
            class: '!text-spreedly-gray-600 first:pl-4',
          },
        }"
      >
        <template
          #filter="{ filterModel, filterCallback }"
          v-if="col.filterable"
        >
          <Dropdown
            v-if="col.filterType === 'dropdown'"
            v-model="filterModel.value"
            @change="filterCallback()"
            :options="filterOptions(col.field)"
            :pt="{ root: { class: '!mb-0' } }"
            class="p-column-filter"
            style="min-width: 12rem"
            :showClear="true"
          >
            <template #option="slotProps">
              <Tag
                v-if="col.type === 'Tag'"
                :value="slotProps.option"
                rounded
                :pt="{
                  root: {
                    class: getTagClass(slotProps.option),
                  },
                }"
              />
            </template>
          </Dropdown>
          <InputText
            v-else
            v-model="filterModel.value"
            type="text"
            @input="filterCallback()"
            class="p-column-filter self-center"
          />
        </template>

        <template #body="slotProps">
          <Skeleton v-if="loadingLogs" width="60%" height="1rem" />
          <div v-else>
            <div
              :id="col.field"
              v-tooltip="slotProps.data[col.field]"
              class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
              v-if="col.type === 'Link' && slotProps.data[col.field] && col.to"
            >
              <router-link
                :to="{
                  name: col.to,
                  params: { id: slotProps.data[col.field] },
                }"
              >
                {{
                  col.truncate
                    ? truncateDisplayValue(slotProps.data[col.field])
                    : slotProps.data[col.field]
                }}
              </router-link>
            </div>
            <Tag
              v-else-if="col.type === 'Tag'"
              class="mx-4 whitespace-nowrap"
              :value="slotProps.data[col.field]"
              rounded
              :pt="{
                root: {
                  class: getTagClass(slotProps.data[col.field]),
                },
              }"
            ></Tag>
            <div v-else-if="col.type === 'DateString'">
              {{ formatDate(slotProps.data[col.field]) }}
            </div>
            <div v-else-if="col.type === 'Drawer'">
              <Button
                class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700"
                v-if="
                  ['Workflow', 'Recover configuration'].includes(
                    slotProps.data.resource
                  )
                "
                @click="openVersionDetailsDrawer(slotProps.data, col.field)"
                >{{ slotProps.data[col.field] }}</Button
              >
              <span v-else>{{ slotProps.data[col.field] }}</span>
            </div>
            <div v-else>
              {{ slotProps.data[col.field] }}
            </div>
          </div>
        </template>
      </Column>
    </DataTable>
    <Sidebar
      v-model:visible="toggleDetailsDrawer"
      position="right"
      class="w-full lg:w-[620px]"
      :dismissable="true"
      :modal="false"
      :pt="{ root: { class: 'font-sans' } }"
    >
      <template #container="{ closeCallback }">
        <div
          class="flex flex-row w-full justify-between px-4 pt-3 text-spreedly-gray-600"
        >
          <span class="font-semibold text-2xl">
            {{
              drawerDetails.field === "previous_value"
                ? $t("activityLog.previousStateDetails")
                : $t("activityLog.newStateDetails")
            }}
          </span>
          <Button
            type="button"
            id="close-payment-capabilities-drawer-button"
            @click="closeCallback"
            icon="pi pi-times"
            class="p-button-text p-button-rounded !shadow-none hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
            rounded
          ></Button>
        </div>
        <div class="h-full">
          <div class="h-full flex flex-col px-4 pt-3">
            <div class="flex flex-col justify-start mb-4 text-lg">
              <h3 class="font-semibold">
                {{ $t("event") }}
              </h3>
              <p class="font-normal">{{ drawerDetails.log.action }}</p>
            </div>
            <div class="flex flex-col justify-start mb-4 text-lg">
              <h3 class="font-semibold">
                {{
                  $t("activityLog.drawerDetailKey", {
                    resource: drawerDetails.log.resource,
                  })
                }}
              </h3>
              <p class="font-normal">{{ drawerDetails.log.target }}</p>
            </div>
            <div class="flex flex-col h-full justify-start">
              <h2 class="font-normal text-xl">
                {{
                  $t("activityLog.drawerDetailVersion", {
                    version: drawerDetails.version,
                  })
                }}
              </h2>
              <SpreedlyPrettyJson
                class="text-base"
                v-if="drawerDetails.versionJSON"
                :raw-json="drawerDetails.versionJSON"
              ></SpreedlyPrettyJson>
            </div>
          </div>
        </div>
      </template>
    </Sidebar>
  </div>
</template>
<script setup lang="ts">
import exportFromJSON from "export-from-json";
import { storeToRefs } from "pinia";
import { watch, ref, onMounted } from "vue";
import {
  type ActivityLog,
  fetchActivityLogs,
} from "@/services/OrganizationService";
import { useSettingsStore } from "@/stores/SettingsStore";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import { formatDate, truncateDisplayValue } from "@/services/HelperService";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Skeleton from "primevue/skeleton";
import Tag from "primevue/tag";
import { FilterMatchMode } from "primevue/api";
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import { fetchWorkflowByVersion } from "@/services/WorkflowService";
import { fetchRecoverConfigurationByVersion } from "@/services/RecoverConfigurationsService";
import i18n from "@/i18n";
import SpreedlyPrettyJson from "@/components/SpreedlyPrettyJson.vue";

const store = useSettingsStore();
const { currentOrganization } = storeToRefs(store);
const activityLogs = ref(Array.from({ length: 10 }).map((_) => generateLog()));
const loadingLogs = ref(false);
const toggleDetailsDrawer = ref(false);

const drawerDetails = ref({});

const filters = ref({
  user_email: { value: null, matchMode: FilterMatchMode.CONTAINS },
  environment: { value: null, matchMode: FilterMatchMode.CONTAINS },
  domain: { value: null, matchMode: FilterMatchMode.EQUALS },
  resource: { value: null, matchMode: FilterMatchMode.EQUALS },
  target: { value: null, matchMode: FilterMatchMode.CONTAINS },
  action: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

watch(
  () => currentOrganization.value.key,
  () => {
    loadActivityLogs();
  }
);

onMounted(() => {
  loadActivityLogs();
});

function generateLog() {
  return {
    created_at: "",
    action: "",
    user_email: "",
    target: "",
    previous_value: "",
    new_value: "",
    environment: "",
    domain: "",
    resource: "",
  };
}

const filterOptions = (field: string) => {
  switch (field) {
    case "domain":
      return Array.from(new Set(activityLogs.value.map((log) => log.domain)));
    case "resource":
      return Array.from(new Set(activityLogs.value.map((log) => log.resource)));
    case "action":
      return Array.from(new Set(activityLogs.value.map((log) => log.action)));
    default:
      return [];
  }
};

const openVersionDetailsDrawer = async (
  log: ActivityLog,
  field: "previous_value" | "new_value"
) => {
  drawerDetails.value = {
    field: field,
    log: log,
    version: log[field],
    versionJSON: await getVersionJSON(log, field),
  };
  toggleDetailsDrawer.value = true;
};

async function getVersionJSON(
  log: ActivityLog,
  field: "previous_value" | "new_value"
) {
  try {
    switch (log.resource) {
      case "Workflow":
        return JSON.stringify(
          await fetchWorkflowByVersion(
            store.currentOrganization.key,
            store.currentEnvironment.key!,
            log.target,
            log[field]
          ),
          null,
          2
        );
      case "Recover configuration":
        return JSON.stringify(
          await fetchRecoverConfigurationByVersion(
            store.currentOrganization.key,
            store.currentEnvironment.key!,
            log.target,
            log[field]
          ),
          null,
          2
        );
      default:
        return "";
    }
  } catch (err) {
    return i18n.global.t("activityLog.errorLoadingVersionDetails", {
      resource: log.resource,
      id: log.target,
    });
  }
}

const headers = [
  {
    field: "created_at",
    type: "DateString",
    sortable: true,
    alias: "time",
    colClass: "min-w-[200px]",
  },
  {
    field: "user_email",
    type: "String",
    sortable: true,
    alias: "performedBy",
    showFilterMenu: true,
    filterable: true,
    colClass: "min-w-[200px]",
  },
  {
    field: "environment",
    sortable: true,
    truncate: true,
    alias: "env.environment",
    type: "Link",
    to: "EnvironmentSettings",
    showFilterMenu: true,
    filterable: true,
    colClass: "min-w-[200px]",
  },
  {
    field: "domain",
    type: "Tag",
    sortable: true,
    showFilterMenu: false,
    filterType: "dropdown",
    filterable: true,
    colClass: "min-w-[200px]",
  },
  {
    field: "resource",
    type: "String",
    sortable: true,
    showFilterMenu: false,
    filterType: "dropdown",
    filterable: true,
    colClass: "min-w-[200px]",
  },
  {
    field: "target",
    type: "String",
    sortable: true,
    showFilterMenu: true,
    truncate: true,
    filterable: true,
    colClass: "min-w-[200px]",
  },
  {
    field: "action",
    type: "String",
    sortable: true,
    alias: "event",
    showFilterMenu: false,
    filterType: "dropdown",
    filterable: true,
    colClass: "min-w-[200px]",
  },
  {
    field: "previous_value",
    type: "Drawer",
    sortable: true,
    alias: "previousState",
    colClass: "min-w-[200px]",
  },
  {
    field: "new_value",
    type: "Drawer",
    sortable: true,
    alias: "newState",
    colClass: "min-w-[200px]",
  },
];

const getTagClass = (state: string) => {
  switch (state.toLowerCase()) {
    case "composer":
      return "bg-[#2CD5C440] text-spreedly-gray-600";
    case "organization":
      return "bg-spreedly-blue-300 text-spreedly-gray-600";
    default:
      return "bg-spreedly-blue-300 text-spreedly-gray-600";
  }
};

async function loadActivityLogs() {
  loadingLogs.value = true;
  try {
    activityLogs.value = await fetchActivityLogs(currentOrganization.value.key);
  } catch (err) {
    activityLogs.value = [];
  } finally {
    loadingLogs.value = false;
  }
}

function downloadCsvData() {
  const todayDate = new Date().toISOString().split("T")[0];
  const data = activityLogs.value.map((log) => {
    return {
      time: log.created_at,
      performedBy: log.user_email,
      environment: log.environment,
      domain: log.domain,
      resource: log.resource,
      target: log.target,
      event: log.action,
      previousState: log.previous_value,
      newState: log.new_value,
    };
  });

  const fileName = `spreedly-activity-logs-${todayDate}`;
  const exportType = exportFromJSON.types.csv;
  const fields = {
    time: "Time",
    performedBy: "Performed By",
    environment: "Environment",
    domain: "Domain",
    resource: "Resource",
    target: "Target",
    event: "Event",
    previousState: "Previous State",
    newState: "New State",
  };

  if (data) exportFromJSON({ data, fileName, exportType, fields });
}
</script>
