import { defineStore } from "pinia";
import type { StoreDefinition } from "pinia";
import type { IdUserInfo, User } from "@/services/UserService";
import type {
  EnvironmentGateway,
  GatewayOption,
} from "@/services/GatewayService";
import { fetchUserInfo, fetchUsers } from "@/services/UserService";
import {
  type Environment,
  fetchEnvironments,
} from "@/services/EnvironmentService";
import {
  fetchOrganizations,
  type Organization,
} from "@/services/OrganizationService";
import { useLayout } from "@/composables/useLayout";
import {
  fetchEnvironmentGateways,
  fetchGatewayOptions,
} from "@/services/GatewayService";
import * as Sentry from "@sentry/vue";
import { deepCopy } from "@/services/HelperService";

const { isInternalError } = useLayout();

type State = {
  currentEnvironment: Environment;
  previousEnvironment: Environment | null;
  previousOrganization: Organization | null;
  currentOrganization: Organization;
  environments: Environment[];
  hasAccessToRoutingRules: boolean;
  hasAccessToGlobalSearch: boolean;
  hasAccessToMarketplace: boolean;
  hasAccessToComposer: boolean;
  hasAccessToIframeEnhancedSecurity: boolean;
  isLoadingEnvironments: boolean;
  organizationUsers: User[];
  organizations: Organization[];
  gatewayOptions: GatewayOption[];
  user: User;
  userPermissions: string[];
};

/*
 * These variables are used to track promises.
 * DefaultLayout and Router will call these methods at same time on initial page load/refresh,
 * to avoid double calls the first call will set a promise and the second call
 * will skip calling if the promise exists and wait on the promise to finish  before
 * proceeding
 */

let orgPromise: Promise<Organization[]> | null,
  userPromise: Promise<IdUserInfo> | null,
  envPromise: Promise<Environment[]> | null,
  orgUserPromise: Promise<User[]> | null,
  gatewaysPromise: Promise<EnvironmentGateway[]> | null,
  gatewayOptionsPromise: Promise<GatewayOption[]> | null;

function clearPromiseCache() {
  orgPromise = null;
  userPromise = null;
  envPromise = null;
  orgUserPromise = null;
  gatewaysPromise = null;
  gatewayOptionsPromise = null;
}

export const useSettingsStore: StoreDefinition<
  "settings",
  State,
  {
    hasOrganizationMembership(
      state: State
    ): (organizationKey: string) => boolean;
    hasPermission(state: State): (permission: string) => boolean;
    hasComposerEnabledGatewayConnections(state: State): () => boolean;
  },
  {
    fillOrganizations(clearPromises?: boolean): Promise<void>;
    fillEnvironments(clearPromises?: boolean): Promise<void>;
    changeCurrentOrganization(org: Organization): Promise<void>;
    fillCurrentOrganization(clearPromises?: boolean): Promise<void>;
    fillCurrentEnvironment(clearPromises?: boolean): Promise<void>;
    fillGateways(clearPromises?: boolean): Promise<void>;
    fillGatewayOptions(clearPromises?: boolean): Promise<void>;
    changeCurrentEnvironment(env: Environment): Promise<void>;
    fillUserInfo(clearPromises?: boolean): Promise<void>;
    fillOrganizationUsers(clearPromises?: boolean): Promise<void>;
    reconcileCurrentEnvironment(
      environmentKey: string,
      organizationKey: string
    ): Promise<void>;
  }
> = defineStore({
  id: "settings",
  state: (): State => {
    return {
      organizations: [],
      currentOrganization: {} as Organization,
      environments: [],
      currentEnvironment: {} as Environment,
      previousEnvironment: null,
      previousOrganization: null,
      userPermissions: [],
      user: {
        email: "",
        key: "",
        firstName: "",
        lastName: "",
        sso: false,
        roles: [],
        spreedlyAdmin: false,
      },
      hasAccessToRoutingRules: false,
      hasAccessToGlobalSearch: false,
      hasAccessToMarketplace: false,
      hasAccessToComposer: false,
      hasAccessToIframeEnhancedSecurity: false,
      organizationUsers: [],
      isLoadingEnvironments: false,
      gatewayOptions: [],
    };
  },
  getters: {
    hasOrganizationMembership: (state: State) => {
      return (organizationKey: string) =>
        state.organizations.map((o) => o.key).includes(organizationKey);
    },
    hasPermission: (state: State) => {
      return (permission: string) => state.userPermissions.includes(permission);
    },
    hasComposerEnabledGatewayConnections: (state: State) => {
      return (): boolean =>
        state.gatewayOptions.some((option: GatewayOption) => {
          return option.composer_enabled && option.connected;
        });
    }
  },
  actions: {
    async fillOrganizations(clearPromises?: boolean) {
      if (clearPromises) clearPromiseCache();

      if (!orgPromise) {
        orgPromise = fetchOrganizations().then((result) => {
          this.organizations = result;
          return result;
        });
      }
      try {
        await orgPromise;
      } catch (err) {
        Sentry.captureException(err);
        isInternalError.value = true;
      }

      if (this.organizations.length === 0) {
        isInternalError.value = true;
      } else {
        await this.fillCurrentOrganization(clearPromises);
      }
    },
    async fillEnvironments(clearPromises?: boolean) {
      if (clearPromises) clearPromiseCache();

      if (!envPromise) {
        this.isLoadingEnvironments = true;
        envPromise = fetchEnvironments(this.currentOrganization.key).then(
          (result) => {
            this.environments = result;
            return result;
          }
        );
      }
      await envPromise;

      if (this.environments.length === 0) {
        //No environment returned, display 500
        isInternalError.value = true;
      } else {
        await this.fillCurrentEnvironment();
      }
      this.isLoadingEnvironments = false;
    },
    async changeCurrentOrganization(org: Organization) {
      clearPromiseCache();

      if (org.key !== this.currentOrganization?.key) {
        this.previousEnvironment = deepCopy(this.currentEnvironment);
        this.previousOrganization = deepCopy(this.currentOrganization);
        await this.fillUserInfo(true);
        this.currentOrganization = org;

        if (this.currentOrganization.key) {
          localStorage.setItem(
            "currentOrganization",
            this.currentOrganization.key
          );
        }
        await this.fillEnvironments();
      }
    },
    async fillCurrentOrganization(clearPromises?: boolean) {
      if (clearPromises) clearPromiseCache();
      this.organizationUsers = [];
      const currentOrgKey = localStorage.getItem("currentOrganization");
      if (!currentOrgKey) {
        // No org key in local storage
        if (this.organizations.length > 0) {
          this.currentOrganization = this.organizations[0];
          await this.fillUserInfo();
          await this.fillEnvironments();

          if (this.currentOrganization.key) {
            localStorage.setItem(
              "currentOrganization",
              this.currentOrganization.key
            );
          }
        } else {
          // No Organization returned, show 500
          isInternalError.value = true;
        }
      } else {
        if (this.organizations.length > 0) {
          // org is set in local storage
          const currentOrg = this.organizations.find(
            (org) => org.key === currentOrgKey
          );
          if (currentOrg) {
            //local storage org is still valid
            this.currentOrganization = currentOrg;
            await this.fillUserInfo();
            await this.fillEnvironments();
          } else {
            // local storage org is stale
            localStorage.removeItem("currentOrganization");
            await this.fillCurrentOrganization();
          }
        } else {
          // No Organization returned, show 500
          isInternalError.value = true;
        }
      }
    },
    async fillCurrentEnvironment(clearPromises?: boolean) {
      if (clearPromises) clearPromiseCache();

      const currentEnvKey = localStorage.getItem("currentEnvironment");
      if (!currentEnvKey) {
        //No env key in local storage
        if (this.environments.length > 0) {
          this.currentEnvironment = this.environments[0];
          await this.fillGateways();
          await this.fillGatewayOptions();
          if (this.currentEnvironment.key) {
            localStorage.setItem(
              "currentEnvironment",
              this.currentEnvironment.key as string
            );
          }
        }
      } else {
        // env is set in local storage
        const currentEnv = this.environments.find(
          (env) => env.key === currentEnvKey
        );
        if (currentEnv) {
          // local storage is still valid
          this.currentEnvironment = currentEnv;
          await this.fillGateways();
          await this.fillGatewayOptions();
        } else {
          //local storage is stale
          localStorage.removeItem("currentEnvironment");
          await this.fillCurrentEnvironment();
        }
      }
    },
    async fillGateways(clearPromises?: boolean) {
      if (clearPromises) clearPromiseCache();

      if (!gatewaysPromise) {
        if (this.hasPermission("gateway.read")) {
          gatewaysPromise = fetchEnvironmentGateways(
            this.currentOrganization.key,
            this.currentEnvironment.key as string
          ).then((result) => {
            this.currentEnvironment.gateways = result;
            return result;
          });
        }
      }

      await gatewaysPromise;
    },
    async fillGatewayOptions(clearPromises?: boolean) {
      if (clearPromises) clearPromiseCache();

      if (!gatewayOptionsPromise) {
        if (this.hasPermission("gateway.read")) {
          gatewayOptionsPromise = fetchGatewayOptions(
            this.currentOrganization.key,
            this.currentEnvironment.key as string
          ).then((result) => {
            this.gatewayOptions = result;
            return result;
          });
        }
      }

      await gatewayOptionsPromise;
    },
    async changeCurrentEnvironment(env: Environment) {
      clearPromiseCache();

      // this scenario occurs when an org is changed and then the undo button is clicked
      // on the environment changed toast -- first the org needs to be changed back before
      // the env can be switched
      if (!this.environments.includes(env) && this.previousOrganization) {
        await this.changeCurrentOrganization(this.previousOrganization);
      }

      if (env.key !== this.currentEnvironment?.key) {
        this.previousEnvironment = deepCopy(this.currentEnvironment);
        this.previousOrganization = deepCopy(this.currentOrganization);

        // check if environment exists in current organization
        this.currentEnvironment = env;
        await this.fillGateways();
        await this.fillGatewayOptions();
        if (this.currentEnvironment.key) {
          localStorage.setItem(
            "currentEnvironment",
            this.currentEnvironment.key as string
          );
        }
      }
    },
    async fillUserInfo(clearPromises?: boolean) {
      if (clearPromises) clearPromiseCache();

      if (!userPromise) {
        userPromise = fetchUserInfo(this.currentOrganization.key).then(
          (result) => {
            if (!result.key) {
              isInternalError.value = true;
            }
            if (
              !result.spreedly_admin &&
              window.location.origin === "https://next.spreedly.com"
            ) {
              window.location.href = import.meta.env.VITE_PRODUCTION_URL;
            }
            this.user.email = result.email as string;
            this.user.key = result.key as string;
            this.user.firstName = result.first_name as string;
            this.user.lastName = result.last_name as string;
            this.user.roles = result.roles as string[];
            this.user.sso = result.sso as boolean;
            this.user.spreedlyAdmin = result.spreedly_admin as boolean;
            this.userPermissions = result.authorize_ops as string[];
            this.hasAccessToGlobalSearch =
              result.feature_flags.access_to_global_search;
            this.hasAccessToRoutingRules =
              result.feature_flags.access_to_routing_rules;
            this.hasAccessToMarketplace =
              result.feature_flags.spreedly_one_marketplace;
            this.hasAccessToComposer = result.feature_flags.access_to_composer;
            this.hasAccessToIframeEnhancedSecurity =
              result.feature_flags.access_to_iframe_enhanced_security;
            Sentry.setUser({ email: result.email });
            return result;
          }
        );
      }

      try {
        await userPromise;
      } catch (err) {
        Sentry.captureException(err);
        isInternalError.value = true;
      }
    },
    async fillOrganizationUsers(clearPromises?: boolean) {
      if (clearPromises) clearPromiseCache();

      if (!orgUserPromise && this.hasPermission("organization.add_user")) {
        orgUserPromise = fetchUsers(this.currentOrganization.key);
        this.organizationUsers = await orgUserPromise;
      }
    },

    async reconcileCurrentEnvironment(
      environmentKey: string,
      organizationKey: string
    ) {
      if (environmentKey !== this.currentEnvironment.key) {
        //key returned is not same as current environment, check if key exists in organization environment list
        const environment = this.environments.find(
          (e: Environment) => e.key === environmentKey
        );
        if (environment) {
          await this.changeCurrentEnvironment(environment);
        } else {
          // environment doesn't exist in current organization
          const organization = this.organizations.find(
            (o: Organization) => o.key === organizationKey
          );

          if (organization) {
            await this.changeCurrentOrganization(organization);
            await this.reconcileCurrentEnvironment(
              environmentKey,
              organizationKey
            );
          }
        }
      }
    },
  },
});
