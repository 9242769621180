<template>
  <div id="standard-connections">
    <div
      class="grid gap-8"
      :class="{ 'grid-cols-1': width < 1600, 'grid-cols-2': width >= 1600 }"
    >
      <div
        class="rounded-2xl shadow-md h-20"
        :class="{
          'border-spreedly-blue-500  border-[2px]': connection.connected,
          'border-spreedly-gray-300 border-[2px]': !connection.connected,
        }"
        v-for="connection in paginatedGateways"
        :key="connection.gateway_type"
      >
        <div class="text-spreedly-gray-600 px-4 h-full w-full min-w-0 relative">
          <img
            v-if="connection.certified"
            class="cursor-pointer h-14 -top-[3px] left-3 absolute"
            src="@/assets/logos/certified-flag.svg"
            alt="Spreedly Blue Logo"
            @click="togglePopup"
            @keyup.enter="togglePopup"
            id="certified-flag"
            tabindex="0"
          />
          <div class="flex flex-row justify-between h-full" :class="{}">
            <div
              class="self-center font-bold w-2/3 lg:w-1/3"
              :class="{
                'text-sm lg:text-base': width < 1600,
                'text-lg': width >= 1600,
                'pl-[65px] ': connection.certified,
              }"
            >
              {{ connection.name }}
            </div>
            <div class="self-center">
              <div
                class="rounded-3xl px-2 text-white text-xs py-1 mx-2 whitespace-nowrap"
                :class="{
                  'bg-success-green': connection.connected,
                  'bg-spreedly-gray-500': !connection.connected,
                }"
              >
                {{
                  connection.connected
                    ? $t("connectionCount", {
                        count:
                          connection.connection_counts.production_count +
                          connection.connection_counts.sandbox_count,
                      })
                    : $t("notConnected")
                }}
              </div>
            </div>
            <div class="self-center">
              <Button
                class="text-spreedly-blue-600 hover:text-spreedly-blue-700"
                :id="`marketplace-manage-connections-button-${connection.gateway_type}`"
                icon="pi pi-arrow-right"
                icon-pos="right"
                type="button"
                text
                :label="$t('gateway_.manage')"
                @click="
                  router.push({
                    name: 'PartnerGatewayList',
                    params: { id: connection.gateway_type },
                  })
                "
              ></Button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="rounded-2xl shadow-md h-20 border-spreedly-gray-300 border-[2px] flex flex-row"
      >
        <div class="flex flex-col px-4 text-spreedly-gray-600 self-center">
          <h1 class="text-lg font-bold">
            {{ $t("partnerProgramCheckBack") }}
          </h1>
          <i18n-t
            scope="global"
            keypath="partnerProgram"
            tag="h1"
            class="text-spreedly-gray-600"
          >
            <template v-slot:learnMore>
              <a
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                target="_blank"
                :href="`${docsUrl}/docs/spreedly-one-certification`"
                >{{ $t("learnMore") }}</a
              >
            </template>
          </i18n-t>
        </div>
      </div>
    </div>
    <Paginator
      v-if="connections.length > 10"
      :totalRecords="connections.length"
      :rows="numRows"
      :rowsPerPageOptions="[5, 10, 15, 20]"
      template="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      v-model:first="currentPage"
      @update:first="(newPage) => (currentPage = newPage)"
      @update:rows="(newRows) => (numRows = newRows)"
      :pt="{
        root: {
          class: 'mt-4 bg-transparent py-0 text-spreedly-gray-600 font-sans',
        },
        rowPerPageDropdown: {
          root: {
            class:
              'flex !ml-4 !mb-0 border border-spreedly-gray-400 ml-4 items-center !w-[5.2rem]',
          },
        },
      }"
    ></Paginator>
  </div>

  <OverlayPanel
    ref="op"
    id="standard-overlay-panel"
    :pt="{
      root: 'bg-spreedly-gray-700 bg-opacity-95 text-white font-sans text-sm',
    }"
  >
    <div class="flex flex-col w-[350px]">
      <div class="pb-4">
        {{ $t("gateway_.certifiedTooltip") }}
      </div>
      <a
        :href="`${docsUrl}/docs/spreedly-one-certification`"
        target="_blank"
        class="text-spreedly-blue-lighter underline"
        >{{ $t("gateway_.certifiedLink") }}</a
      >
    </div>
  </OverlayPanel>
</template>
<script setup lang="ts">
import type { GatewayOption } from "@/services/GatewayService";
import { computed, ref } from "vue";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";

import router from "@/router";
import { useBreakpoints } from "@/composables/useBreakpoints";
import Paginator from "primevue/paginator";
const docsUrl = import.meta.env.VITE_DOCS_URL;

const { width } = useBreakpoints();
const connections = defineModel<GatewayOption[]>("connections", {
  required: true,
});
const op = ref();
const numRows = ref(10);
const currentPage = ref(0);

const paginatedGateways = computed(() => {
  if (connections.value.length > 10) {
    const start = currentPage.value > 0 ? currentPage.value : 0;
    const end = start + numRows.value;
    return connections.value.slice(start, end);
  }
  return connections.value;
});
const togglePopup = (event) => {
  op.value.toggle(event);
};
</script>
