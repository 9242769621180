<template>
  <div class="mb-2 w-full -mx-4 lg:mx-0">
    <div
      class="flex flex-row justify-between border border-b-1 border-spreedly-gray-300 mb-2 -ml-4 -mr-12 lg:-mx-4 border-t-0 border-x-0 pl-4"
      id="search-tab-panel"
      role="tablist"
    >
      <div
        role="tab"
        aria-controls="search-tab-panel"
        :aria-selected="searchCategory === tab.key"
        :id="`search-tab-${tab.key}`"
        v-for="(tab, index) in searchTabs"
        :key="tab.key"
        @click="searchCategory = tab.key"
        class="w-[calc(100%+1rem)] flex flex-row pb-2 hover:cursor-pointer border border-b-4 border-transparent border-t-0 border-x-0"
        :class="{
          '!border-spreedly-blue-600': searchCategory === tab.key,
          '-ml-4 pl-4': index === 0,
          '-mr-0 pr-4': index + 1 === searchTabs.length,
        }"
      >
        <h2
          class="text-spreedly-gray-500 text-sm lg:text-base font-semibold mx-2 pt-4 whitespace-nowrap overflow-hidden"
          :class="{
            '!text-spreedly-blue-600': searchCategory === tab.key,
          }"
        >
          {{ tab.title }}
        </h2>
      </div>
    </div>
    <div class="text-spreedly-gray-500 text-sm">
      <div
        class="mb-4 whitespace-nowrap w-full flex flex-col text-center"
        v-if="resultNotFound && searchStr"
      >
        <span>{{ $t("searchNoResults") }}</span>
        <span class="font-bold">{{ searchStr }}</span>
      </div>
      <div
        id="current-search-result-item"
        class="pt-2 mb-4 flex flex-row justify-between cursor-pointer"
        v-else-if="searchStr && searchResult"
        @click="$emit('go', searchResult)"
      >
        <span class="text-spreedly-blue-600 underline">{{
          searchResult.token
        }}</span>
        <span
          v-if="searchResult.environment"
          class="text-spreedly-gray-500 italic"
        >
          {{
            `${$t("in")} ${searchResult.environment.name} ${
              searchResult.environment.key === currentEnvironment.key
                ? `${$t("current")}`
                : ""
            }`
          }}
        </span>
        <span
          v-else-if="searchResult.organization"
          class="text-spreedly-gray-500 italic"
        >
          {{
            `${$t("in")}  ${searchResult.organization.key} (${$t("org.org")})`
          }}</span
        >
      </div>
      <hr
        class="solid -ml-4 -mr-12 lg:-mx-4 mt-2 text-spreedly-gray-500"
        v-if="(resultNotFound && searchStr) || searchResult"
      />
      <div v-if="recentSearch[searchCategory].length > 0" class="w-full -mr-12">
        <h1 class="text-spreedly-gray-500 text-sm pt-2">
          {{ $t("recentSearches") }}
        </h1>
        <ul class="text-spreedly-gray-700 text-sm">
          <li
            :id="`previous-search-result-list-item-${index}`"
            v-for="(s, index) in recentSearch[searchCategory]"
            :key="index"
            class="cursor-pointer flex flex-row justify-between hover:bg-spreedly-blue-100 p-2 previous-search-result-list-item"
            @click="
              searchStr = s.token;
              $emit('go', s);
            "
          >
            <div class="w-1/2">
              <span
                class="text-spreedly-gray-600 text-ellipsis overflow-hidden whitespace-nowrap"
                >{{ s.token }}</span
              >
            </div>
            <div class="w-1/2 flex justify-end overflow-hidden">
              <span
                v-if="s.environment"
                class="text-spreedly-gray-500 italic text-ellipsis overflow-hidden whitespace-nowrap"
                :title="environmentLabel(s.environment)"
              >
                {{ `${$t("in")} ${environmentLabel(s.environment)}` }}
              </span>
              <span
                v-else-if="s.organization"
                class="text-spreedly-gray-500 italic text-ellipsis overflow-hidden whitespace-nowrap"
                :title="organizationLabel(s.organization)"
              >
                {{ `${$t("in")} ${organizationLabel(s.organization)}` }}
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div v-else>
        <h1 class="text-spreedly-gray-500 text-sm py-2">
          {{ $t("noRecentSearches") }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { RecentSearch } from "@/services/SearchService";
import {
  GATEWAY_TOKEN,
  type SearchResult,
  TRANSACTION_TOKEN,
} from "@/services/SearchService";
import i18n from "@/i18n";
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";

defineEmits(["go", "search"]);

const store = useSettingsStore();
const { currentEnvironment } = storeToRefs(store);

const searchCategory = defineModel<
  typeof TRANSACTION_TOKEN | typeof GATEWAY_TOKEN
>("searchCategory", { required: true });

const searchStr = defineModel("searchStr", { required: true });
const searchResult = defineModel<SearchResult>("searchResult");
const recentSearch = defineModel<RecentSearch>("recentSearch", {
  required: true,
});
const resultNotFound = defineModel<boolean>("resultNotFound", {
  required: true,
});
const searchTabs = ref<
  { title: string; key: "transactionToken" | "gatewayToken" }[]
>([
  {
    title: i18n.global.t("transactions.token"),
    key: TRANSACTION_TOKEN,
  },
  { title: i18n.global.t("gateway_token"), key: GATEWAY_TOKEN },
]);

function environmentLabel(environment) {
  return `${environment.name} ${
    environment.key === currentEnvironment.value.key
      ? `${i18n.global.t("current")}`
      : ""
  }`;
}

function organizationLabel(organization) {
  return `${organization.key} (${i18n.global.t("org.org")})`;
}
</script>
