<template>
  <SpreedlyPageTitle :title="$t('transactions.transactions')">
  </SpreedlyPageTitle>
  <SpreedlySubtitle :title="$t('transactions.recent')">
    <template v-slot:info-text>
      <div
        class="flex flex-col md:flex-col lg:flex-row lg:justify-between pb-8 text-spreedly-gray-600"
      >
        <i18n-t
          scope="global"
          :keypath="'infoMessage.recentTransactions'"
          tag="p"
          class="text-caption"
        >
        </i18n-t>
      </div>
    </template>
  </SpreedlySubtitle>
  <DataTable
    stateStorage="session"
    stateKey="txn-list-session"
    paginator
    filterDisplay="row"
    v-model:filters="filters"
    :rows="10"
    striped-rows
    :rowsPerPageOptions="[5, 10, 20, 50]"
    :size="'small'"
    removable-sort
    sortField="created_at"
    :sort-order="-1"
    scrollable
    column-resize-mode="fit"
    :value="transactions"
    :pt="{
      root: { class: 'font-sans border !text-[14px]' },
      headerRow: { class: '!text-spreedly-gray-400 border-b' },
      bodyRow: { class: 'last:border-b-0 border-b' },
      header: { class: 'bg-[#F8F9FA]' },
      wrapper: { class: 'pb-2 bg-white' },
      paginator: {
        root: {
          class: 'font-sans ',
        },
        rowPerPageDropdown: {
          root: {
            class:
              'flex !ml-4 !mb-0 border border-spreedly-gray-400 ml-4 items-center !w-[5.2rem]',
          },
        },
      },
    }"
  >
    <template #empty>
      <span class="text-spreedly-gray-500 italic" v-if="!isLoading">{{
        $t("transactions.noTransactions")
      }}</span>
    </template>
    <template #header>
      <div class="flex flex-row justify-between">
        <div class="text-left mt-2 ml-2">
          <SpreedlyButton
            v-if="transactions?.length"
            :icon="{ position: 'left', name: 'download' }"
            :inverse="false"
            :text="$t('downloadCsv')"
            @click="downloadCsvData()"
          ></SpreedlyButton>
        </div>
        <button
          v-if="!isLoading"
          type="button"
          class="text-spreedly-blue-600 cursor-pointer inline-flex justify-center pb-2 items-center"
          @click="getRecentTransactions"
          id="refresh"
        >
          <SpreedlyIcon name="refresh" :size="18" class="mr-1" />
          <span>{{ $t("refresh") }}</span>
        </button>
      </div>
    </template>
    <Column
      v-for="col in headers"
      :field="col.field"
      :header="col.alias ? $t(col.alias) : $t(col.field)"
      :show-filter-menu="col.showFilterMenu"
      :sortable="col.sortable"
      :class="col.colClass"
      :pt="{
        sort: { class: 'text-spreedly-blue-600' },
        sortIcon: { class: 'text-spreedly-blue-600' },
        headerTitle: { class: '!text-spreedly-gray-600' },
        headerCell: { class: 'first:pl-4' },
        bodyCell: {
          class: '!text-spreedly-gray-600 first:pl-4',
        },
      }"
    >
      <template #filter="{ filterModel, filterCallback }" v-if="col.filterable">
        <Dropdown
          v-if="col.filterType === 'dropdown'"
          v-model="filterModel.value"
          @change="filterCallback()"
          :options="filterOptions(col.field)"
          :pt="{ root: { class: '!mb-0' } }"
          class="p-column-filter"
          style="min-width: 12rem"
          :showClear="true"
        >
          <template #option="slotProps">
            <Tag
              v-if="col.type === 'Tag'"
              :value="slotProps.option"
              rounded
              :pt="{
                root: {
                  class: {
                    'border border-success-green bg-success-green text-white':
                      getSeverity(slotProps.option) === 'success',
                    'border border-spreedly-red bg-spreedly-red text-white':
                      getSeverity(slotProps.option) === 'danger',
                    'border border-warning-orange bg-warning-orange text-white':
                      getSeverity(slotProps.option) === 'warning',
                  },
                },
              }"
            />
          </template>
        </Dropdown>
        <InputText
          v-else
          v-model="filterModel.value"
          type="text"
          @input="filterCallback()"
          class="p-column-filter self-center"
        />
      </template>
      <template #body="slotProps">
        <Skeleton v-if="isLoading" width="60%" height="1rem" />
        <div v-else>
          <router-link
            v-if="col.type === 'Link' && slotProps.data[col.field] && col.to"
            :id="col.field"
            v-tooltip="slotProps.data[col.field]"
            class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
            :to="{ name: col.to, params: { id: slotProps.data[col.field] } }"
          >
            {{
              col.truncate
                ? truncateDisplayValue(slotProps.data[col.field])
                : slotProps.data[col.field]
            }}
          </router-link>
          <Tag
            v-else-if="col.type === 'Tag'"
            class="mx-4 whitespace-nowrap"
            :value="slotProps.data[col.field]"
            rounded
            :pt="{
              root: {
                class: {
                  'border border-success-green bg-success-green text-white':
                    getSeverity(slotProps.data[col.field]) === 'success',
                  'border border-spreedly-red bg-spreedly-red text-white':
                    getSeverity(slotProps.data[col.field]) === 'danger',
                  'border border-warning-orange bg-warning-orange text-white':
                    getSeverity(slotProps.data[col.field]) === 'warning',
                },
              },
            }"
          ></Tag>
          <span v-else-if="col.type === 'DateString'">{{
            formatDate(slotProps.data[col.field])
          }}</span>
          <span
            v-else
            :class="
              col.field === 'firstName' || col.field === 'lastName'
                ? 'sentry-mask'
                : ''
            "
            >{{ slotProps.data[col.field] }}</span
          >
        </div>
      </template>
    </Column>
  </DataTable>
</template>
<script lang="ts" setup>
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";

import { computed, onMounted, ref, watch } from "vue";
import {
  displayGatewayToken,
  displayTransactionType,
  fetchRecentTransactions,
  formatPaymentMethodDescription,
  generateTxn,
  TRANSACTION_TYPES_WITH_AMOUNTS,
} from "@/services/TransactionService";
import type { TransactionPayload } from "@/services/TransactionService";

import { storeToRefs } from "pinia";
import { useSettingsStore } from "@/stores/SettingsStore";
import SpreedlySubtitle from "@/components/SpreedlySubtitle.vue";
import { formatDate, truncateDisplayValue } from "@/services/HelperService";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Tag from "primevue/tag";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";
import Button from "primevue/button";
import Column from "primevue/column";
import Skeleton from "primevue/skeleton";
import exportFromJSON from "export-from-json";
import { FilterMatchMode } from "primevue/api";

const store = useSettingsStore();
const { currentOrganization, currentEnvironment } = storeToRefs(store);
const transactions = ref<TransactionPayload[]>(
  Array.from({ length: 10 }).map((_, i) => generateTxn(i + 1))
);

const isLoading = ref(true);

onMounted(() => {
  getRecentTransactions();
});

watch(
  () => currentEnvironment.value.key,
  () => {
    getRecentTransactions();
  }
);

async function getRecentTransactions() {
  isLoading.value = true;
  try {
    transactions.value = await fetchRecentTransactions(
      currentOrganization.value.key,
      currentEnvironment.value.key as string
    ).then((data) => {
      data.forEach((txn) => {
        txn.payment_method = formatPaymentMethodDescription(txn);
        txn.amount = TRANSACTION_TYPES_WITH_AMOUNTS.includes(
          txn.transaction_type
        )
          ? txn.amount_formatted
          : "";
        txn.gateway_token = displayGatewayToken(txn);
        txn.state = displayTransactionType(txn.state, txn.succeeded);
      });
      return data;
    });
  } catch (err) {
    transactions.value = [];
  } finally {
    isLoading.value = false;
  }
}

const filters = ref({
  token: { value: null, matchMode: FilterMatchMode.CONTAINS },
  transaction_type: { value: null, matchMode: FilterMatchMode.EQUALS },
  state: { value: null, matchMode: FilterMatchMode.EQUALS },
  payment_method: { value: null, matchMode: FilterMatchMode.CONTAINS },
  gateway_token: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const stateOptions = computed(() => {
  return Array.from(new Set(transactions.value.map((txn) => txn.state)));
});

const transactionTypeOptions = computed(() => {
  return Array.from(
    new Set(transactions.value.map((txn) => txn.transaction_type))
  );
});

const filterOptions = (field: string) => {
  if (field === "transaction_type") {
    return transactionTypeOptions.value;
  }

  if (field === "state") {
    return stateOptions.value;
  }
};

const headers = [
  {
    field: "token",
    sortable: true,
    filterable: true,
    type: "Link",
    to: "TransactionDetailSummary",
    truncate: true,
    showFilterMenu: true,
    colClass: "min-w-[200px]",
  },
  {
    field: "transaction_type",
    sortable: true,
    filterable: true,
    filterType: "dropdown",
    type: "String",
    showFilterMenu: true,
    alias: "transactions.type",
    colClass: "min-w-[200px]",
  },
  {
    field: "state",
    sortable: true,
    filterable: true,
    type: "Tag",
    showFilterMenu: true,
    alias: "status",
    filterType: "dropdown",
    colClass: "min-w-[200px]",
  },
  {
    field: "payment_method",
    sortable: true,
    filterable: true,
    showFilterMenu: true,
    type: "String",
    colClass: "min-w-[200px]",
  },
  {
    field: "gateway_token",
    sortable: true,
    filterable: true,
    showFilterMenu: true,
    truncate: true,
    to: "GatewayDetailSummary",
    type: "Link",
    alias: "gateway",
    colClass: "min-w-[200px]",
  },
  {
    field: "created_at",
    alias: "time",
    sortable: true,
    type: "DateString",
    colClass: "min-w-[200px]",
  },
  {
    field: "amount",
    sortable: true,
    type: "String",
    colClass: "min-w-[200px]",
  },
];

const getSeverity = (state: string) => {
  switch (state.toLowerCase()) {
    case "succeeded":
    case "successful":
      return "success";
    case "failed":
    case "gateway processing failed":
      return "danger";
    default:
      return "warning";
  }
};

function downloadCsvData() {
  const todayDate = new Date().toISOString().split("T")[0];
  const data = transactions.value.map((txn) => ({
    token: txn.token,
    transaction_type: txn.transaction_type,
    state: txn.state,
    gateway_token: txn.gateway_token,
    payment_method: txn.payment_method,
    created_at: txn.created_at,
    amount: txn.amount,
  }));
  const fileName = `transactions-${todayDate}`;
  const exportType = exportFromJSON.types.csv;
  const fields = {
    token: "Token",
    transaction_type: "Transaction type",
    state: "Status",
    gateway_token: "Gateway token",
    payment_method: "Payment method",
    created_at: "Time",
    amount: "Amount",
  };

  if (data) exportFromJSON({ data, fileName, exportType, fields });
}
</script>
