import http, { getList } from "@/http";
import * as Sentry from "@sentry/vue";

export interface Organization {
  active?: boolean;
  ever_active?: boolean;
  allow_account_updater?: boolean;
  account_updater_enabled: boolean;
  allow_account_updater_callbacks?: boolean;
  allow_au_by_environment: boolean | string;
  billingUrl?: string;
  dashboardUrl?: string;
  dispatch_enabled?: boolean;
  feature_level?: unknown;
  key: string;
  last_successful_invoice_at?: boolean;
  name: string;
  settingsUrl?: string;
  can_access_chargify?: boolean;
  advanced_dashboard_enabled?: boolean;
  allow_payment_method_management?: boolean;
  allow_recover?: boolean;
  payment_method_management_enabled: boolean;
}

export interface ActivityLog {
  created_at: string;
  action: string;
  user_email: string;
  target: string;
  previous_value: string;
  new_value: string;
  domain: string;
  resource: string;
  environment: string;
}

export async function updateOrganization(org: Organization): Promise<boolean> {
  const url = `/organizations/${org.key}`;
  try {
    const response = await http().put(url, org);
    return !!response.data.organization;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
}

export async function fetchOrganizations(): Promise<Organization[]> {
  return getList("organizations", "organizations");
}

export async function fetchBilling(orgKey: string) {
  const url = `/organizations/${orgKey}/billing`;

  const response = await http().get(url);

  return response.data.billing_path;
}

export async function fetchActivityLogs(
  orgKey: string
): Promise<ActivityLog[]> {
  // Activity Logs are called Action Logs in ID
  const url = `/organizations/${orgKey}/action_logs`;
  return await getList<any>(url, "action_logs");
}
