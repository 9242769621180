<template>
  <div
    v-if="recover"
    :class="{
      'border-spreedly-red border-2': !recoverAllowed,
      'border-spreedly-gray-300': recoverAllowed,
    }"
    class="w-full border border-solid rounded-md bg-spreedly-blue-100 p-2 mb-2"
  >
    <div
      :class="
        recoverAllowed ? 'text-spreedly-gray-600' : 'text-spreedly-gray-500'
      "
    >
      <div class="recover-step">
        <div class="recover-top-line"></div>
        <div
          class="flex flex-row"
          :class="{ '!border-spreedly-red': !recoverAllowed }"
        >
          <div class="flex flex-col w-full ml-2">
            <div class="flex flex-row justify-between">
              <div class="flex flex-row mb-4">
                <i18n-t
                  scope="global"
                  keypath="recover.recoverHeader"
                  tag="div"
                  class="font-bold self-start w-full whitespace-nowrap"
                >
                  <template v-slot:mode>
                    <Dropdown
                      :aria-label="`recover-mode-dropdown-${stepIndex}-${conclusionIndex}`"
                      v-model="v.base_mode.$model"
                      :placeholder="$t('recover.select')"
                      option-value="value"
                      option-label="label"
                      class="workflow-title-dropdown"
                      :input-id="`recover-mode-dropdown-${stepIndex}-${conclusionIndex}`"
                      :disabled="!hasPermission || !recoverAllowed"
                      :options="[
                        {
                          value: RecoverBaseMode.Outage,
                          label: $t('recover.outage'),
                          description: $t('recover.outageDescription'),
                        },
                        {
                          value: RecoverBaseMode.Standard,
                          label: $t('recover.standardOutage'),
                          description: $t('recover.standardOutageDescription'),
                        },
                      ]"
                      :pt="{
                        root: {
                          class:
                            'bg-transparent !mb-0 !border-t-0 !rounded-none !ml-1 !pr-0 !border-b-spreedly-blue-600 !border-x-0 !w-fit self-center font-sans font-bold ',
                        },
                        input: { class: 'px-0' },
                      }"
                    >
                      <template #header
                        ><div class="flex flex-col font-sans mx-4 my-2">
                          <h1 class="font-bold">
                            {{ $t("recover.recoverMode") }}
                          </h1>
                          <i18n-t
                            scope="global"
                            keypath="recover.docs"
                            tag="p"
                            class="whitespace-pre-line text-sm"
                          >
                            <template v-slot:learnMore>
                              <a
                                class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                                :href="`${docsUrl}/docs/recover-user-guide`"
                                target="_blank"
                                >{{ $t("learnMore").toLowerCase() }}</a
                              >
                            </template>
                          </i18n-t>
                        </div></template
                      >
                      <template #option="slotProps">
                        <div
                          class="flex flex-col text-sm max-w-[300px] font-sans"
                        >
                          <div class="flex flex-row">
                            <div class="text-spreedly-gray-600 font-bold">
                              {{ slotProps.option.label }}
                            </div>
                          </div>
                          <div class="whitespace-normal text-xs">
                            {{ slotProps.option.description }}
                          </div>
                        </div>
                      </template>
                    </Dropdown>
                  </template>
                </i18n-t>
                <div class="self-start">
                  <Button
                    v-if="!store.currentOrganization.allow_recover"
                    @click="$emit('openRecoverInfo')"
                    type="button"
                    :id="`recover-information-${stepIndex}-${conclusionIndex}`"
                    class="p-button-text p-button-rounded !text-spreedly-blue-700 !shadow-none !hover:text-spreedly-blue-800 hover:enabled:text-spreedly-blue-800 focus:text-spreedly-blue-800 hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
                  >
                    <template #icon>
                      <SpreedlyIcon
                        name="information-outline"
                        class="text-spreedly-blue-700 hover:cursor-pointer mx-2"
                        data-testid="info-icon"
                      ></SpreedlyIcon
                    ></template>
                  </Button>
                </div>
              </div>

              <div class="self-start mx-2">
                <Button
                  type="button"
                  :aria-label="$t('remove')"
                  :id="`remove-recovery-strategy-${stepIndex}-${conclusionIndex}`"
                  @click="$emit('removeRecover', conclusionIndex)"
                  :disabled="!hasPermission"
                  icon="pi pi-times"
                  v-tooltip="$t('recover.removeRecoverStrategy')"
                  class="remove-recovery-strategy p-button-text text-spreedly-blue-700 hover:text-spreedly-blue-800 hover:enabled:text-spreedly-blue-800 focus:text-spreedly-blue-800 !shadow-none hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
                  rounded
                ></Button>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="self-center mr-2 flex flex-col">
                <label
                  class="p-float-label text-xs text-spreedly-gray-600 ml-1 mb-1"
                  :for="`recover-configuration-dropdown-${stepIndex}-${conclusionIndex}`"
                  >{{ $t("recover.recoverConfigurationOptional") }}</label
                >
                <Dropdown
                  v-model="v.config_id.$model"
                  :placeholder="$t('recover.selectConfiguration')"
                  option-value="key"
                  option-label="description"
                  filter
                  @update:model-value="updateParentComponent()"
                  :input-id="`recover-configuration-dropdown-${stepIndex}-${conclusionIndex}`"
                  :disabled="!hasPermission || !recoverAllowed"
                  :options="recoverConfigurations"
                  :show-clear="!!v.config_id.$model"
                  :pt="{
                    root: {
                      class: '!mb-0 !px-0 !w-[300px] !mr-2 font-sans',
                    },
                    list: { class: '!font-sans' },
                  }"
                >
                  <template #header
                    ><div class="flex flex-col font-sans mx-4 mt-2">
                      <h1 class="font-bold">
                        {{ $t("recover.recoverConfiguration") }}
                      </h1>
                      <p class="whitespace-pre-line text-sm mb-4">
                        {{ $t("recover.recoverConfigurationInfo") }}
                      </p>
                    </div></template
                  >
                </Dropdown>
              </div>
              <div class="self-end mx-2">
                <Button
                  type="button"
                  :label="$t('recover.editConfiguration')"
                  :disabled="
                    toggleRecoverConfigurationsDrawer || !recoverAllowed
                  "
                  @click="toggleRecoverConfigurationsDrawer = true"
                  class="p-button-text text-spreedly-blue-700 hover:text-spreedly-blue-800 disabled:text-spreedly-gray-500 disabled:hover:cursor-not-allowed self-start mb-2 text-sm px-1"
                  :id="`create-recover-configuration-${stepIndex}-${conclusionIndex}`"
                >
                </Button>
              </div>
            </div>
            <div class="flex flex-row justify-between mt-6">
              <div class="flex flex-row w-full justify-start">
                <div class="self-center mr-2 w-full">
                  <WorkflowResult
                    :step-index="stepIndex"
                    :conclusion-index="conclusionIndex"
                    :result="recover.results[0]"
                    :key="recover.results[0].uuid"
                    :recover="true"
                    :recover-priority="recover.results[0].priority"
                  ></WorkflowResult>
                </div>
                <div
                  class="self-center mx-2"
                  v-if="recover.results.length === 1 && recoverAllowed"
                >
                  <Button
                    type="button"
                    :aria-label="`add-recover-result-button-${conclusionIndex}-${stepIndex}`"
                    :id="`add-recover-result-button-${conclusionIndex}-${stepIndex}`"
                    @click="addRecover()"
                    :disabled="!hasPermission"
                    icon="pi pi-plus"
                    v-tooltip="$t('recover.addGateway')"
                    class="remove-recover-result p-button-text text-spreedly-blue-700 hover:text-spreedly-blue-800 hover:enabled:text-spreedly-blue-800 focus:text-spreedly-blue-800 !shadow-none hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
                    rounded
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="recover-v-stepper">
          <div class="recover-line"></div>
          <div class="recover-arrow"></div>
        </div>
      </div>
      <div class="recover-step" v-if="recover.results.length > 1">
        <div class="recover-top-line"></div>
        <div class="flex flex-row ml-14 my-4">
          <div class="flex flex-row justify-between w-full">
            <div class="self-center mx-2 w-full">
              <WorkflowResult
                :step-index="stepIndex"
                :conclusion-index="conclusionIndex"
                :result="recover.results[1]"
                :key="recover.results[1].uuid"
                :recover="true"
                :recover-priority="recover.results[1].priority"
              ></WorkflowResult>
            </div>
            <div
              class="self-center mx-2"
              v-if="recover.results.length > 1 && recoverAllowed"
            >
              <Button
                type="button"
                :aria-label="$t('remove')"
                :id="`remove-recover-result-${stepIndex}-${conclusionIndex}-${recover.results[1].priority}`"
                @click="removeResult(recover.results[1].priority!)"
                :disabled="!hasPermission"
                icon="pi pi-minus"
                v-tooltip="$t('recover.removeSecondRecoverResult')"
                class="remove-recover-result p-button-text text-spreedly-blue-700 hover:text-spreedly-blue-800 hover:enabled:text-spreedly-blue-800 focus:text-spreedly-blue-800 !shadow-none hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
                rounded
              ></Button>
            </div>
          </div>
        </div>
        <div class="recover-v-stepper">
          <div class="recover-line"></div>
          <div class="recover-arrow"></div>
        </div>
      </div>
    </div>
    <WorkflowRecoverConfigurationsDrawer
      v-model:toggle-drawer="toggleRecoverConfigurationsDrawer"
    ></WorkflowRecoverConfigurationsDrawer>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import WorkflowRecoverConfigurationsDrawer from "@/components/WorkflowRecoverConfigurationsDrawer.vue";
import WorkflowResult from "@/components/WorkflowResult.vue";

import Dropdown from "primevue/dropdown";
import Button from "primevue/button";

import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  addRecoverResult,
  removeRecoverResult,
  updateRecover,
  useWorkflow,
} from "@/composables/useWorkflow";
import type { WorkflowRecover } from "@/services/WorkflowService";
import { RecoverBaseMode } from "@/services/WorkflowService";
import SpreedlyIcon from "@/components/SpreedlyIcon.vue";

const recoverAllowed = computed(
  () => isSandbox.value || store.currentOrganization.allow_recover
);
const store = useSettingsStore();
const { action, recoverConfigurations, isSandbox } = useWorkflow();
const toggleRecoverConfigurationsDrawer = ref(false);
const docsUrl = import.meta.env.VITE_DOCS_URL;

function addRecover() {
  v.value.$touch();
  addRecoverResult(props.conclusionIndex, props.stepIndex);
}

const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("environment.create_workflow");
});

const props = defineProps<{
  recover: WorkflowRecover;
  conclusionIndex: number;
  stepIndex: number;
}>();

defineEmits(["removeRecover", "openRecoverInfo"]);

const state = reactive(props.recover);

const rules = {
  config_id: {},
  base_mode: { required },
  results: {},
};

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

function updateParentComponent() {
  v.value.$touch();
  updateRecover(state, props.conclusionIndex, props.stepIndex);
}

function removeResult(priority: number) {
  v.value.$touch();
  removeRecoverResult(props.conclusionIndex, props.stepIndex, priority);
}
</script>
<style lang="css" scoped>
.recover-step {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
}

.recover-step:nth-child(3) {
  margin-bottom: 0;
}
.recover-v-stepper {
  position: relative;
}

.recover-step:not(:first-child):not(:last-child) .recover-top-line {
  left: 1rem;
  top: 2rem;
  bottom: 0;
  position: absolute;
  border-left: 2px solid #00538c;
}
.recover-step .recover-line {
  left: 2rem;
  top: 0;
  position: absolute;
  border-left: 2px solid #00538c;
  border-bottom: 2px solid #00538c;
  width: 1.25rem;
  height: 4rem;
  border-bottom-left-radius: 1rem;
}

.recover-step:not(:first-child) .recover-line {
  height: 3.5rem;
}

.recover-step .recover-arrow {
  border: solid #00538c;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0.33rem;
  transform: rotate(-45deg);
  position: absolute;
  left: 2.5rem;
  top: 3.55rem;
}

.recover-step:last-child .recover-line {
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  z-index: -1;
}

.recover-step:last-child .recover-arrow {
  border: 2px solid transparent;
  z-index: -1;
}

.recover-step:last-child {
  padding: 0;
}
</style>
