<template>
  <form
    class="h-full w-full flex flex-col px-6"
    @keyup.enter="save(v.$invalid)"
    @submit.prevent="save(v.$invalid)"
  >
    <Message
      v-if="displayErrorMessage"
      class="justify-start"
      severity="error"
      :closable="false"
      >{{ displayErrorMessage }}
    </Message>
    <ConfirmDialog group="delete-config-warning" id="delete-dialog">
      <template #container="{ message, rejectCallback, acceptCallback }">
        <div class="bg-white rounded">
          <div class="bg-spreedly-red p-4 text-white text-lg">
            {{ message.header }}
          </div>
          <div
            class="text-spreedly-red flex flex-row align-items-center w-full gap-3 border-bottom-1 surface-border p-4"
          >
            <i class="text-5xl text-primary-500 pi pi-exclamation-triangle"></i>
            <div>
              <span v-if="!configurationWorkflows.length">{{
                message.message
              }}</span>
              <span v-else class="whitespace-pre-line">{{
                $t("recover.configurationsExistOnWorkflow")
              }}</span>
              <ul class="flex flex-col list-disc ml-4">
                <li
                  v-for="workflow in configurationWorkflows"
                  :key="workflow.key"
                >
                  <button
                    class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700"
                    @click="
                      rejectCallback();
                      $emit('jumpToWorkflow', workflow.key!);
                    "
                  >
                    {{ workflow.name }}
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div class="flex flex-row justify-end p-4">
            <button
              type="button"
              id="close-confirmation-dialog"
              autofocus
              @click="rejectCallback"
              class="border-4 ml-2 text-spreedly-blue-700 border-spreedly-blue-700 p-1 rounded"
            >
              {{ message.rejectLabel }}
            </button>
            <button
              v-if="!configurationWorkflows.length"
              type="button"
              id="delete-confirmation"
              autofocus
              @click="acceptCallback"
              class="border-4 ml-2 text-white border-spreedly-blue-600 bg-spreedly-blue-600 p-1 rounded"
            >
              {{ message.acceptLabel }}
            </button>
          </div>
        </div>
      </template>
    </ConfirmDialog>

    <div class="mt-8 mb-2" v-if="action === 'create'">
      <label
        for="recover-configuration-description-input"
        class="font-bold"
        :class="{ '!text-spreedly-red': v.description.$invalid && submitted }"
        >{{ $t("description") }}</label
      >
      <InputText
        id="recover-configuration-description-input"
        v-model="v.description.$model"
        :disabled="!hasPermission"
        :maxlength="100"
        :pt="{
          input: { class: 'pl-0 pr-2' },
          root: {
            class:
              v.description.$invalid && submitted ? '!border-spreedly-red' : '',
          },
        }"
      />
      <div v-if="v.description.$invalid && submitted">
        <small class="p-error">{{ v.description.required.$message }}</small>
      </div>
    </div>

    <div class="my-6">
      <label
        for="retryable-failure-reasons-multiselect"
        class="font-bold"
        :class="{
          '!text-spreedly-red':
            v.retryable_failure_reasons.$invalid && submitted,
        }"
        >{{ $t("recover.failureReasons") }}</label
      >
      <i18n-t
        scope="global"
        keypath="recover.failureReasonDocumentation"
        tag="p"
        class="whitespace-pre-line text-sm my-1"
      >
        <template v-slot:link>
          <a
            class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
            :href="`${docsUrl}/docs/recover-user-guide`"
            target="_blank"
            >{{ $t("seeDocumentation") }}</a
          >
        </template>
      </i18n-t>
      <MultiSelect
        v-model="selectedFailureReasons"
        :showClear="!v.retryable_failure_reasons.$model"
        :disabled="!hasPermission"
        input-id="retryable-failure-reasons-multiselect"
        filter
        display="chip"
        @change="updateState()"
        :options="retryableFailureReasonOptions"
        optionLabel="name"
        class="w-full"
        :pt="{
          root: {
            class:
              v.retryable_failure_reasons.$invalid && submitted
                ? 'border border-spreedly-red !text-spreedly-red'
                : 'border border-spreedly-gray-300',
          },
        }"
      />
      <div v-if="v.retryable_failure_reasons.$invalid && submitted">
        <small class="p-error">{{
          v.retryable_failure_reasons.required.$message
        }}</small>
      </div>
    </div>
    <div class="flex flex-row justify-between">
      <div
        v-if="store.hasPermission('workflow.update')"
        class="flex flex-shrink-0 flex-wrap items-center justify-start mt-4"
      >
        <SpreedlyButton
          class="mr-4"
          :disabled="isLoading"
          :text="$t('cancel')"
          id="cancel-recover-configuration-editor-button"
          :inverse="true"
          @click="cancel()"
        ></SpreedlyButton>
        <SpreedlyButton
          class="!mr-0"
          :disabled="
            !v.$anyDirty ||
            formState === 'saving' ||
            !hasPermission ||
            isLoading
          "
          @click="save(v.$invalid)"
          id="save-recover-configuration-editor-button"
          :icon="{ position: 'left', state: formState }"
          :text="$t('save')"
        ></SpreedlyButton>
      </div>
      <div
        v-if="store.hasPermission('workflow.delete') && action === 'edit'"
        class="flex flex-shrink-0 justify-end mt-4 items-center"
      >
        <span
          v-if="errorMessage"
          class="text-sm text-spreedly-red font-bold mr-4"
          >{{ errorMessage }}</span
        >
        <button
          @click="confirmDeleteConfig"
          type="button"
          :disabled="isLoading"
          id="delete-recover-configuration"
          class="max-h-[42px] h-full inline-flex disabled:cursor-not-allowed justify-center items-center font-light rounded whitespace-nowrap ml-4 px-2 text-spreedly-blue-600 hover:text-spreedly-blue-700"
        >
          {{ $t("recover.deleteConfiguration") }}
        </button>
      </div>
    </div>
  </form>
</template>
<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import Message from "primevue/message";

import {
  deleteRecoverConfiguration,
  type RecoverConfiguration,
} from "@/services/RecoverConfigurationsService";
import {
  createRecoverConfiguration,
  updateRecoverConfiguration,
} from "@/services/RecoverConfigurationsService";
import { useVuelidate } from "@vuelidate/core";
import {
  removeRecoverConfigFromWorkflow,
  useWorkflow,
} from "@/composables/useWorkflow";
import { required } from "@vuelidate/validators";
import { sentenceCase } from "@/services/HelperService";
import i18n from "@/i18n";

import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import {
  listWorkflowsByRecoverConfiguration,
  type Workflow,
} from "@/services/WorkflowService";

const configurationWorkflows = ref<Workflow[]>([]);
const docsUrl = import.meta.env.VITE_DOCS_URL;
const store = useSettingsStore();
const emit = defineEmits(["closeEditor", "jumpToWorkflow"]);
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const submitted = ref(false);
const displayErrorMessage = ref();
const selectedFailureReasons = ref<{ name: string; code: string }[]>([]);
const { failureReasons } = useWorkflow();
const isLoading = ref(false);
const confirm = useConfirm();
const errorMessage = ref("");
const props = defineProps<{
  action: "create" | "edit";
  config?: RecoverConfiguration;
  scope: string;
}>();

const state = reactive({
  description: props.config ? props.config.description : "",
  retryable_failure_reasons: props.config
    ? props.config.retryable_failure_reasons
    : [],
});
const rules = {
  description: { required },
  retryable_failure_reasons: { required },
};

const v = useVuelidate(rules, state, { $scope: props.scope });

const retryableFailureReasonOptions = computed(() => {
  let optionsArray: { name: string; code: string }[] = [];
  failureReasons.value.forEach((reason) => {
    optionsArray.push({ name: sentenceCase(reason), code: reason });
  });
  return optionsArray;
});

const hasPermission = computed(() => {
  return props.action === "edit"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("environment.create_workflow");
});

onMounted(async () => {
  if (props.config && props.config.retryable_failure_reasons?.length > 0) {
    props.config.retryable_failure_reasons.forEach((reason) => {
      selectedFailureReasons.value.push({
        name: sentenceCase(reason),
        code: reason,
      });
    });
  }

  if (props.action === "edit" && props.config) {
    configurationWorkflows.value = await listWorkflowsByRecoverConfiguration(
      store.currentOrganization.key,
      store.currentEnvironment.key!,
      props.config.key!
    );
  }
});

onUnmounted(() => {
  selectedFailureReasons.value = [];
});

function cancel() {
  if (v.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
  emit("closeEditor", false);
}

async function save(isFormInvalid: boolean) {
  if (!v.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = null;

  if (!hasPermission.value) {
    displayErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }

  submitted.value = true;
  if (isFormInvalid) {
    return;
  }
  isLoading.value = true;
  try {
    formState.value = "saving";
    await formatFunctionCall().call(null);
    emit("closeEditor", true);
  } catch (err) {
    displayErrorMessage.value = i18n.global.t("errorMessage.generic");
    formState.value = "saveChanges";
  } finally {
    v.value.$reset();
    v.value.$touch();
    submitted.value = false;
    isLoading.value = false;
  }
}

function formatFunctionCall() {
  if (props.action === "create") {
    return createRecoverConfiguration.bind(
      null,
      store.currentOrganization.key,
      store.currentEnvironment.key!,
      {
        recover_configuration: {
          description: state.description,
          environment_key: store.currentEnvironment.key!,
          retryable_failure_reasons: state.retryable_failure_reasons,
        },
      }
    );
  }

  return updateRecoverConfiguration.bind(
    null,
    store.currentOrganization.key,
    store.currentEnvironment.key!,
    props.config?.key!,
    {
      recover_configuration: {
        retryable_failure_reasons: state.retryable_failure_reasons,
      },
    }
  );
}

function updateState() {
  v.value.$touch();
  state.retryable_failure_reasons = [];
  selectedFailureReasons.value.map((reason) => {
    state.retryable_failure_reasons.push(reason.code);
  });
}

const confirmDeleteConfig = () => {
  confirm.require({
    group: "delete-config-warning",
    message: i18n.global.t("confirmations.deleteRecoverConfig.message"),
    header: i18n.global.t("confirmations.deleteRecoverConfig.header", {
      configurationName: props.config?.description,
    }),
    icon: "pi pi-exclamation-triangle",
    acceptLabel: i18n.global.t("recover.deleteConfiguration"),
    rejectLabel: i18n.global.t("cancel"),
    defaultFocus: "reject",
    accept: async () => {
      isLoading.value = true;
      try {
        await deleteRecoverConfiguration(
          store.currentOrganization.key,
          store.currentEnvironment.key!,
          props.config?.key!
        );
        removeRecoverConfigFromWorkflow(props.config?.key!);
        emit("closeEditor", true);
      } catch (err) {
        errorMessage.value = i18n.global.t("recover.deleteError");
      } finally {
        isLoading.value = false;
      }
    },
  });
};
</script>
